import {
  SimpleChange,
  Component,
  OnChanges,
  OnInit,
  Input,
} from "@angular/core";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

// Models
import { SwiperLazy } from "src/app/modules/game-groups/models/swiper/swiper-lazy.model";
import { ProviderPregmatic } from "src/app/modules/game-groups/models/provider.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { JackpotService } from "src/app/modules/game-groups/services/jackpot.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

// Utilities
import { swiperLazyConfigurationsChange } from "src/app/modules/game-groups/utilities/swiper.utility";

@Component({
  selector: "app-poster-jockpot",
  templateUrl: "./poster-jockpot.component.html",
  styleUrls: ["./poster-jockpot.component.scss"],
})
export class PosterJockpotComponent implements OnInit, OnChanges {
  // Inputs
  @Input() gameGroupData: ProviderPregmatic;
  @Input() callingFromlobby: string = "";

  // Arrays
  gamesList: GamePregmatic[] = [];

  // Swiper Configurations
  swiperPosterJackpot: SwiperConfigInterface = {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 16,
    freeMode: true,
    navigation: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    resistanceRatio: 0,
    breakpoints: {
      // breakpoints works like max-width css
      6000: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 16,
      },
      1599: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 16,
        // freeMode: false,
      },
      767: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 8,
      },
      480: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 8,
        //freeMode: false,
      },
    },
  };
  swiperLazyConfigurations: SwiperLazy = swiperLazyConfigurationsChange(10, 5);

  constructor(
    private jackpotService: JackpotService,
    private utilityService: UtilityService
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.jackpotService.onGetMessagesJackpots();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["gameGroupData"] &&
      changes["gameGroupData"].previousValue !==
        changes["gameGroupData"].currentValue
    ) {
      this.gameGroupData = changes["gameGroupData"].currentValue;

      if (this.gameGroupData.group_type === "manual") {
        this.gamesList = this.utilityService.sortGameByCountryOrder(
          this.gameGroupData.games
        );
      } else {
        this.gamesList = this.gameGroupData.games;
      }

      if (this.gamesList && this.gamesList.length > 0) {
        this.swiperLazyConfigurations.totalNumberOfGamesExist = this.gamesList.length;
      }
    }

    if (
      changes["callingFromlobby"] &&
      changes["callingFromlobby"].previousValue !==
        changes["callingFromlobby"].currentValue
    ) {
      this.callingFromlobby = changes["callingFromlobby"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  /*
    Function for Lazy loading of games goes here
    on every next click of silde below function will be trigerred
    & calculated how many game have to load amount that total game present

    This works on bases of swiperLazyConfig defined above...
  */
  onSwiperNextEvent(): void {
    if (this.swiperLazyConfigurations.backwardslideIndex) {
      this.swiperLazyConfigurations.backwardslideIndex =
        this.swiperLazyConfigurations.backwardslideIndex - 1;
      return;
    }
    if (
      this.swiperLazyConfigurations.indexNumberOfGameTilesToshow <
      this.swiperLazyConfigurations.totalNumberOfGamesExist
    ) {
      this.swiperLazyConfigurations.indexNumberOfGameTilesToshow =
        this.swiperLazyConfigurations.indexNumberOfGameTilesToshow +
        this.swiperLazyConfigurations.numberOfExtraGamesToLoadForSlide;

      this.swiperLazyConfigurations.forwardslideIndex =
        this.swiperLazyConfigurations.forwardslideIndex + 1;
    }
  }

  onSwiperPrevEvent(): void {
    this.swiperLazyConfigurations.backwardslideIndex =
      this.swiperLazyConfigurations.backwardslideIndex + 1;
  }

  onNavigateAllGamesPage(): void {
    this.utilityService.navigateAllGamesPage(this.gameGroupData);
  }
}
