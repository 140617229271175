import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

// Actions
import { userRequested } from "src/app/modules/user/store/actions/user.actions";

// Libraries
import * as _ from "underscore";

// Models
import { ProfileCountryDetails } from "src/app/modules/user/models/profile-country-details.model";
import { ProfileCountries } from "src/app/modules/user/models/profile-countries.model";
import { UserData } from "src/app/modules/user/models/user-data.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { CustomValidatorService } from "src/app/modules/shared/services/custom-validator.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

@Component({
  selector: "app-update-profile",
  templateUrl: "./update-profile.component.html",
  styleUrls: ["./update-profile.component.scss"],
})
export class UpdateProfileComponent implements OnInit, OnDestroy {
  // Booleans 
  isLoggedIn: boolean = false;

  // Form Group
  profileUpdateForm: FormGroup;

  // Arrays
  isoBasedCountryList: ProfileCountries[] = [];

  // Objects
  profileDetails: UserData;

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private customValidators: CustomValidatorService,
    private liveChatService: LiveChatService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {
    this.profileUpdateForm = this.formBuilder.group({
      firstName: ["", [this.customValidators.validName(2, 30)]],
      lastName: ["", [this.customValidators.validName(2, 30)]],
      address1: ["", [this.customValidators.maxMinCharacters(2, 80)]],
      city: ["", [this.customValidators.maxMinCharacters(2, 50)]],
      zip: ["", [this.customValidators.validAlphaNumeric(2, 9)]],
      country: ["", [this.customValidators.required]],
      phone: ["", []],
      email: ["", []],
    });
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.subscriptions = [
      this.store.select(selectAuthLoginIsLoggedIn).subscribe((isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut),
      this.commonService
        .getProfileCountryDetails()
        .subscribe((profileCountryDetails: ProfileCountryDetails) => {
          if (profileCountryDetails && profileCountryDetails.countrylist) {
            this.isoBasedCountryList = profileCountryDetails.countrylist;
          }
        }),
      this.store
        .select(selectAuthUserDataLoaded)
        .subscribe(({ userData, isLoaded }) => {
          if (isLoaded) {
            this.profileDetails = userData;

            this.onSetFormData(this.profileDetails);
          } else {
            if (this.isLoggedIn) {
              this.store.dispatch(userRequested());
            }
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetFormData(profileDetails: UserData): void {
    let formGroup: FormGroup = this.profileUpdateForm;

    if (profileDetails) {
      Object.keys(formGroup.controls).forEach((formControlName: string) => {
        const control: AbstractControl = formGroup.get(formControlName);

        let profileValue: string = "";

        if (formControlName === "dob") {
          profileValue = profileDetails.birthDate;
        } else if (formControlName === "phone") {
          profileValue = `+${profileDetails.cellPhoneAreaCode}${profileDetails.cellPhone}`;
        } else if (formControlName === "country" && this.isoBasedCountryList) {
          let filteredData: ProfileCountries = _.findWhere(
            this.isoBasedCountryList,
            {
              iso: profileDetails.country,
            }
          );

          profileValue = filteredData ? filteredData.name : "";
        } else {
          profileValue = profileDetails[formControlName];
        }

        if (profileValue) {
          control.setValue(profileValue);
        }
      });
    }
  }

  onInitializeLiveChat(): void {
    this.liveChatService.onInitializeChat();

    this.utilityService.closeAccountComponent();
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
