import { environment } from "src/environments/environment";

export const purpleTheme = (): string[] => {
  let latinLocale = ["pt-br", "es-sa"];

  if (environment.features.hungarian) {
   // latinLocale = [...latinLocale, "hu-hu"];
  }

  return latinLocale;
};
