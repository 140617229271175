import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  withLatestFrom,
  catchError,
  switchMap,
  filter,
  map,
} from "rxjs/operators";

// Actions
import {
  lastPlayedGamesRequested,
  lastPlayedGamesLoaded,
  lastPlayedGamesError,
  gamesRequested,
  lobbyRequested,
  gamesLoaded,
  lobbyLoaded,
  gamesError,
  lobbyError,
} from "src/app/modules/game-groups/store/actions/games.actions";

// Models
import { LobbyPregmatic } from "src/app/modules/game-groups/models/lobby/lobby.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import {
  selectAllLobbyPregmatic,
  selectLastPlayedState,
  selectAllGames,
} from "src/app/modules/game-groups/store/selectors/games.selectors";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";

@Injectable()
export class GamesEffects {
  constructor(
    private gameGroupsService: GameGroupsService,
    private store: Store<AppState>,
    private actions$: Actions
  ) {}

  gamesRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(gamesRequested),
      withLatestFrom(this.store.select(selectAllGames)),
      filter(([, gamesList]) => !!gamesList),
      switchMap(() => {
        return this.gameGroupsService.onGetGamePregmaticList().pipe(
          map((gamesList: GamePregmatic[]) => gamesLoaded({ gamesList })),
          catchError(() => of(gamesError()))
        );
      })
    )
  );

  lobbyRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lobbyRequested),
      withLatestFrom(this.store.select(selectAllLobbyPregmatic)),
      filter(([, lobbyPregmaticList]) => !!lobbyPregmaticList),
      switchMap(() => {
        return this.gameGroupsService.onGetLobbyPregmaticList().pipe(
          map((lobbyPregmaticList: LobbyPregmatic[]) =>
            lobbyLoaded({ lobbyPregmaticList })
          ),
          catchError(() => of(lobbyError()))
        );
      })
    )
  );

  lastPlayedGamesRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lastPlayedGamesRequested),
      withLatestFrom(this.store.select(selectLastPlayedState)),
      filter(([, lastPlayedList]) => !!lastPlayedList),
      switchMap(() => {
        return this.gameGroupsService.onGetLastedPlayedGamesList().pipe(
          map((lastPlayedList: number[]) =>
            lastPlayedGamesLoaded({ lastPlayedList })
          ),
          catchError(() => of(lastPlayedGamesError()))
        );
      })
    )
  );
}
