import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  SimpleChange,
  EventEmitter,
  HostListener,
  ElementRef,
  Component,
  OnDestroy,
  OnChanges,
  ViewChild,
  Output,
  Input,
} from "@angular/core";

// Actions
import { bannerRequested } from "src/app/modules/banner/store/actions/banners.actions";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import Player from "@vimeo/player";

// Models
import { LobbyByPageNameConfigurations } from "src/app/models/configurations/menu/lobby-by-page-name-configuration.model";
import { MenuConfigurationType } from "src/app/models/configurations/menu/menu-configuration-type.model";
import { BannerRequest } from "src/app/modules/banner/models/banner-request.model";
import { BannerBonus } from "src/app/modules/banner/models/banner-bonus.model";
import { BannerZone } from "src/app/modules/banner/models/banner-zone.model";
import { Banner } from "src/app/modules/banner/models/banner.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { getBanner } from "src/app/modules/banner/store/selectors/banners.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { AffiliateService } from "src/app/modules/auth/services/affiliate.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Utilities
import { replaceAllUtility } from "src/app/modules/shared/utilities/replace-all.utilities";

@Component({
  selector: "app-custom-banner",
  templateUrl: "./custom-banner.component.html",
  styleUrls: ["./custom-banner.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CustomBannerComponent implements OnChanges, OnDestroy {
  // View Child
  @ViewChild("banner", { static: false }) cashier: ElementRef;

  // Outputs
  @Output() callOnBannerApiSuccess: EventEmitter<void> = new EventEmitter<
    void
  >();

  // Inputs
  @Input() callingFrom: string = "";
  @Input() zoneId: string = "";

  // Numbers
  userTotalBalance: number = 0;

  // Strings
  liveCasinoZoneId: string = "b_all-live";
  mediaUrl: string = environment.mediaUrl;
  affiliateOfferJsonText: string = "";
  casinoZoneId: string = "b_casino";
  getAffiliateValue: string = "";
  languageCode: string = "";
  theme: string = "";

  // Booleans
  isShowBannerFromAffiliate: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Arrays
  activeBannersList: Banner[] = [];

  // Enums
  deviceType: "desktop" | "mobile" = "desktop";
  currencyCode: WorldCurrencyCode;

  // Objects
  swiperBanner: SwiperConfigInterface = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    loop: false,
    resistanceRatio: 0,
    autoplay: {
      delay: 30000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".banner-pagination",
      clickable: true,
    },
    breakpoints: {
      767: {
        pagination: false,
      },
    },
  };

  // Subscriptions
  bannerSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private customBannerService: CustomBannerService,
    private registrationService: RegistrationService,
    private translationService: TranslationService,
    private userDetailsService: UserDetailsService,
    private affiliateService: AffiliateService,
    private gamePlayService: GamePlayService,
    private emitterService: EmitterService,
    private sessionService: SessionService,
    private utilityService: UtilityService,
    private store: Store<AppState>
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.languageCode = this.utilityService.getLangCode();

    if (this.userDetailsService.getUserTotalBalance()) {
      this.userTotalBalance = this.userDetailsService.getUserTotalBalance();
    }

    this.onSetLocaleTheme();

    this.getWindowType();

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;

          this.onGetUserAffiliateValue();

          this.onReloadBanner(true);
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = !isLoggedOut;

            this.onGetUserAffiliateValue();

            this.onBannerCalling();
          }
        }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.onGetUserAffiliateValue();

          this.onSetLocaleTheme();
        }),
      this.emitterService.isSuccessfulDepositSubject$.subscribe(() => {
        this.onReloadBanner(true);
      }),
      this.userDetailsService.userBalanceUpdatedSubject$.subscribe(
        (balanceUpdateMessage: string) => {
          if (balanceUpdateMessage == "UserBalanceUpdated") {
            this.userTotalBalance = this.userDetailsService.getUserTotalBalance();

            this.utilityService.toggleBannerDepositBtn(this.userTotalBalance);
          }
        }
      ),
      this.userDetailsService.currencyCodeBehaviourSubject$.subscribe(
        (currencyCode: WorldCurrencyCode) => {
          this.currencyCode = currencyCode;
        }
      ),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["zoneId"] &&
      changes["zoneId"].previousValue != changes["zoneId"].currentValue &&
      changes["zoneId"].currentValue !== ""
    ) {
      this.zoneId = changes["zoneId"].currentValue;

      this.zoneId = `b_${this.zoneId}`;

      this.onReloadBanner(true);
    }

    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !=
        changes["callingFrom"].currentValue
    ) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onResize(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1023) {
      this.deviceType = "desktop";
    } else {
      this.deviceType = "mobile";
    }

    if (
      (clientWidth >= 500 &&
        window.matchMedia("(orientation: landscape)").matches) ||
      (clientWidth >= 768 &&
        window.matchMedia("(orientation: portrait)").matches)
    ) {
      this.deviceType = "desktop";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onReloadBanner(refreshProfileCache?: boolean): void {
    /* this if condition is to avoid calling the banner API when the child zones are called. Only parent zones
    are supported for banners. This avoids the banner being refreshed everytime a category is chosen within a lobby */
    this.bannerSubscription = this.store
      .select(getBanner({ zoneId: this.zoneId }))
      .subscribe(
        (list: {
          isLoading: boolean;
          isLoaded: boolean;
          banners: Banner[];
        }) => {
          if (list) {
            if (list.isLoaded && !list.isLoading) {
              if (this.customBannerService.getIsProfileRefreshRequired()) {
                refreshProfileCache = true;

                this.customBannerService.onSetIsProfileRefreshRequired(false);
              }

              this.onShowBanner(list);
            }

            if (!list.isLoaded && list.isLoading) {
              if (this.zoneId && !this.isShowBannerFromAffiliate) {
                const queryRequest: BannerZone = refreshProfileCache
                  ? { zoneId: this.zoneId, refreshProfileCache: true }
                  : { zoneId: this.zoneId };

                this.onGetBannersByZoneId(queryRequest);
              }
            }
          }
        }
      );
  }

  onOpenRegistrationPopup(): void {
    this.registrationService.onOpenRegistration();
  }

  onSetLocaleTheme(): void {
    this.theme = this.utilityService.setLocaleBasedTheme();
  }

  onGetUserAffiliateValue(): void {
    let affilateLanguage: string = this.affiliateService.getAffiliateCookies(
      "affiliateLang"
    );

    let affiliateValuesList: string[] = this.translationService
      .get("affiliateids.affiliateValue")
      .split(",");

    if (this.affiliateService.getAffiliateCookies("affId")) {
      this.getAffiliateValue = this.affiliateService
        .getAffiliateCookies("affId")
        .split("_")[0];
    }

    if (
      this.getAffiliateValue &&
      affilateLanguage &&
      affiliateValuesList &&
      affiliateValuesList.indexOf(this.getAffiliateValue) > -1 &&
      affilateLanguage === this.languageCode
    ) {
      this.isShowBannerFromAffiliate = true;

      this.onRenderAffiliteJsonOfferTextAsHtml();
    } else {
      this.isShowBannerFromAffiliate = false;
    }
  }

  onGetBannersByZoneId(bannerRequest: BannerRequest): void {
    this.activeBannersList = undefined;

    this.isLoading = true;

    this.store.dispatch(bannerRequested({ bannerRequest }));
  }

  onShowBanner(list: {
    isLoading: boolean;
    isLoaded: boolean;
    banners: Banner[];
  }) {
    if (list && list.isLoaded && !list.isLoading) {
      this.isLoading = false;

      let bannerList: Banner[] = list.banners;

      if (bannerList && bannerList.length > 0) {
        this.activeBannersList = bannerList;

        this.activeBannersList = this.activeBannersList.map(
          (banner: Banner) => {
            let content: string = replaceAllUtility(banner.content.toString(), {
              "{{site_url}}": environment.siteUrl,
              "{{media_url}}": environment.mediaUrl,
            });

            /*
              adding click event to whole banner container inorder to listen all click
              events within the banner...
            */
            setTimeout(() => {
              this.utilityService.onToggleCMSButtons();

              this.utilityService.toggleBannerDepositBtn(this.userTotalBalance);

              const iframe: Element = document.querySelector(
                `.dynamicBannerContainer-${banner.id} iframe.videoIframe`
              );

              if (iframe) {
                const player: Player = new Player(iframe, {
                  background: 1,
                  muted: 1,
                  loop: 0,
                  title: "promotional-banners",
                });
              }

              if (this.callingFrom === "allgames-page") {
                this.callOnBannerApiSuccess.emit();
              }
            });

            return {
              ...banner,
              content: content,
            };
          }
        );

        this.customBannerService.onBroadCastIsBannerAvialable(true);
      } else {
        this.activeBannersList = [];

        this.customBannerService.onBroadCastIsBannerAvialable(false);
      }
    }
  }

  onJoinBanners(event: Event): void {
    let target: HTMLInputElement = <HTMLInputElement>event.target;

    let routerLink: string = target.getAttribute("routerLink");

    let data: BannerBonus;

    if (routerLink === "deposit" && target.getAttribute("bonusCode")) {
      data = { bonusCode: target.getAttribute("bonusCode") };
    }

    /* 
      We are using this registration routing here because of circular
      dependency
    */
    if (
      routerLink &&
      (routerLink === "registration" || routerLink === "register")
    ) {
      if (!this.isLoggedIn) {
        this.registrationService.onOpenRegistration();
      }
    } else if (routerLink) {
      if (
        routerLink &&
        this.multiLanguageService.getActiveLobby(routerLink.split("/")[1]) ===
          "game"
      ) {
        this.gamePlayService.onClearGameWindowData();
      }

      /*
        Below Utility Handler decide routerLink event action on banner
        like registration button , login btn, game play btn deposit etc...
      */
      this.utilityService.onCmsRoutingHandler(routerLink, data);
    }
  }

  onBannerCalling(): void {
    const menuConfigurationType: MenuConfigurationType = this.utilityService.getBrandMenuConfigurationType(
      "lobbyByPageName"
    );

    const lobby: string[] = (menuConfigurationType as LobbyByPageNameConfigurations)
      .livecasinolobbies;

    if (this.zoneId) {
      const activeLobbyname: string = this.zoneId.replace("b_", "");

      if (
        menuConfigurationType &&
        (menuConfigurationType as LobbyByPageNameConfigurations)
          .livecasinolobbies &&
        lobby.indexOf(activeLobbyname) <= -1
      ) {
        this.onReloadBanner(true);
      }
    }
  }

  onRenderAffiliteJsonOfferTextAsHtml(): void {
    this.affiliateOfferJsonText = this.translationService.get(
      `affiliate.${this.getAffiliateValue}_offer`
    );
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.bannerSubscription) this.bannerSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
