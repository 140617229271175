export enum CountryCodes {
  "CA" = "CA",
  "FI" = "FI",
  "NO" = "NO",
  "IN" = "IN",
  "NZ" = "NZ",
  "NL" = "NL",
  "JP" = "JP",
  "DE" = "DE",
  "AT" = "AT",
  "BR" = "BR",
  "PE" = "PE",
  "CL" = "CL",
  "PY" = "PY",
  "UY" = "UV",
  "BO" = "BO",
  "HU" = "HU",
}
