import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs";

// Models
import { SocketMessage } from "src/app/modules/shared/models/socket-message.model";

@Injectable({
  providedIn: "root",
})
export class EmitterService {
  // Booleans
  isCCCardsAvailableStatus: boolean = false;

  // --------------------------------------------------------
  // Subject - Socket Message Subject
  private socketMessageSubject: Subject<SocketMessage> = new Subject<
    SocketMessage
  >();
  public socketMessageSubject$: Observable<
    SocketMessage
  > = this.socketMessageSubject.asObservable();

  // --------------------------------------------------------
  // Subject - successful Deposit Subject
  private isSuccessfulDepositSubject: Subject<boolean> = new Subject<boolean>();
  public isSuccessfulDepositSubject$: Observable<
    boolean
  > = this.isSuccessfulDepositSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Is Open Reward Terms And Conditions
  private isOpenRewardTermsConditionsSubject: Subject<boolean> = new Subject<
    boolean
  >();
  public isOpenRewardTermsConditionsSubject$: Observable<
    boolean
  > = this.isOpenRewardTermsConditionsSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Is No CC Cards Available
  private isNoCCCardsAvailableSubject: Subject<boolean> = new Subject<
    boolean
  >();
  public isNoCCCardsAvailableSubject$: Observable<
    boolean
  > = this.isNoCCCardsAvailableSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Is Promotional Video Enabled
  private isPromotionalVideoEnabledSubject: Subject<boolean> = new Subject<
    boolean
  >();
  public isPromotionalVideoEnabledSubject$: Observable<
    boolean
  > = this.isPromotionalVideoEnabledSubject.asObservable();

  constructor() {}

  // -----------------------------------------------------------------
  // Get Methods
  getIsCCCardsAvailableStatus(): boolean {
    return this.isCCCardsAvailableStatus;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onBroadcastSocketMessage(socketMessage: SocketMessage): void {
    this.socketMessageSubject.next(socketMessage);
  }

  onBroadcastSuccessfulDeposit(isSuccessful: boolean): void {
    this.isSuccessfulDepositSubject.next(isSuccessful);
  }

  onBroadcastRewardTermsAndConditions(
    isOpenRewardTermsConditions: boolean
  ): void {
    this.isOpenRewardTermsConditionsSubject.next(isOpenRewardTermsConditions);
  }

  onBroadcastIsNoCCCardsAvailable(isNoCCCardsAvailable: boolean): void {
    this.isNoCCCardsAvailableSubject.next(isNoCCCardsAvailable);
  }

  onBroadcastIsPromotionalVideoEnabled(
    isPromotionalVideoEnabled: boolean
  ): void {
    this.isPromotionalVideoEnabledSubject.next(isPromotionalVideoEnabled);
  }
}
