import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  AfterViewInit,
  SimpleChange,
  Component,
  OnChanges,
  OnDestroy,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { TriggerLoginGameDetails } from "src/app/modules/shared/models/trigger-login/trigger-login-game-details.model";
import { FreeGames } from "src/app/modules/game-groups/models/free-games/free-games.model";
import { ProviderPregmatic } from "src/app/modules/game-groups/models/provider.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

@Component({
  selector: "app-show-case",
  templateUrl: "./show-case.component.html",
  styleUrls: ["./show-case.component.scss"],
})
export class ShowCaseComponent implements OnChanges, OnDestroy, AfterViewInit {
  // Inputs
  @Input() gameGroupData: ProviderPregmatic;
  @Input() callingFromlobby: string = "";

  // Strings
  gameId: string = "booming_5c9547cf2dd5bd000b45103b";
  mediaUrlPath: string = environment.mediaUrlPath;
  gamesUrlPath: string = environment.gamesUrlPath;
  languageCode: string = "";
  imgixParams: string = "";
  theme: string = "";

  // Booleans
  isLoggedIn: boolean = false;
  isVisible: boolean = false;

  // Objects
  gameURL: SafeResourceUrl;
  game: GamePregmatic;

  // Subscriptions
  subscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private gameGroupsService: GameGroupsService,
    private gamePlayService: GamePlayService,
    private mixPanelService: MixPanelService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private domSanitizer: DomSanitizer,
    private store: Store<AppState>,
    private router: Router
  ) { }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.imgixParams = this.utilityService.getImgixParams();

    this.theme = this.utilityService.setLocaleBasedTheme();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.theme = this.utilityService.setLocaleBasedTheme();
        }),
      this.store.select(selectAuthLoginIsLoggedIn).subscribe((isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["gameGroupData"] &&
      changes["gameGroupData"].previousValue !==
      changes["gameGroupData"].currentValue
    ) {
      this.gameGroupData = changes["gameGroupData"].currentValue;

      this.game = this.gameGroupData.games[0];

      if (this.game && this.game.hasDemo) {
        this.onGetFreeGame(this.game.gameCode);
      }
    }

    if (
      changes["callingFromlobby"] &&
      changes["callingFromlobby"].previousValue !==
      changes["callingFromlobby"].currentValue
    ) {
      this.callingFromlobby = changes["callingFromlobby"].currentValue;
    }
  }

  ngAfterViewInit(): void {
    this.isVisible = true;
  }

  // -----------------------------------------------------------------
  // Set Methods
  /*
    We always show user a demo game only here...
    user can click on the btn & navigate to game play window 
    to for real money
  */
  onGetFreeGame(gameId: string): void {
    this.subscription = this.gameGroupsService
      .onGetFreeGame(gameId)
      .subscribe((data: FreeGames) => {
        if (data.gameDetails && data.gameDetails.url) {
          this.gameURL = this.domSanitizer.bypassSecurityTrustResourceUrl(
            data.gameDetails.url
          );
        }
      });
  }

  onGameLaunch(gameName: string): void {
    gameName = gameName.toLowerCase().split(" ").join("-");

    this.gamePlayService.onClearGameWindowData();

    if (this.isLoggedIn) {
      this.gamePlayService.onSetGameCalledFrom("realgame");
    } else {
      this.gamePlayService.onSetGameCalledFrom("freegame");
    }

    setTimeout(() => {
      if (gameName && this.isLoggedIn) {
        this.mixPanelService.onTrackGameLaunchEvent({
          gameData: {
            name: gameName,
          },
          gameType: "realgame",
          groupName: this.gameGroupData ? this.gameGroupData.name : "",
          lobbyName: this.callingFromlobby,
          callingFrom: "showcase",
        });

        this.router.navigate([
          `${this.languageCode}/${this.translationService.get(
            "url.game"
          )}/${gameName}`,
        ]);
      } else {
        this.onTriggerLogin(gameName);
      }
    });
  }

  onTriggerLogin(gameName: string): void {
    let gameDetails: TriggerLoginGameDetails = {
      navigateTo: "gameWindow",
      path: gameName,
    };

    if (this.gameGroupData) {
      gameDetails.gameGroupName = this.gameGroupData.name;
    }

    if (this.callingFromlobby) {
      gameDetails.lobbyName = this.callingFromlobby;
    }

    this.commonService.onBroadcastActiveAcountView("login");

    this.commonService.onSetNavigateAfterLogin({
      type: "url",
      data: gameDetails,
    });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
