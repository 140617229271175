import { FormGroup } from "@angular/forms";
import { Component } from "@angular/core";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Models
import { UserPaymentMethod } from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-response.model";
import { DepositMethodInputField } from "src/app/models/configurations/deposit-method-configuration.model";

@Component({
  selector: "app-form-label",
  templateUrl: "./form-label.component.html",
  styleUrls: ["./form-label.component.scss"],
})
export class FormLabelComponent extends FormValidationComponent {
  // Strings
  callingFrom: string = "";

  // Booleans
  isUsedAccount: boolean = false;

  // Objects
  selectedMethodData: UserPaymentMethod;
  config: DepositMethodInputField;

  // Form Groups
  formGroup: FormGroup;

  constructor() {
    super();
  }
}
