export const getCreditCardValidation = (ccNum: string): boolean => {
  const arr: number[] = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];

  let len = ccNum.length;

  let bit: number = 1;
  let sum: number = 0;

  let val: number = 0;

  if (len >= 13 && len <= 19) {
    while (len) {
      val = parseInt(ccNum.charAt(--len), 10);
      sum += (bit ^= 1) ? arr[val] : val;
    }
  }

  return sum && sum % 10 === 0;
};
