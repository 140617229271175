import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

// Components
import { LiveCasinoGroupDisplayComponent } from "src/app/modules/game-groups/components/live-casino-group-display/live-casino-group-display.component";
import { LiveCasinoPlayAgainComponent } from "src/app/modules/game-groups/components/live-casino-play-again/live-casino-play-again.component";
import { DefaultLiveCasinoComponent } from "src/app/modules/game-groups/components/default-live-casino/default-live-casino.component";
import { GameGoupsDisplayComponent } from "src/app/modules/game-groups/components/game-groups-display/game-groups-display.component";
import { PosterLiveCasinoComponent } from "src/app/modules/game-groups/components/poster-live-casino/poster-live-casino.component";
import { BetRangeFilterComponent } from "src/app/modules/game-groups/components/bet-range-filter/bet-range-filter.component";
import { ProviderFilterComponent } from "src/app/modules/game-groups/components/provider-filter/provider-filter.component";
import { JackpotCounterComponent } from "src/app/modules/game-groups/components/jackpot-counter/jackpot-counter.component";
import { DefaultCasinoComponent } from "src/app/modules/game-groups/components/default-casino/default-casino.component";
import { PosterJockpotComponent } from "src/app/modules/game-groups/components/poster-jockpot/poster-jockpot.component";
import { ProviderLobbyComponent } from "src/app/modules/game-groups/components/provider-lobby/provider-lobby.component";
import { ViewAllGamesComponent } from "src/app/modules/game-groups/components/view-all-games/view-all-games.component";
import { GameProviderComponent } from "src/app/modules/game-groups/components/game-provider/game-provider.component";
import { JackpotGamesComponent } from "src/app/modules/game-groups/components/jackpot-games/jackpot-games.component";
import { VimeoPlayerComponent } from "src/app/modules/game-groups/components/vimeo-player/vimeo-player.component";
import { ShowCaseComponent } from "src/app/modules/game-groups/components/show-case/show-case.component";
import { GameCardComponent } from "src/app/modules/game-groups/components/game-card/game-card.component";
import { AllGamesComponent } from "src/app/modules/game-groups/components/all-games/all-games.component";
import { ShowReelComponent } from "src/app/modules/game-groups/components/show-reel/show-reel.component";
import { StorylyComponent } from "src/app/modules/game-groups/components/storyly/storyly.component";
import { PostersComponent } from "src/app/modules/game-groups/components/poster/posters.component";

// Effects
import { GamesEffects } from "src/app/modules/game-groups/store/effects/games.effects";

// Modules
import { MbscModule } from "src/app/modules/shared/libraries/mobiscroll/js/mobiscroll.custom-4.1.0.min";
import { LivespinsModule } from "src/app/modules/livespins/livespins.module";
import { SharedModule } from "src/app/modules/shared/shared.module";

// Reducers
import * as fromGamingReducer from "src/app/modules/game-groups/store/reducers/games.reducer";

@NgModule({
  declarations: [
    LiveCasinoGroupDisplayComponent,
    LiveCasinoPlayAgainComponent,
    DefaultLiveCasinoComponent,
    GameGoupsDisplayComponent,
    PosterLiveCasinoComponent,
    ProviderFilterComponent,
    BetRangeFilterComponent,
    JackpotCounterComponent,
    DefaultCasinoComponent,
    PosterJockpotComponent,
    ProviderLobbyComponent,
    GameProviderComponent,
    JackpotGamesComponent,
    ViewAllGamesComponent,
    VimeoPlayerComponent,
    ShowCaseComponent,
    ShowReelComponent,
    GameCardComponent,
    AllGamesComponent,
    PostersComponent,
    StorylyComponent
  ],
  imports: [
    LivespinsModule,
    CommonModule,
    SharedModule,
    MbscModule,
    EffectsModule.forFeature([GamesEffects]),
    StoreModule.forFeature(
      fromGamingReducer.GamesStateFeatureKey,
      fromGamingReducer.reducer
    ),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    LiveCasinoGroupDisplayComponent,
    LiveCasinoPlayAgainComponent,
    DefaultLiveCasinoComponent,
    GameGoupsDisplayComponent,
    PosterLiveCasinoComponent,
    ProviderFilterComponent,
    BetRangeFilterComponent,
    DefaultCasinoComponent,
    PosterJockpotComponent,
    ProviderLobbyComponent,
    GameProviderComponent,
    JackpotGamesComponent,
    ViewAllGamesComponent,
    VimeoPlayerComponent,
    ShowCaseComponent,
    ShowReelComponent,
    GameCardComponent,
    AllGamesComponent,
    PostersComponent,
    StorylyComponent,
  ],
})
export class GameGroupsModule { }
