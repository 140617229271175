import { Observable, of, Subscription } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  HttpInterceptor,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";

// Actions
import { logoutRequestedRefresh } from "src/app/modules/auth/store/actions/auth.actions";

// Configurations
import {
  languageToGameLaunchConfigurations,
  localToCmsLanguageConfigurations,
  cmsToLocalLanguageConfigurations,
  countryLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/multi-languages/utilities/languages.utilities";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  //Strings
  languageCode: string = "";

  // Booleans
  isLoggedIn: boolean = false;

  // Arrays
  acceptedLanguagesList: string[] = [];

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.acceptedLanguagesList = supportedLanguagesList();

    this.languageCode = this.utilityService.getLangCode();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store.select(selectAuthLoginIsLoggedIn).subscribe((isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut)
    ];
  }

  // -----------------------------------------------------------------
  // Intercept
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const urlSegments: string[] = this.utilityService
      .getDecodedCurrentPath()
      .split("/");

    let landCode: string =
      urlSegments && urlSegments.length >= 2 ? urlSegments[1] : "";

    let userLanguageCode: string = this.utilityService.getLangCode();

    if (this.isLoggedIn && userLanguageCode) {
      this.languageCode = userLanguageCode;
    } else if (landCode && _.contains(this.acceptedLanguagesList, landCode)) {
      this.languageCode = urlSegments[1];
    } else {
      this.languageCode = environment.defaultLanguage;
    }
    /*
      If we want to modify any request object..we can do it there like
      adding header or modifying header base on endpoint ur or request
      Method type
    */
    let updatedRequest: HttpRequest<any> = request;

    if (
      this.languageCode &&
      request.url &&
      (request.url.includes("/ajax/game/") ||
        request.url.includes("/ajax/banner/getBanners") ||
        request.url.includes("/ajax/promotion/getPromotions"))
    ) {
      updatedRequest = request.clone({
        setParams: {
          languageId: localToCmsLanguageConfigurations[this.languageCode],
        },
      });
    }

    if (
      this.languageCode &&
      request.url &&
      request.url.includes("/ajax/staticPage")
    ) {
      updatedRequest = request.clone({
        setParams: {
          language: localToCmsLanguageConfigurations[this.languageCode],
        },
      });
    }

    if (
      this.languageCode &&
      request.url &&
      request.url.includes("/ajax/faq/")
    ) {
      updatedRequest = request.clone({
        setParams: {
          lang: localToCmsLanguageConfigurations[this.languageCode],
        },
      });
    }

    if (
      this.languageCode &&
      request.url &&
      (request.url.includes("/ajax/launcher/getRealGames") ||
        request.url.includes("/ajax/launcher/getFreeGames"))
    ) {
      updatedRequest = request.clone({
        setParams: {
          languageId: languageToGameLaunchConfigurations[this.languageCode],
        },
      });
    }

    return next.handle(updatedRequest).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          /*
            You will receive every http call response object here
            we can write some global logic to handle it
            like navigation to different page based on response.
          */
          if (event.body && event.body.error && event.body.error === 150) {
            this.onSetLanguage(event.body.countryCode);

            this.onNavigatorBasedOnError(event.body.error);
          } else if (event.body && event.body.error && event.body.error) {
            this.onNavigatorBasedOnError(event.body.error);
          } else if (event && event["error"]) {
            this.onNavigatorBasedOnError(event["error"]);
          } else if (event && event.body.errors && event.body.errors[0]) {
            this.onNavigatorBasedOnError(event.body.errors[0].errorCode);
          } else if (
            event &&
            event.body &&
            event.body.is_IpBlocked &&
            JSON.parse(event.body.is_IpBlocked) == true
          ) {
            this.onSetLanguage(event.body.countryCode);

            this.onNavigatorBasedOnError("country_blocked");
          } else {
            let pathName: string = this.utilityService.getDecodedCurrentPath();

            if (
              pathName.includes("maintenance") ||
              pathName.includes("countryblock")
            ) {
              this.router.navigate([`${this.languageCode}/casino`]);
            }
          }
        }

        return event;
      }),
      catchError((event: HttpEvent<any>) => {
        return of(event);
      })
    );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetLanguage(countryCode: string): void {
    let ipBasedLangCode: string = "";

    if (
      countryCode &&
      countryLanguageConfigurations.hasOwnProperty(countryCode)
    ) {
      ipBasedLangCode =
        cmsToLocalLanguageConfigurations[
        countryLanguageConfigurations[countryCode]
        ];
    }

    this.languageCode = ipBasedLangCode
      ? ipBasedLangCode
      : environment.defaultLanguage;

    this.translationService.onUseLanguage(
      localToCmsLanguageConfigurations[this.languageCode]
    );
  }

  onNavigatorBasedOnError(code: string | number): void {
    switch (code) {
      case 150: {
        this.router.navigate([`${this.languageCode}/maintenance`]);
        break;
      }
      case "country_blocked": {
        this.router.navigate([`${this.languageCode}/countryblock`]);
        break;
      }
      case 100187: {
        this.store.dispatch(logoutRequestedRefresh());
        break;
      }
      default:
        return;
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
