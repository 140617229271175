import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  SimpleChange,
  HostListener,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Input,
} from "@angular/core";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import * as _ from "underscore";

// Models
import { GameLaunch } from "src/app/modules/game-groups/models/game-launch/game-launch.model";
import { SwiperLazy } from "src/app/modules/game-groups/models/swiper/swiper-lazy.model";
import { ProviderPregmatic } from "src/app/modules/game-groups/models/provider.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Utilities
import { swiperLazyConfigurationsChange } from "src/app/modules/game-groups/utilities/swiper.utility";

@Component({
  selector: "app-show-reel",
  templateUrl: "./show-reel.component.html",
  styleUrls: ["./show-reel.component.scss"],
})
export class ShowReelComponent implements OnInit, OnChanges, OnDestroy {
  // Inputs
  @Input() gameGroupData: ProviderPregmatic;
  @Input() callingFromlobby: string = "";

  // Strings
  languageCode: string = "";

  // Booleans
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Arrays
  gamesList: GamePregmatic[] = [];

  // Swiper Configurations
  swiperVidoeShow: SwiperConfigInterface = {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 16,
    freeMode: true,
    navigation: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    resistanceRatio: 0,
    breakpoints: {
      // breakpoints works like max-width css
      2000: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 16,
      },
      1599: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 16,
      },
      767: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 8,
        freeMode: false,
      },
      480: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 8,
        freeMode: false,
      },
    },
  };
  swiperLazyConfigurations: SwiperLazy = swiperLazyConfigurationsChange(8, 4);

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private gamePlayService: GamePlayService,
    private mixPanelService: MixPanelService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private router: Router
  ) { }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store.select(selectAuthLoginIsLoggedIn).subscribe((isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["gameGroupData"] &&
      changes["gameGroupData"].previousValue !==
      changes["gameGroupData"].currentValue
    ) {
      this.gameGroupData = changes["gameGroupData"].currentValue;

      if (this.gameGroupData.group_type === "manual") {
        this.gamesList = this.utilityService.sortGameByCountryOrder(
          this.gameGroupData.games
        );
      } else {
        this.gamesList = this.gameGroupData.games;
      }

      if (this.gamesList && this.gamesList.length > 0) {
        this.swiperLazyConfigurations.totalNumberOfGamesExist = this.gamesList.length;
      }
    }

    if (
      changes["callingFromlobby"] &&
      changes["callingFromlobby"].previousValue !==
      changes["callingFromlobby"].currentValue
    ) {
      this.callingFromlobby = changes["callingFromlobby"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Host Listeners
  /*
    Here we are stopping tab default behaviour because of swiper arrow moving to middle
  */
  @HostListener("keydown", ["$event"])
  onKeydown(event): void {
    if (
      event.which == 9 &&
      document.getElementsByClassName("swiper-container")
    ) {
      event.preventDefault();
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onNavigateAllGamesPage(): void {
    this.utilityService.navigateAllGamesPage(this.gameGroupData);
  }

  onGameLaunch(gameName: string, hasDemo: boolean): void {
    gameName = this.utilityService.convertGameNameToUrl(gameName);

    this.gamePlayService.onClearGameWindowData();

    if (this.isLoggedIn) {
      this.gamePlayService.onSetGameCalledFrom("realgame");
    } else {
      this.gamePlayService.onSetGameCalledFrom("freegame");
    }

    setTimeout(() => {
      if (gameName && (this.isLoggedIn || (!this.isLoggedIn && hasDemo))) {
        this.mixPanelService.onTrackGameLaunchEvent({
          gameData: {
            name: gameName,
          },
          gameType: "realgame",
          groupName: this.gameGroupData ? this.gameGroupData.name : "",
          lobbyName: this.callingFromlobby,
          callingFrom: "showreel",
        });

        this.router.navigate([
          `${this.languageCode}/${this.translationService.get(
            "url.game"
          )}/${gameName}`,
        ]);
      } else {
        this.commonService.onBroadcastActiveAcountView("login");
      }
    });
  }

  onTriggerGameLaunch(gameLaunch: GameLaunch): void {
    this.gamePlayService.onSetGameCalledFrom(gameLaunch.callingFrom);

    this.onGameLaunch(gameLaunch.name, gameLaunch.hasDemo);
  }

  /*
    Function for Lazy loading of games goes here
    on every next click of silde below function will be trigerred
    & calculated how many game have to load amount that total games present

    This works on bases of swiperLazyConfig defined above...
  */
  onSwiperNextEvent(): void {
    if (this.swiperLazyConfigurations.backwardslideIndex) {
      this.swiperLazyConfigurations.backwardslideIndex =
        this.swiperLazyConfigurations.backwardslideIndex - 1;
      return;
    }

    if (
      this.swiperLazyConfigurations.indexNumberOfGameTilesToshow <
      this.swiperLazyConfigurations.totalNumberOfGamesExist
    ) {
      this.swiperLazyConfigurations.indexNumberOfGameTilesToshow =
        this.swiperLazyConfigurations.indexNumberOfGameTilesToshow +
        this.swiperLazyConfigurations.numberOfExtraGamesToLoadForSlide;

      this.swiperLazyConfigurations.forwardslideIndex =
        this.swiperLazyConfigurations.forwardslideIndex + 1;
    }
  }

  onSwiperPrevEvent(): void {
    this.swiperLazyConfigurations.backwardslideIndex =
      this.swiperLazyConfigurations.backwardslideIndex + 1;
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
