import { Component, HostListener, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  NavigationStart,
  ActivatedRoute,
  NavigationEnd,
  RouterEvent,
  ParamMap,
  Router,
} from "@angular/router";

// Actions
import {
  loginStatusRequested,
  logoutRequested,
} from "src/app/modules/auth/store/actions/auth.actions";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";
import { AffiliateService } from "src/app/modules/auth/services/affiliate.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";
import { LoginService } from "src/app/modules/auth/services/login.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnDestroy {
  // Strings
  languageCode: string = "";

  // Booleans
  isShowSearchResult: boolean = false;
  isBannerAvialable: boolean = false;
  isSearchRequired: boolean = true;
  isLoggedIn: boolean = false;
  isSticky: boolean = false;
  isHidden: boolean = false;

  // Enums
  windowType: "desktop" | "mobile" = "desktop";

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private customBannerService: CustomBannerService,
    private affiliateService: AffiliateService,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private loginService: LoginService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    /*
      We call this method onfresh of page...To check user login
      session exist or not and bases on it's response we land user on
      login state or logout state  
    */
    this.onGetLoginStatus();

    this.onStorageHandlers();

    this.onEnableSearch();

    this.getWindowType();

    this.subscriptions = [
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.onEnableSearch();
        }

        if (event instanceof NavigationStart) {
          this.isHidden = event.url.includes("livespins");
        }
      }),
      /*
        Below Logic is to get the affiliate information form URL which is passed by
        affiliate system
        & we save it in cookies & pass it to BE when user get registered after navigating
        from affiliate system
      */
      this.activatedRoute.queryParamMap.subscribe((queryParams: ParamMap) => {
        let affiliateId: string = queryParams.get("affid");

        let tracker: string = queryParams.get("trackerid");

        let banner: string = queryParams.get("bannerid");

        this.affiliateService.onSetAffiliateCookies(
          affiliateId,
          tracker,
          banner
        );
      }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.customBannerService.isBannerAvailableSubject$.subscribe(
        (isBannerAvialable: boolean) => {
          this.isBannerAvialable = isBannerAvialable;
        }
      ),
    ];
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onResize(): void {
    this.getWindowType();
  }

  @HostListener("window:orientationchange") onRotate(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth <= 1023) {
      this.windowType = "mobile";
    } else {
      this.windowType = "desktop";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  /*
    This Function decides under which route search should be enabled in header
    section, Basically search is enable on all routes except casino, live casino
    reset-password & unsubscribe
  
    where on casino & live casino we have seach which is at different position
    so we call search reusable component with in casino & live casino compoenets
  */
  onEnableSearch(): void {
    const urlSegments: string[] = this.utilityService.getDecodedCurrentPath()
      ? this.utilityService.getDecodedCurrentPath().split("/")
      : [];

    if (
      urlSegments &&
      (urlSegments.length <= 2 ||
        (urlSegments.length === 3 &&
          [
            "casino",
            "live-casino",
            "reset-password",
            "unsubscribe",
            "livespins",
          ].includes(urlSegments[2])))
    ) {
      this.isSearchRequired = false;
    } else {
      this.isSearchRequired = true;
    }
  }

  onGetLoginStatus(): void {
    this.store.dispatch(loginStatusRequested());
  }

  onNavigateToHome(): void {
    if (this.sessionService.getIsUserLoggedIn()) {
      this.router.navigate([`${this.languageCode}/casino`]);

      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      this.router.navigate([this.languageCode]);
    }
  }

  onCloseSearchResults(): void {
    this.isShowSearchResult = false;

    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");
  }

  onStorageHandlers(): void {
    /*
      This even will listen for removal of localstorage item in new Tabs
      and logout user from all tabs
    */
    window.addEventListener("storage", (event: StorageEvent) => {
      if (
        (event.type === "storage" &&
          event.newValue !== "true" &&
          event.key === "caf_user") ||
        (event.newValue === null && event.key === null)
      ) {
        this.store.dispatch(logoutRequested({}));
      }
      /*
        This will trigger login in all other Tabs
      */
      if (
        event.type === "storage" &&
        event.key === "caf_user" &&
        event.newValue === "true"
      ) {
        if (
          window.regily &&
          window.regily.signUp &&
          window.regily.signUp.isOpen
        ) {
          window.regily.signUp.close();
        }

        this.router.navigate([`${this.languageCode}/casino`]);

        if (this.commonService.getActiveAccountMenu()) {
          this.commonService.onBroadcastActiveAcountView("");
        }

        this.loginService.onNavigateToUserProfileLanguage();
      }
    });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
