import { createFeatureSelector, createSelector } from "@ngrx/store";

// Reducers
import {
  ProfileStateFeatureKey,
  ProfileReducerState,
} from "src/app/modules/user/store/reducers/profile.reducer";

// Select the Main State
const selectProfileReducerState = createFeatureSelector<ProfileReducerState>(
  ProfileStateFeatureKey
);

// Profile Balance
export const selectAuthProfileBalanceLoaded = createSelector(
  selectProfileReducerState,
  (state: ProfileReducerState) => {
    return {
      profileBalance: state.profileBalance,
      isLoaded: state.isLoaded,
    };
  }
);

export const selectAuthProfileBalance = createSelector(
  selectProfileReducerState,
  (state: ProfileReducerState) => {
    return state.profileBalance;
  }
);

// User Profile Balance
export const selectUserProfileBalanceLoaded = createSelector(
  selectProfileReducerState,
  (state: ProfileReducerState) => {
    return {
      userProfileBalance: state.userProfileBalance,
      isLoaded: state.userProfileBalanceIsLoaded,
    };
  }
);

export const selectUserProfileBalance = createSelector(
  selectProfileReducerState,
  (state: ProfileReducerState) => {
    return state.userProfileBalance;
  }
);
