import { createAction, props } from "@ngrx/store";

// Enums
import { LanguageCodes } from "src/app/models/configurations/enums/language-codes.enum";

export enum LanguagesActionTypes {
  // Languages
  LanguageCodeRequested = "[Language API] Language Code Requested",
  LanguageCodeRequestedRefresh = "[Language API] Language Code Requested Refresh",
  LanguageCodeLoaded = "[Language API] Language Code Loaded",
  LanguageCodeError = "[Language API] Language Code Error",
}

// Language
export const languageCodeRequested = createAction(LanguagesActionTypes.LanguageCodeRequested);
export const languageCodeRequestedRefresh = createAction(
  LanguagesActionTypes.LanguageCodeRequestedRefresh
);
export const languageCodeLoaded = createAction(
  LanguagesActionTypes.LanguageCodeLoaded,
  props<{ language: string, languageCode: string }>()
);
export const languageCodeError = createAction(LanguagesActionTypes.LanguageCodeError);
