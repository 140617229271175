import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Models
import { UploadDocumentsResponse } from "src/app/modules/kyc/models/upload-documents/upload-documents-response.model";
import { UploadDocumentsRequest } from "src/app/modules/kyc/models/upload-documents/upload-documents-request.model";
import { SendDocumentRequest } from "src/app/modules/kyc/models/send-document-request.model";
import { UpdateStatusData } from "src/app/modules/kyc/models/update-status-data.model";
import { FileUploadData } from "src/app/modules/kyc/models/file-upload-data.model";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { AccountService } from "src/app/modules/account/services/account.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { KYCService } from "src/app/modules/kyc/services/kyc.service";

@Component({
  selector: "app-confirm-identity-kyc",
  templateUrl: "./confirm-identity-kyc.component.html",
  styleUrls: ["./confirm-identity-kyc.component.scss"],
})
export class ConfirmIdentityKycComponent implements OnInit, OnDestroy {
  // Strings
  documentSendStatus: string = "";
  theme: string = "";

  // Booleans
  isButtonDisabled: boolean = false;

  // Objects
  updateStatusData: UpdateStatusData = {
    nationalid: {
      message: "",
      status: "",
    },
    utilitybill: {
      message: "",
      status: "",
    },
    "proof-of-payment": {
      message: "",
      status: "",
    },
    "source-of-fund": {
      message: "",
      status: "",
    },
  };

  // To Discover
  accountDocumentStatus: any;
  kycLevelDetails;

  // Subscriptions
  sendDocumentSubs: Subscription;
  userKycLevelSubs: Subscription;

  constructor(
    private translationService: TranslationService,
    private accountService: AccountService,
    private utilityService: UtilityService,
    private kycService: KYCService
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.getAccountVerficationStatus();

    this.getUserKycLevelDetails();

    this.theme = this.utilityService.setLocaleBasedTheme();
  }

  // -----------------------------------------------------------------
  // Set Methods
  onUploadDocument(data: FileUploadData): void {
    if (data) {
      let requestData: UploadDocumentsRequest = {
        file: (data.event.target as HTMLInputElement).files[0],
        type: data.type,
      };

      this.isButtonDisabled = true;

      this.kycService
        .onUploadDocuments(requestData)
        .subscribe((response: UploadDocumentsResponse) => {
          if (response && response.status === StatusResponse.success) {
            if (data.sourceOfIncome) {
              this.onSendDocument(data, {
                listOfSources: data.sourceOfIncome,
                fileType: "sourceOfIncome",
              });
            } else {
              this.onSendDocument(data);
            }
          } else {
            this.onFailedDocument(data, response);
          }
        });
    }
  }

  onSendDocument(data: FileUploadData, requestData?: SendDocumentRequest) {
    this.kycService.onSendDocuments(requestData).subscribe((response) => {
      this.isButtonDisabled = false;

      if (response && response["status"] == "success") {
        if (data.type === "nationalid") {
          this.updateStatusData["nationalid"] = {
            message: this.translationService.get(
              "confirmidentity.file_upload_successs"
            ),
            status: response["status"],
          };
        } else if (data.type === "utilitybill") {
          this.updateStatusData["utilitybill"] = {
            message: this.translationService.get(
              "confirmidentity.file_upload_successs"
            ),
            status: response["status"],
          };
        } else if (data.type === "caf") {
          this.updateStatusData["proof-of-payment"] = {
            message: this.translationService.get(
              "confirmidentity.file_upload_successs"
            ),
            status: response["status"],
          };
        } else {
          this.updateStatusData["source-of-fund"] = {
            message: this.translationService.get(
              "confirmidentity.file_upload_successs"
            ),
            status: response["status"],
          };
        }
        (this.documentSendStatus = this.translationService.get(
          "confirmidentity.doc_send_success"
        )),
          this.getAccountVerficationStatus();
      } else {
        this.documentSendStatus = this.translationService.get(
          "confirmidentity.doc_send_fail"
        );

        this.onFailedDocument(data, response);
      }
    });
  }

  onFailedDocument(data, response): void {
    this.isButtonDisabled = false;
    if (data["type"] === "nationalid") {
      this.updateStatusData["nationalid"] = {
        message: this.translationService.get(
          "confirmidentity.file_upload_error"
        ),
        status: response["status"],
      };
    } else if (data["type"] === "utilitybill") {
      this.updateStatusData["utilitybill"] = {
        message: this.translationService.get(
          "confirmidentity.file_upload_error"
        ),
        status: response["status"],
      };
    } else if (data["type"] === "caf") {
      this.updateStatusData["proof-of-payment"] = {
        message: this.translationService.get(
          "confirmidentity.file_upload_error"
        ),
        status: response["status"],
      };
    } else {
      this.updateStatusData["source-of-fund"] = {
        message: this.translationService.get(
          "confirmidentity.file_upload_error"
        ),
        status: response["status"],
      };
    }
  }

  getUserKycLevelDetails() {
    this.userKycLevelSubs = this.kycService
      .getUserKycLevelDetails()
      .subscribe((data) => {
        this.kycLevelDetails = data;
      });
  }
  getAccountVerficationStatus() {
    this.accountService.onGetAccountVerficationStatus().subscribe((data) => {
      if (data && data["documents"]) {
        this.accountDocumentStatus = {
          addressStatus: data["documents"]["addressStatus"],
          identityStatus: data["documents"]["identityStatus"],
          cafStatus: data["documents"]["cafStatus"],
          sourceOfIncomeStatus: data["documents"]["sourceOfIncomeStatus"],
        };
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sendDocumentSubs) {
      this.sendDocumentSubs.unsubscribe();
    }
    
    if (this.userKycLevelSubs) {
      this.userKycLevelSubs.unsubscribe();
    }
  }
}
