import { takeWhile } from "rxjs/operators";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  HostListener,
  SimpleChange,
  Component,
  OnDestroy,
  OnChanges,
  ViewChild,
  OnInit,
  Input,
} from "@angular/core";

// Libraries
import * as _ from "underscore";
import {
  SwiperConfigInterface,
  SwiperDirective,
  SwiperComponent,
} from "ngx-swiper-wrapper";

// Models
import { FavouriteGameList } from "src/app/modules/game-groups/models/favourite/favourite-game-list.model";
import { SwiperLazy } from "src/app/modules/game-groups/models/swiper/swiper-lazy.model";
import { Favourite } from "src/app/modules/game-groups/models/favourite/favourite.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import {
  selectLastPlayedState,
  selectAllGames,
} from "src/app/modules/game-groups/store/selectors/games.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Utilities
import { swiperLazyConfigurationsChange } from "src/app/modules/game-groups/utilities/swiper.utility";

@Component({
  selector: "app-tag-game",
  templateUrl: "./tag-game.component.html",
  styleUrls: ["./tag-game.component.scss"],
})
export class TagGameComponent implements OnInit, OnChanges, OnDestroy {
  // View Child
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;
  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;

  // Input
  @Input() gameSpecificTag: string[] = [];

  // Strings
  activeTag: string = "";
  theme: string = "";

  // Booleans
  isGamePlayFooterHover: boolean = false;
  isNoCCCardsAvialable: boolean = false;
  isGamesToasterOpen: boolean = false;
  isTouchScreen: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Enums
  windowType: "device" | "mobile" = "device";

  // Arrays
  activeTagGamesList: GamePregmatic[] = [];
  allAvaialbleTagsList: string[] = [];
  allGamesList: GamePregmatic[] = [];

  // Timeouts
  userActivityTimeout: NodeJS.Timer;

  // Others
  swiperTagButton: SwiperConfigInterface = {
    slidesPerView: "auto",
    slidesPerGroup: 2,
    freeMode: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    navigation: true,
    resistanceRatio: 0,
  };
  swiperTagFilter: SwiperConfigInterface;
  swiperLazyConfigurations: SwiperLazy = swiperLazyConfigurationsChange(28, 14);

  // Subscriptions
  subscriptionLastPlayedList: Subscription;
  subscriptionFavourite: Subscription;
  subscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private gameGroupsService: GameGroupsService,
    private emitterService: EmitterService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private store: Store<AppState>
  ) {
    this.onGetGameGroupGames();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.getWindowType();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.isNoCCCardsAvialable = this.emitterService.getIsCCCardsAvailableStatus();

    this.theme = this.utilityService.setLocaleBasedTheme();

    this.subscriptions = [
      this.store.select(selectAuthLoginIsLoggedIn).subscribe((isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut),
      this.commonService.activeAccountViewSubject$.subscribe(
        (tabName: string) => {
          if (tabName === "menuOptions" && this.isGamesToasterOpen) {
            this.onCloseGamesToaster();
          }
        }
      ),
      this.emitterService.isNoCCCardsAvailableSubject$.subscribe(
        (isNoCCCardsAvialable: boolean) => {
          this.isNoCCCardsAvialable = isNoCCCardsAvialable;
        }
      ),
      this.utilityService.gameplayFooterHover$.subscribe(
        (isGamePlayFooterHover: boolean) => {
          this.isGamePlayFooterHover = isGamePlayFooterHover;
        }
      ),
      this.utilityService.isGamesToasterOpen$
        .pipe(takeWhile(() => true))
        .subscribe((isGamesToasterOpen: boolean) => {
          this.isGamesToasterOpen = isGamesToasterOpen;
        }),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["gameSpecificTag"] &&
      changes["gameSpecificTag"].previousValue !==
      changes["gameSpecificTag"].currentValue
    ) {
      this.gameSpecificTag = changes["gameSpecificTag"].currentValue;

      if (this.gameSpecificTag && this.gameSpecificTag.length > 0) {
        this.allAvaialbleTagsList = this.gameSpecificTag;

        this.onOpenGameTagOnPageLoad();
      } else {
        this.allAvaialbleTagsList = [];
      }
    }
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onResize(): void {
    this.getWindowType();
  }

  @HostListener("window:orientationchange") onRotate(): void {
    this.getWindowType();
  }

  @HostListener("document:click")
  @HostListener("window:mousemove")
  onRefreshUserState(): void {
    if (this.isGamesToasterOpen) {
      this.onCheckUserSessionActivity();
    }
  }

  @HostListener("touchstart") onStart(): void {
    if (this.isGamesToasterOpen) {
      this.onCheckUserSessionActivity();
    }
  }

  // -----------------------------------------------------------------
  // Get Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }

    if (window.matchMedia("(pointer: coarse)").matches) {
      this.isTouchScreen = true;
    } else {
      this.isTouchScreen = false;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onHoverBottomRibbon(): void {
    this.utilityService.gameplayFooterMouseOver(true);
  }

  onMouseLeaveBottomRibbon(): void {
    this.utilityService.gameplayFooterMouseOver(false);
  }

  onOpenGameTagOnPageLoad(): void {
    this.onFilterGamesByTagName(this.allAvaialbleTagsList[0]);

    setTimeout(() => {
      this.onCloseGamesToaster();
    }, 2000);
  }

  onGetGameGroupGames(): void {
    this.allAvaialbleTagsList = [];

    this.subscription = this.store
      .select(selectAllGames)
      .subscribe((allGamesList: GamePregmatic[]) => {
        if (allGamesList && allGamesList.length > 0) {
          this.allGamesList = allGamesList;
        } else {
          this.allGamesList = [];
        }
      });
  }

  onFilterGamesByTagName(tagName: string): void {
    this.onReinitializeSwiperLazyConfigurations();

    if (this.activeTag !== tagName) {
      this.activeTagGamesList = undefined;

      this.activeTag = tagName;

      this.utilityService.GamesToasterOpen(true);

      this.utilityService.gameplayFooterMouseOver(true);

      this.isLoading = true;

      if (this.activeTag === "last-played") {
        this.onGetLastPlayedGames();
      } else if (this.activeTag === "favourite") {
        this.onGetFavoriteGames();
      } else {
        this.activeTagGamesList = _.filter(
          this.allGamesList,
          (game: GamePregmatic) => {
            if (game && game.tagList.length > 0) {
              return game.tagList.indexOf(tagName) !== -1;
            }
          }
        );

        if (this.activeTagGamesList && this.activeTagGamesList.length > 0) {
          this.swiperLazyConfigurations.totalNumberOfGamesExist = this.activeTagGamesList.length;
        }

        this.isLoading = false;
      }
    } else {
      this.utilityService.GamesToasterOpen(!this.isGamesToasterOpen);

      this.activeTag = "";
    }
  }

  onGetLastPlayedGames(): void {
    this.subscriptionLastPlayedList = this.store
      .select(selectLastPlayedState)
      .subscribe((lastPlayedGames: number[]) => {
        if (lastPlayedGames && lastPlayedGames.length) {
          _.each(lastPlayedGames, (typeId: number) => {
            let game: GamePregmatic = _.filter(this.allGamesList, {
              beGameTypeId: typeId,
            })[0];

            if (game && this.activeTagGamesList) {
              this.activeTagGamesList.push(game);
            } else if (game) {
              this.activeTagGamesList = [];

              this.activeTagGamesList.push(game);
            }
          });

          this.isLoading = false;
        } else if (
          this.activeTagGamesList &&
          this.activeTagGamesList.length > 0
        ) {
          this.isLoading = false;
        } else {
          this.activeTagGamesList = [];

          this.isLoading = false;
        }

        if (this.activeTagGamesList && this.activeTagGamesList.length > 0) {
          this.swiperLazyConfigurations.totalNumberOfGamesExist = this.activeTagGamesList.length;
        }
      });
  }

  onGetFavoriteGames(): void {
    this.subscriptionFavourite = this.gameGroupsService
      .onGetFavoriteGamesList(true)
      .subscribe((favoriteGames: Favourite) => {
        if (
          favoriteGames &&
          favoriteGames.favorite &&
          favoriteGames.favorite.favoriteGamesList.length > 0
        ) {
          _.each(
            favoriteGames.favorite.favoriteGamesList,
            (favoriteGame: FavouriteGameList) => {
              let game: GamePregmatic = _.findWhere(this.allGamesList, {
                beGameTypeId: favoriteGame.gameTypeId,
              });

              if (game && this.activeTagGamesList) {
                this.activeTagGamesList.push(game);
              } else if (game) {
                this.activeTagGamesList = [];

                this.activeTagGamesList.push(game);
              }
            }
          );

          this.isLoading = false;
        } else {
          this.activeTagGamesList = [];

          this.isLoading = false;
        }

        if (this.activeTagGamesList && this.activeTagGamesList.length > 0) {
          this.swiperLazyConfigurations.totalNumberOfGamesExist = this.activeTagGamesList.length;
        }
      });
  }

  onCloseGamesToaster(): void {
    this.utilityService.GamesToasterOpen(false);

    this.activeTag = "";

    this.utilityService.gameplayFooterMouseOver(false);
  }

  onUsedCardsNotAvailable(): void {
    this.isNoCCCardsAvialable = true;

    this.componentRef.directiveRef.update();
  }

  onReinitializeSwiperLazyConfigurations(): void {
    this.swiperTagFilter = {
      slidesPerView: 14,
      slidesPerGroup: 14,
      freeMode: true,
      navigation: true,
      watchSlidesVisibility: true,
      observer: true,
      observeParents: true,
      resistanceRatio: 0,
      breakpoints: {
        // breakpoints works like max-width css
        2500: {
          slidesPerView: 17,
          slidesPerGroup: 17,
        },

        1920: {
          slidesPerView: 14,
          slidesPerGroup: 14,
        },

        1600: {
          slidesPerView: 12,
          slidesPerGroup: 12,
        },
        1599: {
          slidesPerView: 10,
          slidesPerGroup: 10,
        },
        1024: {
          slidesPerView: 8,
          slidesPerGroup: 8,
        },
      },
    };

    this.swiperLazyConfigurations = {
      totalNumberOfGamesExist: 0,
      indexNumberOfGameTilesToshow: 28,
      numberOfExtraGamesToLoadForSlide: 14,
      forwardslideIndex: 0,
      backwardslideIndex: 0,
    };

    if (this.directiveRef) {
      this.directiveRef.setIndex(0);
    }

    if (this.componentRef && this.componentRef.directiveRef) {
      this.componentRef.directiveRef.update();
    }
  }

  /*
    Function for Lazy loading games on click of Every silde..
    This works on bases of swiperLazyConfig defined above...
  */
  onSwiperNextEvent(): void {
    if (this.swiperLazyConfigurations.backwardslideIndex) {
      this.swiperLazyConfigurations.backwardslideIndex =
        this.swiperLazyConfigurations.backwardslideIndex - 1;
      return;
    }

    if (
      this.swiperLazyConfigurations.indexNumberOfGameTilesToshow <
      this.swiperLazyConfigurations.totalNumberOfGamesExist
    ) {
      this.swiperLazyConfigurations.indexNumberOfGameTilesToshow =
        this.swiperLazyConfigurations.indexNumberOfGameTilesToshow +
        this.swiperLazyConfigurations.numberOfExtraGamesToLoadForSlide;

      this.swiperLazyConfigurations.forwardslideIndex =
        this.swiperLazyConfigurations.forwardslideIndex + 1;
    }
  }

  onSwiperPrevEvent(): void {
    this.swiperLazyConfigurations.backwardslideIndex =
      this.swiperLazyConfigurations.backwardslideIndex + 1;
  }

  onCheckUserSessionActivity(): void {
    if (this.userActivityTimeout) {
      clearTimeout(this.userActivityTimeout);
    }

    if (this.isGamesToasterOpen) {
      this.userActivityTimeout = setTimeout(() => {
        this.onCloseGamesToaster();
      }, 5000);
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscriptionLastPlayedList)
      this.subscriptionLastPlayedList.unsubscribe();

    if (this.subscriptionFavourite) this.subscriptionFavourite.unsubscribe();

    if (this.subscription) this.subscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
