import { createAction, props } from "@ngrx/store";

// Enums
import { UserData } from "src/app/modules/user/models/user-data.model";

export enum UserActionTypes {
  // User
  UserRequested = "[User API] User Requested",
  UserRequestedRefresh = "[User API] User Requested Refresh",
  UserLoaded = "[User API] User Loaded",
  UserError = "[User API] User Error",
}

// User
export const userRequested = createAction(UserActionTypes.UserRequested);
export const userRequestedRefresh = createAction(
  UserActionTypes.UserRequestedRefresh
);
export const userLoaded = createAction(
  UserActionTypes.UserLoaded,
  props<{ userData: UserData }>()
);
export const userError = createAction(UserActionTypes.UserError);
