import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Libraries
import * as _ from "underscore";

// Models
import { CashierTransactionsRequest } from "src/app/modules/transactions/models/cashier-transactions-request.model";
import { TransactionHistoryResponse } from "src/app/modules/transactions/models/transaction-history-response.model";
import { WithdrawalDepositResponse } from "src/app/modules/transactions/models/withdrawal-deposit-response.model";
import { BetTransactionsResponse } from "src/app/modules/transactions/models/bet-transactions-response.model";
import { BetTransactionsRequest } from "src/app/modules/transactions/models/bet-transactions-request.model";
import { APIRequest } from "src/app/modules/shared/models/request.model";

@Injectable({
  providedIn: "root",
})
export class TransactionsService {
  // API Interactions
  apiInteractor: ApiInteractors;

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Transaction History
  onGetTransactionHistory(
    cashierTransactionsRequest: CashierTransactionsRequest
  ): Observable<TransactionHistoryResponse[]> {
    let request: APIRequest = {
      criteria: JSON.stringify(cashierTransactionsRequest),
    };

    return this.apiInteractor
      .post<APIRequest, TransactionHistoryResponse[]>(
        `/ajax/Report/getTransactionHistory`,
        request
      )
      .pipe(
        map((transactionHistoryResponse: TransactionHistoryResponse[]) => {
          return transactionHistoryResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetCashierTransactionHistory(
    cashierTransactionsRequest: CashierTransactionsRequest
  ): Observable<any> {
    let request: APIRequest = {
      criteria: JSON.stringify(cashierTransactionsRequest),
    };

    return this.apiInteractor
      .post<APIRequest, WithdrawalDepositResponse>(
        `/ajax/CashierTransactionReport/withdrawalDeposit`,
        request
      )
      .pipe(
        map((withdrawalDepositResponse: WithdrawalDepositResponse) => {
          return withdrawalDepositResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetBetTransactions(
    betTransactionsRequest: BetTransactionsRequest
  ): Observable<BetTransactionsResponse[]> {
    let request: APIRequest = {
      criteria: JSON.stringify(betTransactionsRequest),
    };

    return this.apiInteractor
      .post<APIRequest, BetTransactionsResponse[]>(
        `/ajax/Report/getTransactionHistory`,
        request
      )
      .pipe(
        map((betTransactionsResponse: BetTransactionsResponse[]) => {
          return betTransactionsResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
