import { FormGroup, FormBuilder } from "@angular/forms";
import { Component } from "@angular/core";

// Models
import { MiscDateOptions } from "src/app/modules/transactions/models/misc-date-options.model";

// Services
import { CustomValidatorService } from "src/app/modules/shared/services/custom-validator.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-shared-transaction",
  templateUrl: "./shared-transaction.component.html",
  styleUrls: ["./shared-transaction.component.scss"],
})
export class SharedTransactionComponent {
  // Numbers
  timeslap: number = 6;

  // Dates
  minDate: Date = new Date("1970-01-01");
  maxDate: Date = new Date();

  // Form Groups
  txnDetailsFrom: FormGroup;

  // Objects
  desktopStartDate: MiscDateOptions = {
    theme: "ios",
    cssClass: "whiteCalenderBg",
    select: "single",
    controls: ["calendar"],
    display: "bubble",
    buttons: false,
    onSet: () => {
      this.onDateChangeHandler(this.txnDetailsFrom, "startdate");
    },
  };
  mobileStartDate: MiscDateOptions = {
    theme: "ios",
    display: "bottom",
    onSet: () => {
      this.onDateChangeHandler(this.txnDetailsFrom, "startdate");
    },
  };
  desktopEndDate: MiscDateOptions = {
    theme: "ios",
    cssClass: "whiteCalenderBg",
    select: "single",
    controls: ["calendar"],
    display: "bubble",
    buttons: false,
    onSet: () => {
      this.onDateChangeHandler(this.txnDetailsFrom, "enddate");
    },
  };
  mobileEndDate: MiscDateOptions = {
    theme: "ios",
    display: "bottom",
    onSet: () => {
      this.onDateChangeHandler(this.txnDetailsFrom, "enddate");
    },
  };

  constructor(
    public customValidatorService: CustomValidatorService,
    public utilityService: UtilityService,
    public formBuilder: FormBuilder
  ) {
    this.txnDetailsFrom = this.formBuilder.group({
      startDate: ["", [this.customValidatorService.required]],
      endDate: ["", [this.customValidatorService.required]],
    });
  }

  // -----------------------------------------------------------------
  // Get Methods
  getDays(startDate: Date, endDate: Date): number {
    let startTime: number = new Date(startDate).getTime();

    let endTime: number = new Date(endDate).getTime();

    let difference: number = endTime - startTime;

    return Math.floor(difference / 1000 / 60 / 60 / 24);
  }

  // -----------------------------------------------------------------
  // Set Methods
  /* 
    Function called on event: Date change through mobi-scroll on mobile
  */
  onDateChangeHandler(form: FormGroup, target: string): void {
    this.onResetDate(form, target);
  }

  /*
   Function for resetting date: if user selects startdate then auto change the end date.

   If user selects the end date auto select the start date.

   This function is called by both device and mobile.
  */
  onResetDate(formGroup: FormGroup, target: string): void {
    let startDate: Date = new Date(formGroup.controls["startDate"].value);

    let endDate: Date = new Date(formGroup.controls["endDate"].value);

    endDate.setHours(23, 59, 59, 999);

    let daysDifference: number = this.getDays(startDate, endDate);

    if (daysDifference > this.timeslap || daysDifference < 0) {
      if (target === "startdate") {
        let incrementedTime: Date = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + this.timeslap
        );

        if (incrementedTime.getTime() < new Date().getTime()) {
          formGroup.get("endDate").setValue(incrementedTime);
        } else {
          formGroup.get("endDate").setValue(new Date());
        }
      } else if (target === "enddate") {
        let decrementTime: Date = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate() - this.timeslap
        );

        if (decrementTime.getTime() < new Date().getTime()) {
          formGroup.get("startDate").setValue(decrementTime);
        } else {
          formGroup.get("startDate").setValue(new Date());
        }
      }
    }
  }
}
