import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  Output,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Models
import { CampaignItem } from "src/app/modules/rewards/models/campaign-items.model";

// Services
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-unclaimed-free-spin-reward-card",
  templateUrl: "./unclaimed-free-spin-reward-card.component.html",
  styleUrls: ["./unclaimed-free-spin-reward-card.component.scss"],
})
export class UnclaimedFreeSpinRewardCardComponent implements OnChanges {
  // Outputs
  @Output() callOpenRewardTCPopUp: EventEmitter<MouseEvent> = new EventEmitter<
    MouseEvent
  >();

  // Inputs
  @Input() bonusDetails: CampaignItem;

  // Strings
  mediaUrlPath: string = environment.mediaUrlPath;
  imgixParams: string = "";
  bonusType: string = "";
  theme: string = "";

  constructor(
    private rewardsService: RewardsService,
    private utilityService: UtilityService
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.imgixParams = this.utilityService.getImgixParams();

    this.theme = this.utilityService.setLocaleBasedTheme();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["bonusDetails"] &&
      changes["bonusDetails"].previousValue !==
        changes["bonusDetails"].currentValue
    ) {
      this.bonusDetails = changes["bonusDetails"].currentValue;

      this.bonusType = this.bonusDetails.bonusTypeFD;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetClaimLevelUpBonus(levelId: number): void {
    this.rewardsService.onSetLevelUpDetails({
      newLevelId: levelId,
      dataFrom: "rewards",
    });

    this.utilityService.openAccountComponent("reward-congratulations");
  }

  onOpenRewardTCPopUp(event: MouseEvent): void {
    this.callOpenRewardTCPopUp.emit(event);
  }
}
