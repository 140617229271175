import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  withLatestFrom,
  catchError,
  switchMap,
  filter,
  map,
} from "rxjs/operators";

// Actions
import {
  userProfileBalanceRequested,
  userProfileBalanceLoaded,
  profileBalanceRequested,
  userProfileBalanceError,
  profileBalanceLoaded,
  profileBalanceError,
} from "src/app/modules/user/store/actions/profile.actions";

// Models
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import {
  selectAuthProfileBalanceLoaded,
  selectUserProfileBalanceLoaded,
} from "src/app/modules/user/store/selectors/profile.selectors";

// Services
import { ProfileService } from "src/app/modules/user/services/profile.service";

@Injectable()
export class ProfileEffects {
  constructor(
    private profileService: ProfileService,
    private store: Store<AppState>,
    private actions$: Actions
  ) {}

  profileBalanceRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(profileBalanceRequested),
      withLatestFrom(this.store.select(selectAuthProfileBalanceLoaded)),
      filter(([, { isLoaded }]) => !isLoaded),
      switchMap(() => {
        return this.profileService.onGetProfileBalanceCurrency().pipe(
          map((profileBalance: ProfileBalance) =>
            profileBalanceLoaded({ profileBalance })
          ),
          catchError(() => of(profileBalanceError()))
        );
      })
    )
  );

  userProfileBalanceRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProfileBalanceRequested),
      withLatestFrom(this.store.select(selectUserProfileBalanceLoaded)),
      filter(([, { isLoaded }]) => !isLoaded),
      switchMap(() => {
        return this.profileService.onGetUserBalanceByPockets().pipe(
          map((userProfileBalance: UserProfileBalance) =>
            userProfileBalanceLoaded({ userProfileBalance })
          ),
          catchError(() => of(userProfileBalanceError()))
        );
      })
    )
  );
}
