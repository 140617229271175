import { QueryParams } from "src/app/modules/shared/models/parameters.model";

export const getQueryParams = (): QueryParams => {
  if (window.location.search) {
    return JSON.parse(
      '{"' +
        decodeURI(
          window.location.search
            .substring(1)
            .replace(/&/g, '","')
            .replace(/=/g, '":"')
        ) +
        '"}'
    );
  } else {
    return {};
  }
};
