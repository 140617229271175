import { Component, ViewEncapsulation } from "@angular/core";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

@Component({
  selector: "app-form-radio",
  templateUrl: "./form-radio.component.html",
  styleUrls: ["./form-radio.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FormRadioComponent extends FormValidationComponent {
  constructor() {
    super();
  }
}
