import { FormBuilder, FormGroup } from "@angular/forms";
import { Component } from "@angular/core";
import { Subscription } from "rxjs";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Models
import { ChangePasswordResult } from "src/app/modules/auth/models/change-password-result.model";
import { ChangePassword } from "src/app/modules/auth/models/change-password.model";
import { StatusResponse } from "src/app/models/api/status.response";

// Services
import { CustomValidatorService } from "src/app/modules/shared/services/custom-validator.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { LoginAPIService } from "src/app/modules/auth/store/services/login-api.service";
import { FormService } from "src/app/modules/dynamic-form/services/form.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent extends FormValidationComponent {
  // Strings
  serverError: string = "";

  // Booleans
  isPasswordSuccessfullyUpdated: boolean = false;
  isConfirmPassword: boolean = true;
  isButtonLoader: boolean = false;
  isOldPassword: boolean = true;
  isPassword: boolean = true;

  // Form Groups
  changePasswordForm: FormGroup;

  // Subscriptions
  subscription: Subscription;

  constructor(
    private customValidators: CustomValidatorService,
    private translationService: TranslationService,
    private loginAPIService: LoginAPIService,
    private formService: FormService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group(
      {
        old: ["", [this.customValidators.required]],
        password: ["", [this.customValidators.validatePassword(8, 25)]],
        confirmPassword: ["", [this.customValidators.validatePassword(8, 25)]],
      },
      { validator: this.customValidators.passwordConfirming }
    );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onChangePassword(): void {
    this.isButtonLoader = true;

    if (this.changePasswordForm.valid) {
      let changePassword: ChangePassword = this.formService.transformFormToData<ChangePassword>(
        this.changePasswordForm,
        {}
      );

      this.subscription = this.loginAPIService
        .onChangePassword(changePassword)
        .subscribe((response: ChangePasswordResult) => {
          this.isButtonLoader = false;
          if (
            response &&
            response.success &&
            response.success.status === StatusResponse.SUCCESS
          ) {
            this.isPasswordSuccessfullyUpdated = true;

            this.formService.onResetFormFields(this.changePasswordForm);

            this.formService.onRemoveAllFormFieldsValidation(
              this.changePasswordForm
            );
          } else {
            this.isPasswordSuccessfullyUpdated = false;

            if (
              response &&
              response.errors &&
              response.errors.confirmPassword
            ) {
              this.serverError = response.errors.confirmPassword;
            } else if (response && response.errors && response.errors.message) {
              this.serverError = response.errors.message;
            } else {
              this.serverError = this.translationService.get(
                "change_password.something_wrong_pass"
              );
            }
          }
        });
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
