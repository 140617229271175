import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  OnDestroy,
  Output,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Models
import { CampaignItem } from "src/app/modules/rewards/models/campaign-items.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-free-spin-reward-card",
  templateUrl: "./free-spin-reward-card.component.html",
  styleUrls: ["./free-spin-reward-card.component.scss"],
})
export class FreeSpinRewardCardComponent implements OnChanges, OnDestroy {
  // Outputs
  @Output() callOpenRewardTCPopUp: EventEmitter<MouseEvent> = new EventEmitter<
    MouseEvent
  >();

  // Inputs
  @Input() bonusDetails: CampaignItem;
  @Input() callingFrom: string = "";

  // Strings
  mediaUrlPath: string = environment.mediaUrlPath;
  gamesUrlPath: string = environment.gamesUrlPath;
  currencySymbol: string = "";
  languageCode: string = "";
  imgixParams: string = "";
  bonusType: string = "";
  theme: string = "";

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private userDetailsService: UserDetailsService,
    private gamePlayService: GamePlayService,
    private utilityService: UtilityService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.imgixParams = this.utilityService.getImgixParams();

    this.theme = this.utilityService.setLocaleBasedTheme();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.theme = this.utilityService.setLocaleBasedTheme();
        }),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["bonusDetails"] &&
      changes["bonusDetails"].previousValue !==
        changes["bonusDetails"].currentValue
    ) {
      this.bonusDetails = changes["bonusDetails"].currentValue;

      this.bonusType = this.bonusDetails.bonusTypeFD;
    }

    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !==
        changes["callingFrom"].currentValue
    ) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLaunchGame(gameName: string): void {
    gameName = this.utilityService.convertGameNameToUrl(gameName);

    let currentGameSegmentList: string[] = this.utilityService
      .getDecodedCurrentPath()
      .split("/");

    if (currentGameSegmentList[currentGameSegmentList.length - 1] != gameName) {
      this.gamePlayService.onClearGameWindowData();
    }

    this.gamePlayService.onSetGameCalledFrom("realgame");

    let currentPath: string = this.utilityService.getDecodedCurrentPath();

    setTimeout(() => {
      if (
        currentPath.includes(
          `${this.translationService.get("url.game")}/${gameName}`
        )
      ) {
        this.gamePlayService.onBroadcastIsRelaunchGame(true);
      } else {
        this.router.navigate([
          `${this.languageCode}/${this.translationService.get(
            "url.game"
          )}/${gameName}`,
        ]);
      }

      this.utilityService.closeAccountComponent();
    });
  }

  onOpenRewardTCPopUp(event: MouseEvent): void {
    this.callOpenRewardTCPopUp.emit(event);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
