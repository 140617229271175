import { combineLatest, Observable, Subscription } from "rxjs";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";

// Actions
import { userRequested } from "src/app/modules/user/store/actions/user.actions";
import {
  userProfileBalanceRequested,
  profileBalanceRequested,
} from "src/app/modules/user/store/actions/profile.actions";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { StatusResponse } from "src/app/models/api/status.response";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import * as _ from "underscore";

// Models
import { CancelTransactionResponse } from "src/app/modules/transactions/models/cancel-transaction/cancel-transaction-response.model";
import { CancelTransactionRequest } from "src/app/modules/transactions/models/cancel-transaction/cancel-transaction-request.model";
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";
import { UserBalanceDetails } from "src/app/modules/shared/models/profiles/user-balance-details.model";
import {
  PendingWithdrawalsRequest,
  TransactionMethodTypes,
} from "src/app/modules/transactions/models/pending-withdrawals/pending-withdrawals-request.model";
import {
  PendingWithdrawalsResponse,
  Transactions,
} from "src/app/modules/transactions/models/pending-withdrawals/pending-withdrawals-response.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";
import { PiqDetails } from "src/app/modules/account/models/piq-details.model";
import { UserData } from "src/app/modules/user/models/user-data.model";
import { TokenResponse } from "src/app/models/games/token.response";
import { TokenRequest } from "src/app/models/games/token.request";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import {
  selectAuthProfileBalance,
  selectUserProfileBalance,
} from "src/app/modules/user/store/selectors/profile.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { PaymentService } from "src/app/modules/transactions/services/payment.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-pending-withdraw",
  templateUrl: "./pending-withdraw.component.html",
  styleUrls: ["./pending-withdraw.component.scss"],
})
export class PendingWithdrawComponent implements OnInit, OnDestroy {
  // Strings
  currencySymbol: string = "";
  theme: string = "";

  // Booleans
  isPendingWithdrawConfirmationPopup = false;
  isLoggedIn = false;
  isLoading = false;

  // Enums
  currencyCode: WorldCurrencyCode;

  // Arrays
  transactionsList: Transactions[] = [];

  // Objects
  userBalanceDetails: UserBalanceDetails;
  selectedTransaction: Transactions;
  piqDetails: PiqDetails = {};
  profileDetails: UserData;

  // Swiper Configurations
  swiperPendingWithdraw: SwiperConfigInterface = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 8,
    observer: true,
    observeParents: true,
    watchOverflow: true,
    resistanceRatio: 0,
    pagination: {
      el: ".swipper-pagination",
      clickable: true,
    },
  };

  // Subscriptions
  pendingWithdrawalsSubscription: Subscription;
  cancelTransactionSubscription: Subscription;
  combineLatestSubscription: Subscription;
  onGetTokenSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private userDetailsService: UserDetailsService,
    private currencyFormatPipe: CurrencyFormatPipe,
    private paymentService: PaymentService,
    private cashierService: CashierService,
    private utilityService: UtilityService,
    private store: Store<AppState>
  ) { }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.store.dispatch(userProfileBalanceRequested());

    this.store.dispatch(profileBalanceRequested());

    this.userBalanceDetails = this.userDetailsService.getUserBalanceDetails();

    this.theme = this.utilityService.setLocaleBasedTheme();

    this.subscriptions = [
      this.store.select(selectAuthLoginIsLoggedIn).subscribe((isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.userDetailsService.currencyCodeBehaviourSubject$.subscribe(
        (currencyCode: WorldCurrencyCode) => {
          this.currencyCode = currencyCode;
        }
      ),
      this.store
        .select(selectAuthUserDataLoaded)
        .subscribe(({ userData, isLoaded }) => {
          if (isLoaded) {
            this.profileDetails = userData;

            this.onRequestToken(this.profileDetails);
          } else {
            if (this.isLoggedIn) {
              this.store.dispatch(userRequested());
            }
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Get Methods
  getAmountCurrencyFormat(value: string): string {
    if (value && this.currencyCode) {
      let regex: RegExp = new RegExp(`-|${this.currencyCode}| `, "gi");

      return this.currencyFormatPipe.transform(
        value.replace(regex, ""),
        this.currencySymbol,
        false
      );
    } else {
      return value;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onRequestToken(profileDetails: UserData): void {
    if (!_.isEmpty(profileDetails) && !_.isEmpty(this.userBalanceDetails)) {
      this.piqDetails.userId = profileDetails.playerID;

      this.onGetCashierToken();
    } else {
      let tokenRequest: TokenRequest = {
        token: "cashier",
      };

      const tokenObservable: Observable<TokenResponse> = this.cashierService.onGetToken(
        tokenRequest
      );

      const profileBalanceObservable: Observable<ProfileBalance> = this.store.select(
        selectAuthProfileBalance
      );

      const userProfileBalanceObservable: Observable<UserProfileBalance> = this.store.select(
        selectUserProfileBalance
      );

      this.combineLatestSubscription = combineLatest([
        tokenObservable,
        profileBalanceObservable,
        userProfileBalanceObservable,
      ]).subscribe(
        ([
          tokenResponse,
          profileBalanceResponse,
          userProfileBalanceResponse,
        ]) => {
          const profileBalance: ProfileBalance = profileBalanceResponse;

          const token: TokenResponse = tokenResponse;

          const userProfileBalance: UserProfileBalance = userProfileBalanceResponse;

          if (
            profileBalance &&
            profileBalance.profile &&
            profileBalance.profile.playerID &&
            userProfileBalance
          ) {
            this.userBalanceDetails = this.userDetailsService.getUserBalanceDetails();

            this.piqDetails.userId = profileBalance.profile.playerID;

            this.onParseToken(token);
          }
        }
      );
    }
  }

  onGetCashierToken(): void {
    this.isLoading = true;

    let tokenRequest: TokenRequest = {
      token: "cashier",
    };

    this.onGetTokenSubscription = this.cashierService
      .onGetToken(tokenRequest)
      .subscribe((tokenResponse: TokenResponse) => {
        if (
          tokenResponse &&
          tokenResponse.status === StatusResponse.SUCCESS &&
          tokenResponse.token &&
          tokenResponse.token !== ""
        ) {
          this.onParseToken(tokenResponse);

          this.piqDetails.sessionId = tokenResponse.token;
        }
      });
  }

  onParseToken(tokenResponse: TokenResponse): void {
    if (
      tokenResponse.status === StatusResponse.SUCCESS &&
      tokenResponse.token &&
      tokenResponse.token !== ""
    ) {
      this.piqDetails.sessionId = tokenResponse.token;

      this.piqDetails.merchantId = environment.paymentIqMID;

      this.onGetPendingWithdrawals();
    } else {
      this.transactionsList = [];

      this.isLoading = false;
    }
  }

  onGetPendingWithdrawals(): void {
    let { userId, merchantId, sessionId } = this.piqDetails;

    let pendingWithdrawalsRequest: PendingWithdrawalsRequest = {
      merchantId,
      userId,
      sessionId,
      method: TransactionMethodTypes.Withdrawal,
      states: "WAITING_APPROVAL",
    };

    this.pendingWithdrawalsSubscription = this.paymentService
      .onPendingWithdrawals(pendingWithdrawalsRequest)
      .subscribe(
        (pendingWithdrawalsResponse: PendingWithdrawalsResponse) => {
          this.isLoading = false;
          if (
            pendingWithdrawalsResponse &&
            pendingWithdrawalsResponse.success &&
            pendingWithdrawalsResponse.transactions
          ) {
            this.transactionsList = pendingWithdrawalsResponse.transactions;
          } else {
            this.transactionsList = [];
          }
        },
        () => {
          this.transactionsList = [];

          this.isLoading = false;
        }
      );
  }

  onCancelTransaction(transactionId: string): void {
    this.isLoading = true;

    let { userId, merchantId, sessionId } = this.piqDetails;

    let cancelTransactionRequest: CancelTransactionRequest = {
      userId,
      merchantId,
      sessionId,
      transactionId,
    };

    this.onCloseConfirmationPopup();

    this.cancelTransactionSubscription = this.paymentService
      .onCancelTransaction(cancelTransactionRequest)
      .subscribe(
        (cancelTransactionResponse: CancelTransactionResponse) => {
          if (cancelTransactionResponse && cancelTransactionResponse.success) {
            this.onGetPendingWithdrawals();
          } else {
            this.isLoading = false;
          }
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  onCloseConfirmationPopup(): void {
    this.isPendingWithdrawConfirmationPopup = false;
  }

  onOpenConfirmationPopup(transaction: Transactions): void {
    transaction = { ...transaction };

    if (transaction && transaction.amount) {
      transaction.amount = this.getAmountCurrencyFormat(transaction.amount);
    }

    this.selectedTransaction = transaction;

    this.isPendingWithdrawConfirmationPopup = true;
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.pendingWithdrawalsSubscription)
      this.pendingWithdrawalsSubscription.unsubscribe();

    if (this.cancelTransactionSubscription)
      this.cancelTransactionSubscription.unsubscribe();

    if (this.combineLatestSubscription)
      this.combineLatestSubscription.unsubscribe();

    if (this.onGetTokenSubscription) this.onGetTokenSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
