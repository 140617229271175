import { Component, Output, Input, EventEmitter, SimpleChange, OnChanges, ViewChild, ElementRef } from "@angular/core";

// Configurations
import { incomeSourceListConfigurations, maxSupportedFileSizeInBytesConfigurations } from "src/app/configurations/main.configurations";

// Libraries
import * as _ from "underscore";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

@Component({
  selector: "app-source-of-fund",
  templateUrl: "./source-of-fund.component.html",
  styleUrls: ["./source-of-fund.component.scss"],
})
export class SourceOfFundComponent implements OnChanges {
  @ViewChild("sourceOfFund", { static: false }) sourceOfFund: ElementRef;
  @Output() uploadProofOfPaymentFiles: EventEmitter<any> = new EventEmitter();
  @Input() uploadResponseData;
  @Input() isButtonDisabled: boolean;
  sourceOfFundData;
  sizeExceeded: string;
  incomeSelected: string = "";

  sourceOfIncomeList;
  selectedSouceOfIncome: string;
  constructor(private translationService: TranslationService, private commonService: CommonService) {
    this.sourceOfIncomeList = incomeSourceListConfigurations;
    this.selectedSouceOfIncome = this.sourceOfIncomeList[0];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["uploadResponseData"]) {
      this.uploadResponseData = changes["uploadResponseData"].currentValue;
      setTimeout(() => {
        this.uploadResponseData = undefined;
      }, 5000);
      this.clearField();
    } else if (changes["isButtonDisabled"]) {
      this.isButtonDisabled = changes["isButtonDisabled"].currentValue;
    }
  }

  incomeChangeHanlder(event) {
    let data = undefined;
    data = _.findWhere(this.sourceOfIncomeList, { value: event.target.value });
    if (data) {
      this.selectedSouceOfIncome = data;
    }
  }

  upload(event) {
    this.sizeExceeded = undefined;
    if (event.target.files[0].size >= maxSupportedFileSizeInBytesConfigurations) {
      this.sizeExceeded = this.translationService.get("confirmidentity.file_size_alert");
      this.clearField();
    } else {
      let sourceOfIncomeobj = {};
      sourceOfIncomeobj[this.selectedSouceOfIncome["value"]] = true;
      this.sourceOfFundData = {
        event: event,
        fileName: event.target.files[0].name,
        type: this.selectedSouceOfIncome["value"],
        sourceOfIncome: JSON.stringify(sourceOfIncomeobj),
      };
    }
  }

  submitSourceOfPayment() {
    if (this.sourceOfFundData["event"] && this.sourceOfFundData["type"]) {
      /**what even value user select from drop down, From front end we are passing value as 'others' for time been as
       * functionality for this all types is pending from back end..
       * Once backend functionality is done we will passing correct values.
       */
      this.sourceOfFundData["type"] = "others";
      this.sourceOfFundData["sourceOfIncome"] = JSON.stringify({ others: true });
      /**end*** */
      this.uploadProofOfPaymentFiles.emit(this.sourceOfFundData);
    }
  }
  clearField(callingFrom?: string) {
    this.sourceOfFundData = undefined;
    if (this.sourceOfFund) {
      this.sourceOfFund.nativeElement.value = "";
    }
    if (callingFrom !== "input-elm") {
      this.selectedSouceOfIncome = this.sourceOfIncomeList[0];
    }
    this.incomeSelected = this.selectedSouceOfIncome["value"];
    if (this.sizeExceeded) {
      setTimeout(() => {
        this.sizeExceeded = undefined;
      }, 5000);
    }
  }
}
