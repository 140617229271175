// Libraries
import * as _ from "underscore";

// Models
import { FavouriteGameList } from "src/app/modules/game-groups/models/favourite/favourite-game-list.model";
import { LobbyGameGroup } from "src/app/modules/game-groups/models/lobby/lobby-game-group.model";
import { GameGroup } from "src/app/modules/game-groups/models/game-group.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

export const processGameGroup = (
  lobbyGameGroupList: LobbyGameGroup[],
  games: GamePregmatic[],
  lastPlayedGames: number[] = [],
  allFavoriteGames: FavouriteGameList[] = [],
  isLoggedIn: boolean = false
) => {
  if (lobbyGameGroupList && games) {
    _.each(
      lobbyGameGroupList,
      (lobbyGameGroup: LobbyGameGroup, indexLobbyGameGroup: number) => {
        if (lobbyGameGroup.group_type === "manual") {
          _.each(games, (game: GamePregmatic) => {
            let gameGroupList: GameGroup[] = game.gameGroupList as GameGroup[];

            if (game.gameGroupList && gameGroupList.length > 0) {
              _.each(
                gameGroupList,
                (gameGroup: GameGroup, indexGameGroup: number) => {
                  if (gameGroup.id === lobbyGameGroup.id) {
                    let clonedGame: GamePregmatic = _.clone(game);

                    clonedGame.gameGroupList =
                      game.gameGroupList[indexGameGroup];

                    if (!lobbyGameGroupList[indexLobbyGameGroup].games) {
                      lobbyGameGroupList[indexLobbyGameGroup].games = [];
                    }

                    lobbyGameGroupList[indexLobbyGameGroup].games.push(
                      clonedGame
                    );
                  }
                }
              );
            }
          });
        } else if (
          lobbyGameGroup.group_type === "automatic" &&
          lobbyGameGroup.group_sub_type === "continue_playing"
        ) {
          if (isLoggedIn && lastPlayedGames && lastPlayedGames.length > 0) {
            _.each(lastPlayedGames, (typeId: number) => {
              let game: GamePregmatic = _.findWhere(games, {
                beGameTypeId: typeId,
              });

              if (!lobbyGameGroupList[indexLobbyGameGroup].games) {
                lobbyGameGroupList[indexLobbyGameGroup].games = [];
              }

              if (game) {
                lobbyGameGroupList[indexLobbyGameGroup].games.push(game);
              }
            });
          } else {
            lobbyGameGroupList[indexLobbyGameGroup].games = [];
          }
        } else if (
          lobbyGameGroup.group_type === "automatic" &&
          lobbyGameGroup.group_sub_type === "favourite"
        ) {
          if (isLoggedIn && allFavoriteGames && allFavoriteGames.length > 0) {
            _.each(allFavoriteGames, (favoriteGame: FavouriteGameList) => {
              let game: GamePregmatic = _.findWhere(games, {
                beGameTypeId: favoriteGame.gameTypeId,
              });

              if (!lobbyGameGroupList[indexLobbyGameGroup].games) {
                lobbyGameGroupList[indexLobbyGameGroup].games = [];
              }

              if (game) {
                lobbyGameGroupList[indexLobbyGameGroup].games.push(game);
              }
            });
          } else {
            lobbyGameGroupList[indexLobbyGameGroup].games = [];
          }
        }
      }
    );
  }

  return lobbyGameGroupList;
};
