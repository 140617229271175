import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  SimpleChange,
  EventEmitter,
  Component,
  OnChanges,
  OnDestroy,
  Output,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { TriggerLoginGameDetails } from "src/app/modules/shared/models/trigger-login/trigger-login-game-details.model";
import { ImageEndTagKeyValue } from "src/app/modules/game-groups/models/image-end-tag-key-value.model";
import { JackpotKeyValue } from "src/app/modules/game-groups/models/jackpot/jackpot-key-value.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { JackpotService } from "src/app/modules/game-groups/services/jackpot.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

@Component({
  selector: "app-game-card",
  templateUrl: "./game-card.component.html",
  styleUrls: ["./game-card.component.scss"],
})
export class GameCardComponent implements OnChanges, OnDestroy {
  // Inputs
  @Input() game_asset_style: string = "";
  @Input() isJackpot: boolean = false;
  @Input() gameGroupName: string = "";
  @Input() activeTagName: string = "";
  @Input() callingFrom: string = "";
  @Input() lobbyName: string = "";

  @Input() gameData: GamePregmatic;

  // Outputs
  @Output() callBackAction: EventEmitter<void> = new EventEmitter<void>();

  // Numbers
  gameLauncherTimeout: number = 0;
  timeout: number = 0;

  // Strings
  gamesUrlPath: string = environment.gamesUrlPath;
  currencySymbol: string = "";
  imageStypeTag: string = "";
  languageCode: string = "";
  imgixParams: string = "";

  // Booleans
  isLoggedIn: boolean = false;

  // Others
  /*
   Below all are the basic assest type's which decide How the game card
   should be displayed (mostly in terms of size) & image size both
  
   Basically use this constant values to attach at end of the game image url
   to render proper image from CMS
  */
  imageEndTagKeyValue: ImageEndTagKeyValue = {
    "default-live-casino": "_default_live_casino",
    "poster-live-casino": "_poster",
    "poster-jackpot": "_poster",
    "default-casino": "_casino",
    poster: "_poster",
  };
  jackpotKeyValue: JackpotKeyValue = {};

  // Subscriptions
  mircoGamingJackpotDataSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private userDetailsService: UserDetailsService,
    private gamePlayService: GamePlayService,
    private mixPanelService: MixPanelService,
    private jackpotService: JackpotService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private router: Router
  ) { }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.imgixParams = this.utilityService.getImgixParams();

    this.subscriptions = [
      this.store.select(selectAuthLoginIsLoggedIn).subscribe((isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
    ];

    if (this.isJackpot) {
      this.mircoGamingJackpotDataSubscription = this.jackpotService.mircoGamingJackpotDataBehavourSubject$.subscribe(
        (jackpotKeyValue: { [key: string]: number }) => {
          this.jackpotKeyValue = jackpotKeyValue;
        }
      );
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["gameData"] &&
      changes["gameData"].previousValue !== changes["gameData"].currentValue
    ) {
      this.gameData = changes["gameData"].currentValue;
    }

    if (
      changes["isJackpot"] &&
      changes["isJackpot"].previousValue !== changes["isJackpot"].currentValue
    ) {
      this.isJackpot = changes["isJackpot"].currentValue;
    }

    if (
      changes["game_asset_style"] &&
      changes["game_asset_style"].previousValue !==
      changes["game_asset_style"].currentValue
    ) {
      let styleTag: string = changes["game_asset_style"].currentValue;

      this.imageStypeTag = this.imageEndTagKeyValue[styleTag];
    }

    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !==
      changes["callingFrom"].currentValue
    ) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }

    if (
      changes["gameGroupName"] &&
      changes["gameGroupName"].previousValue !==
      changes["gameGroupName"].currentValue
    ) {
      this.gameGroupName = changes["gameGroupName"].currentValue;
    }

    if (
      changes["lobbyName"] &&
      changes["lobbyName"].previousValue !== changes["lobbyName"].currentValue
    ) {
      this.lobbyName = changes["lobbyName"].currentValue;
    }

    if (
      changes["activeTagName"] &&
      changes["activeTagName"].previousValue !==
      changes["activeTagName"].currentValue
    ) {
      this.activeTagName = changes["activeTagName"].currentValue;
    }
  }

  ngAfterViewInit(): void {
    window["prerenderReady"] = true;
  }

  // -----------------------------------------------------------------
  // Get Methods
  getIsRealGame(gametype: string): boolean {
    return gametype === "realgame" && this.isLoggedIn;
  }

  getIsDemoGame(gametype: string, hasDemo: boolean): boolean {
    return gametype === "freegame" && hasDemo;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGameLauncher(
    event: Event,
    gameName: string,
    hasDemo: boolean,
    gametype: string
  ): void {
    if (event) {
      event.stopPropagation();
    }

    if (environment.features.liveCasino.livespins) {
      if (gameName == "Bet Behind") {
        // setTimeout(() => {
        if (
          gameName &&
          (this.getIsRealGame(gametype) ||
            this.getIsDemoGame(gametype, hasDemo))
        ) {
          this.mixPanelService.onTrackGameLaunchEvent({
            gameData: this.gameData,
            gameType: gametype,
            groupName: this.gameGroupName,
            lobbyName: this.lobbyName,
            callingFrom: this.callingFrom,
            activeTag: this.activeTagName,
          });

          this.router.navigate([`${this.languageCode}/livespins`]);
        } else if (gameName) {
          this.onTriggerLogin(gameName);
        }
        // });

        return;
      }
    }

    gameName = this.utilityService.convertGameNameToUrl(gameName);

    let currentGameSlug: string[] = this.utilityService
      .getDecodedCurrentPath()
      .split("/");

    if (currentGameSlug[currentGameSlug.length - 1] != gameName) {
      this.gamePlayService.onClearGameWindowData();

      if (gametype === "freegame") {
        event.stopPropagation();
      }

      this.gamePlayService.onSetGameCalledFrom(gametype);

      // setTimeout(() => {
      if (
        gameName &&
        (this.getIsRealGame(gametype) ||
          this.getIsDemoGame(gametype, hasDemo))
      ) {
        this.mixPanelService.onTrackGameLaunchEvent({
          gameData: this.gameData,
          gameType: gametype,
          groupName: this.gameGroupName,
          lobbyName: this.lobbyName,
          callingFrom: this.callingFrom,
          activeTag: this.activeTagName,
        });

        this.router.navigate([
          `${this.languageCode}/${this.translationService.get(
            "url.game"
          )}/${gameName}`,
        ]);
      } else if (gameName) {
        this.onTriggerLogin(gameName);
      }
      // });
    } else if (
      currentGameSlug[currentGameSlug.length - 1] === gameName &&
      gametype != this.gamePlayService.getGameCalledFrom()
    ) {
      if (gametype === "freegame") {
        event.stopPropagation();
      }

      // user to set value like realgame or freegame/demogame
      this.gamePlayService.onSetGameCalledFrom(gametype);

      /*
        Below Event is used to reload or switch the game from demo to real & viva versa
        we need this event because when user is in game play window &
        playing 7 piggies(demo game) & what to play realgame by click on top btn or from tag managment
        angular will not understand to reload route as URL in both cases will be same
        so we use this event method to force relaod game window iframe
      */
      this.gamePlayService.onBroadcastIsRelaunchGame(true);
    }

    /*
      Below code help you to close the search window after user
      launch any game from search area.
    */
    if (
      this.callingFrom === "recent-search" ||
      this.callingFrom === "game-window-tag"
    ) {
      this.callBackAction.emit();
    }
  }

  onTriggerLogin(gameName: string): void {
    let gameDetails: TriggerLoginGameDetails = {
      navigateTo: gameName == "Bet Behind" ? "livespins" : "gameWindow",
      path: gameName,
    };

    if (this.gameGroupName) {
      gameDetails.gameGroupName = this.gameGroupName;
    }

    if (this.lobbyName) {
      gameDetails.lobbyName = this.lobbyName;
    }

    this.commonService.onSetNavigateAfterLogin({
      type: "url",
      data: gameDetails,
    });

    this.commonService.onBroadcastActiveAcountView("login");
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.mircoGamingJackpotDataSubscription)
      this.mircoGamingJackpotDataSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );

    if (this.gameLauncherTimeout) clearTimeout(this.gameLauncherTimeout);

    if (this.timeout) clearTimeout(this.timeout);
  }
}
