import { catchError, map, withLatestFrom, filter, exhaustMap } from "rxjs/operators";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { of } from "rxjs";

// Actions
import {
  bannerRequested,
  bannerLoaded,
  bannerError,
} from "src/app/modules/banner/store/actions/banners.actions";

// Models
import { Banner } from "src/app/modules/banner/models/banner.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { getBannerList } from "src/app/modules/banner/store/selectors/banners.selectors";

// Services
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";

@Injectable()
export class BannersEffects {
  constructor(
    private customBannerService: CustomBannerService,
    private store: Store<AppState>,
    private actions$: Actions
  ) {}

  bannerRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bannerRequested),
      withLatestFrom(this.store.select(getBannerList)),
      map(([{ bannerRequest }, bannersList]) => {
        return {bannerRequest, bannersList};
      }),
      filter(({bannerRequest, bannersList}) => !bannersList[bannerRequest.zoneId]),
      exhaustMap(({bannerRequest}) => {
        return this.customBannerService.onBannerList(bannerRequest, false).pipe(
          map((banners: Banner[]) => bannerLoaded({ bannerRequest, banners })),
          catchError(() => of(bannerError()))
        );
      })
    )
  );
}
