import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent {
  // Outputs
  @Output() closeNotifications: EventEmitter<void> = new EventEmitter<void>();

  // Booleans
  isShowNotification: boolean = true;
  isLoading: boolean = false;

  constructor() {}

  // -----------------------------------------------------------------
  // Set Methods
  onCloseNotificationsHandler(event: void): void {
    this.closeNotifications.emit(event);
  }
}
