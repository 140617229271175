import { map, catchError, tap, share } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  BehaviorSubject,
  Subscription,
  Observable,
  throwError,
  Subject,
  of,
} from "rxjs";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Libraries
import * as _ from "underscore";

// Models
import { ProviderLevelGamesList } from "src/app/modules/game-groups/models/provider-level-games-list.model";
import { FavouriteGameList } from "src/app/modules/game-groups/models/favourite/favourite-game-list.model";
import { LastPlayedGames } from "src/app/modules/game-groups/models/games/last-played-games.model";
import { LobbyGameGroup } from "src/app/modules/game-groups/models/lobby/lobby-game-group.model";
import { FreeGames } from "src/app/modules/game-groups/models/free-games/free-games.model";
import { Favourite } from "src/app/modules/game-groups/models/favourite/favourite.model";
import { LobbyPregmatic } from "src/app/modules/game-groups/models/lobby/lobby.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import { StatusResponse } from "src/app/models/api/status.response";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Utilities
import { processGameGroup } from "src/app/modules/game-groups/utilities/process-game-group.utilities";

@Injectable({
  providedIn: "root",
})
export class GameGroupsService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Booleans
  isLoggedIn: boolean = false;

  // Arrays of Objects
  lobbyWithGameGroupsList: LobbyPregmatic[] = [];
  lastPlayedGamesList: number[] = [];
  gameList: GamePregmatic[] = [];

  // Others
  providerLevelGamesList: ProviderLevelGamesList = {};
  favouriteGameList: Favourite;

  // --------------------------------------------------------
  // Subject - Is Play Again Clicked
  private isPlayAgainClickedSubject: Subject<boolean> = new Subject<boolean>();
  public isPlayAgainClickedSubject$: Observable<
    boolean
  > = this.isPlayAgainClickedSubject.asObservable();

  // --------------------------------------------------------
  // Behaviour Subject - Is Live Games Last Played Available
  private isLiveGamesLastPlayedAvailableBehaviorSubject: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);
  public isLiveGamesLastPlayedAvailableBehaviorSubject$: Observable<
    boolean
  > = this.isLiveGamesLastPlayedAvailableBehaviorSubject.asObservable();

  // --------------------------------------------------------
  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private httpClient: HttpClient,
    private store: Store<AppState>
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);

    this.subscriptions = [
      this.store.select(selectAuthLoginIsLoggedIn).subscribe((isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut),
    ];
  }

  // -----------------------------------------------------------------
  // Get Methods
  /*****************************************Get Game group by lobby*********************************************** */
  /*
    Below Function Iternates through gameGroups vs games Data & filter each and every gameGroup related
    games & push into it related game group object
  
    So when we run this below code final output will be all the game groupes and it's related games as
    array with the group object will be returned.
  */
  getProcessGameGroupGames(
    lobbyGameGroupList: LobbyGameGroup[],
    games: GamePregmatic[],
    lastPlayedGames: number[] = [],
    allFavoriteGames: FavouriteGameList[] = []
  ): LobbyGameGroup[] {
    return processGameGroup(
      lobbyGameGroupList,
      games,
      lastPlayedGames,
      allFavoriteGames,
      this.isLoggedIn
    );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables - Get All Games List
  onGetGamePregmaticList(isForce?: boolean): Observable<GamePregmatic[]> {
    if (!_.isEmpty(this.gameList) || isForce) {
      return of(this.gameList);
    } else {
      return this.apiInteractor
        .get<null, GamePregmatic[]>(`/ajax/game/getLobbyGameGroupGames`)
        .pipe(
          tap((gameList: GamePregmatic[]) => {
            if (gameList && gameList.length > 0) {
              this.gameList = gameList;
            }
          }),
          map((gameList: GamePregmatic[]) => {
            return gameList;
          }),
          catchError((error) => {
            return throwError(error);
          }),
          share()
        );
    }
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables - Get Favourite Games List
  /*
    Mostly after we call toggleFavorite api call...we need to pass true flag
    to get updated data from backoffice...
  */
  onGetFavoriteGamesList(isForce: boolean = false): Observable<Favourite> {
    if (!_.isEmpty(this.favouriteGameList) && !isForce) {
      return of(this.favouriteGameList);
    } else {
      return this.apiInteractor
        .post<null, Favourite>(`/ajax/profile/getFavouriteGames`)
        .pipe(
          tap((favouriteGameList: Favourite) => {
            if (
              favouriteGameList.favorite &&
              favouriteGameList.favorite.favoriteGamesList.length > 0
            ) {
              this.favouriteGameList = favouriteGameList;
            }
          }),
          map((favouriteGameList: Favourite) => {
            return favouriteGameList;
          }),
          catchError((error) => {
            return throwError(error);
          }),
          share()
        );
    }
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables - Get Last Played Games List
  onGetLastedPlayedGamesList(isForce: boolean = false): Observable<number[]> {
    if (isForce) {
      this.lastPlayedGamesList = undefined;
    }

    if (!_.isEmpty(this.lastPlayedGamesList) && !isForce) {
      return of(this.lastPlayedGamesList);
    } else {
      return this.apiInteractor
        .get<null, LastPlayedGames>(`/ajax/profile/getLastPlayedGames`)
        .pipe(
          tap((lastPlayedGamesList: LastPlayedGames) => {
            if (
              lastPlayedGamesList &&
              lastPlayedGamesList.status === StatusResponse.SUCCESS &&
              lastPlayedGamesList.gameDetails.length > 0
            ) {
              this.lastPlayedGamesList = lastPlayedGamesList.gameDetails;
            } else {
              this.lastPlayedGamesList = [];
            }
          }),
          map((lastPlayedGamesList: LastPlayedGames) => {
            if (
              lastPlayedGamesList &&
              lastPlayedGamesList.status === StatusResponse.SUCCESS &&
              lastPlayedGamesList.gameDetails.length > 0
            ) {
              return lastPlayedGamesList.gameDetails;
            } else {
              return [];
            }
          }),
          catchError((error) => {
            return throwError(error);
          }),
          share()
        );
    }
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables - Get Last Played Games List
  onGetPromiseLobbyPregmaticList(
    isForce: boolean = false
  ): Promise<LobbyPregmatic[]> {
    return new Promise((resolve, reject) => {
      if (!_.isEmpty(this.lobbyWithGameGroupsList) && !isForce) {
        resolve(this.lobbyWithGameGroupsList);
      } else {
        Promise.resolve(this.onGetLobbyPregmaticList().toPromise()).then(
          (response: LobbyPregmatic[]) => {
            if (response && response.length > 0) {
              this.lobbyWithGameGroupsList = response;

              resolve(this.lobbyWithGameGroupsList);
            }
          }
        );
      }
    });
  }

  onGetLobbyPregmaticList(
    isForce: boolean = false
  ): Observable<LobbyPregmatic[]> {
    if (!_.isEmpty(this.lobbyWithGameGroupsList) && !isForce) {
      return of(this.lobbyWithGameGroupsList);
    } else {
      return this.apiInteractor
        .get<null, LobbyPregmatic[]>(`/ajax/game/getLobbyListWithGameGroups`)
        .pipe(
          tap((response: LobbyPregmatic[]) => {
            this.lobbyWithGameGroupsList = response;
          }),
          map((response: LobbyPregmatic[]) => {
            return response;
          }),
          catchError((error) => {
            return throwError(error);
          }),
          share()
        );
    }
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables - Get Free Game
  onGetFreeGame(gameId: string): Observable<FreeGames> {
    return this.apiInteractor
      .get<{ gameSymbol: string }, FreeGames>(`/ajax/launcher/getFreeGames`, {
        gameSymbol: gameId,
      })
      .pipe(
        map((freeGames: FreeGames) => {
          return freeGames;
        }),
        catchError((error) => {
          return throwError(error);
        }),
        share()
      );
  }

  /*
    This Methods Give each provider level Live , Non-Live & All games List
  */
  onGetPromiseProviderLevelGamesList(): Promise<ProviderLevelGamesList> {
    return new Promise((resolve, reject) => {
      if (
        this.providerLevelGamesList &&
        Object.keys(this.providerLevelGamesList).length > 0
      ) {
        resolve(this.providerLevelGamesList);
      } else {
        Promise.resolve(this.onGetGamePregmaticList().toPromise()).then(
          (gamesList: GamePregmatic[]) => {
            if (gamesList && gamesList.length > 0) {
              _.each(gamesList, (game: GamePregmatic) => {
                if (
                  !this.providerLevelGamesList.hasOwnProperty(game.vendorCode)
                ) {
                  this.providerLevelGamesList[game.vendorCode] = {
                    vendorName: game.vendorName,
                    "non-live-games": [],
                    "live-games": [],
                    all: [],
                  };
                }

                this.providerLevelGamesList[game.vendorCode].all.push(game);

                if (
                  game.gameType === "live_dealer" ||
                  game.gameType === "live_casino"
                ) {
                  this.providerLevelGamesList[game.vendorCode][
                    "live-games"
                  ].push(game);
                } else {
                  this.providerLevelGamesList[game.vendorCode][
                    "non-live-games"
                  ].push(game);
                }
              });
            }

            resolve(this.providerLevelGamesList);
          }
        );
      }
    });
  }

  // -----------------------------------------------------------------
  // Set Methods - on Broad Is Play Again Clicked
  onBroadIsPlayAgainClicked(isPlayAgainClicked: boolean): void {
    this.isPlayAgainClickedSubject.next(isPlayAgainClicked);
  }

  // -----------------------------------------------------------------
  // Set Methods - on Broad Is Play Again Clicked
  onLastPlayedLiveCasinoGameAvailable(
    isLiveGamesLastPlayedAvailable: boolean
  ): void {
    this.isLiveGamesLastPlayedAvailableBehaviorSubject.next(
      isLiveGamesLastPlayedAvailable
    );
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
