import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"],
})
export class PageNotFoundComponent implements OnInit {
  // Strings
  languageCode: string = "";
  theme: string = "";

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private utilityService: UtilityService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.theme = this.utilityService.setLocaleBasedTheme();

    this.languageCode = this.utilityService.getLangCode();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.theme = this.utilityService.setLocaleBasedTheme();
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGoToHome(): void {
    this.router.navigate([`${this.languageCode}/casino`]);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
