import { Observable, Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import {
  ActivatedRoute,
  NavigationEnd,
  RouterEvent,
  ParamMap,
  Params,
  Router,
} from "@angular/router";
import {
  ViewEncapsulation,
  HostListener,
  ElementRef,
  Component,
  ViewChild,
  OnDestroy,
  OnInit,
} from "@angular/core";

// Actions
import { lastPlayedGamesRequested } from "src/app/modules/game-groups/store/actions/games.actions";

// Configurations
import { launchGameURLBasedOnMarketConfigurations } from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Models
import { LaunchGameBasedOnMarketConfigurations } from "src/app/models/configurations/general-configurations/launch-game-market.model-configuration";
import { FavouriteGameList } from "src/app/modules/game-groups/models/favourite/favourite-game-list.model";
import { Favourite } from "src/app/modules/game-groups/models/favourite/favourite.model";
import { GamePlay } from "src/app/modules/game-groups/models/game-play/game-play.model";
import { SocketMessage } from "src/app/modules/shared/models/socket-message.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoginIsLoggedOut } from "src/app/modules/auth/store/selectors/auth.selectors";
import { selectAuthLoginIsLoggedIn } from "src/app/modules/auth/store/selectors/auth.selectors";
import { selectAllGames } from "src/app/modules/game-groups/store/selectors/games.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Utilities
import { getQueryParams } from "src/app/modules/shared/utilities/query-parameter.utilities";
import { purpleTheme } from "src/app/modules/multi-languages/utilities/theme.utilities";

@Component({
  selector: "app-game-play-window",
  templateUrl: "./game-play-window.component.html",
  styleUrls: ["game-play-window.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GamePlayWindowComponent implements OnDestroy, OnInit {
  // View Child
  @ViewChild("gameWindowScreen", { static: false })
  gameWindowScreen: ElementRef;
  @ViewChild("gameWindowIframe", { static: false })
  gameWindowIframe: ElementRef;

  // Numbers
  gameScreenFooterHeight: number = 64;
  topHeaderRibbonHeight: number = 0;
  gameIframeHeight: number = 0;
  tagFilterHeight: number = 0;
  windowHeight: number = 0;
  aspectRatio: number = 0;

  // Strings
  gamesUrlPath: string = environment.gamesUrlPath;
  maxBetWarningMessage: string = "";
  gameplayedFrom: string = "";
  activeGameName: string = "";
  gameCalledFrom: string = "";
  currencySymbol: string = "";
  languageCode: string = "";
  previousURL: string = "";
  imgixParams: string = "";

  // Booleans
  isGamePlayFooterHover: boolean = false;
  isQuickDepositOpen: boolean = false;
  isGamesToasterOpen: boolean = false;
  isGameMinimized: boolean = false;
  isFavoriteGame: boolean = false;
  isMaxBetPopup: boolean = false;
  isLoggedIn: boolean = false;

  // Enums
  windowType: "device" | "mobile" = "device";

  // Objects
  activeGameDataGamePregmatic: GamePregmatic;
  activeGameData: GamePregmatic | GamePlay;

  // Subjects
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Observables
  activatedRouteParamMapPipe$: Observable<string>;

  // Subscriptions
  subscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private currencyFormatPipe: CurrencyFormatPipe,
    private gameGroupsService: GameGroupsService,
    private gamePlayService: GamePlayService,
    private liveChatService: LiveChatService,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private emitterService: EmitterService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.gameCalledFrom = this.gamePlayService.getGameCalledFrom();

    window["prerenderReady"] = false;
  }

  ngAfterViewInit(): void {
    this.onGetScreenHeight();

    this.getWindowType();

    setTimeout(() => {
      if (
        document
          .querySelector(".main_container")
          .classList.contains("atGamePlay") &&
        this.windowType === "mobile"
      ) {
        this.onGoToFullScreen(document.documentElement);
      }
    });
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:orientationchange") onRotate(): void {
    this.onGetScreenHeight();

    this.getWindowType();
  }

  @HostListener("window:resize") onResize(): void {
    this.onGetScreenHeight();

    this.getWindowType();
  }

  @HostListener("window:message", ["$event"])
  onMessage(messageEvent: MessageEvent): void {
    if (messageEvent.data === "pplive_enter_fs") {
      const htmlIFrameElement: HTMLIFrameElement = document.querySelector(
        "#gameIframeWrapper iframe"
      );

      this.onGoToFullScreen(htmlIFrameElement);
    } else if (
      messageEvent.data === "pplive_exit_fs" ||
      messageEvent.data === "exit_fs_pp"
    ) {
      this.onGoOutFullScreen();
    }
  }

  // -----------------------------------------------------------------
  getWindowType(): void {
    const clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    this.activatedRouteParamMapPipe$ = this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => params.get("gameId"))
    );

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.imgixParams = this.utilityService.getImgixParams();

    this.onGetScreenHeight();

    this.getWindowType();

    this.gamePlayService.isGamePlayWindowLaunched = true;

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.commonService.quickDepositToggle$.subscribe(
        (isQuickDepositOpen: boolean) => {
          this.isQuickDepositOpen = isQuickDepositOpen;
        }
      ),
      this.gamePlayService.updateIsFavouriteGameSubject$.subscribe(
        (isFavoriteGame: boolean) => {
          this.isFavoriteGame = isFavoriteGame;
        }
      ),
      this.gamePlayService.isGameWindowMinimizedSubject$.subscribe(
        (isGameMinimized: boolean) => {
          this.isGameMinimized = isGameMinimized;
        }
      ),
      this.activatedRoute.params.subscribe(() => {
        let game:
          | GamePlay
          | GamePregmatic = this.gamePlayService.getCurrentGamePregmatic();

        if (game && Object.keys(game).length > 0) {
          this.activeGameData = game;

          this.activeGameDataGamePregmatic = game as GamePregmatic;

          this.activeGameName = this.activeGameDataGamePregmatic.name;
        }
      }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn)),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => (this.isLoggedIn = !isLoggedOut)),
      this.gamePlayService.isGameWindowMinimizedSubject$.subscribe(
        (isGameMinimized: boolean) => {
          this.isGameMinimized = isGameMinimized;

          if (isGameMinimized) {
            this.gamePlayService.onClearRegistrationTimer();

            this.onClearGameVendorCode();
          }
        }
      ),
      /*
        Below Navigation handler Logic will read game name from URL & filter the game data from all games
        present in the system by name and the use this data to create iframe & load it on page.
    
        creating game window iframe is handled in game service.ts file
      */
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.activeGameData = this.gamePlayService.getCurrentGamePregmatic();

          this.activeGameDataGamePregmatic = this
            .activeGameData as GamePregmatic;

          if (
            this.activeGameDataGamePregmatic &&
            Object.keys(this.activeGameDataGamePregmatic).length > 0
          ) {
            this.activeGameName = this.activeGameDataGamePregmatic.name;
          }

          this.activatedRouteParamMapPipe$
            .pipe(takeUntil(this.destroy$))
            .subscribe((gameId: string) => {
              const queryParams: Params = getQueryParams();

              const gameName: string = this.activeGameName
                ? this.utilityService.convertGameNameToUrl(this.activeGameName)
                : undefined;

              if (queryParams && queryParams.hasOwnProperty("login")) {
                this.activeGameName = gameId;

                this.onCheckSessionAndLoad();
              } else if (
                gameId &&
                gameName !== gameId &&
                this.utilityService
                  .getDecodedCurrentPath()
                  .includes(`/${this.translationService.get("url.game")}`)
              ) {
                this.activeGameName = gameId;

                this.onGetGameDataById(this.activeGameName);
              }
            });
        }
      }),
      /*
        Use case : when user is playing 7 piggies of demo game & trying to switch real game In this angular will
        not reload page/game because in both cases URL will be same so framework will not recognize change
      
        To Handle this case we have written a logic which emit event to force reload of game window/iframe
        Below function is handling this scenario when ever below function hits we relaod the iframe instead of page.
        & Mostly below event is boradcasted by game card component by comparing current game page url vs incoming game name
        and then broadcast relaod event.(This scenario occurs when user in game page & relaunch same game from search
        or tag or ribben)
      */
      this.gamePlayService.isRelaunchGameSubject$.subscribe(() => {
        this.activeGameData = this.gamePlayService.getCurrentGamePregmatic();

        this.activeGameDataGamePregmatic = this.activeGameData as GamePregmatic;

        if (
          this.activeGameDataGamePregmatic &&
          Object.keys(this.activeGameDataGamePregmatic).length > 0
        ) {
          this.activeGameName = this.activeGameDataGamePregmatic.name;

          this.onGetGameDataById(this.activeGameName);
        }
      }),
      this.emitterService.socketMessageSubject$.subscribe(
        (socketMessage: SocketMessage) => {
          if (socketMessage) {
            this.onOpenMaxBetWarningPopup(socketMessage);
          }
        }
      ),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.utilityService.isGamesToasterOpen$.subscribe(
        (isGamesToasterOpen: boolean) => {
          this.isGamesToasterOpen = isGamesToasterOpen;
        }
      ),
      this.utilityService.gameplayFooterHover$.subscribe(
        (isGamePlayFooterHover: boolean) => {
          this.isGamePlayFooterHover = isGamePlayFooterHover;
        }
      ),
    ];
  }

  onCheckSessionAndLoad(): void {
    if (this.sessionService.getIsUserLoggedIn()) {
      this.onGetGameDataById(this.activeGameName);
    }
  }

  onGoToFullScreen(targetElement: Element): void {
    this.utilityService.fullScreenInit(targetElement);
  }

  onGoOutFullScreen(): void {
    document.exitFullscreen();
  }

  onGetGameDataById(gameName: string): void {
    this.isFavoriteGame = false;

    this.gamePlayService.onBroadcastIsFavouriteGame(this.isFavoriteGame);

    this.subscription = this.store
      .select(selectAllGames)
      .subscribe((gamesList: GamePregmatic[]) => {
        let filterGameData: GamePregmatic = gamesList.find(
          (game: GamePregmatic) => {
            if (game.name && gameName) {
              return (
                this.utilityService.convertSpecialCharactersWithSpace(
                  game.name
                ) ==
                this.utilityService.convertSpecialCharactersWithSpace(gameName)
              );
            }
          }
        );

        if (
          this.languageCode &&
          purpleTheme().indexOf(this.languageCode) > -1 &&
          filterGameData
        ) {
          this.onSetGameProvidersUrlMapping(filterGameData);
        }

        if (!_.isEmpty(filterGameData)) {
          this.gamePlayService.onBroadCastGameLaunch(filterGameData);
          /*
            In mobile we will not have play & try for fun btn..so based on login/logout state
            we decide realgame & freegame..But if we don't have demo version a particular game,we
            are asking user to froce login(which is handled in the component level itself).
          
            whether as in desktop It will not depend on only login state...It will depends from where it's call(
            on clicking 'play btn' or 'fun btn')..because we have "try for fun" for logged In users also..
            Same here to if we don't
          */
          gameName = filterGameData.name;

          this.gameCalledFrom = this.gamePlayService.gameCalledFrom;

          if (!this.gameCalledFrom) {
            this.gameCalledFrom = this.isLoggedIn ? "realgame" : "freegame";
          }

          setTimeout(() => {
            this.gamePlayService.onLoadGamePlay({
              gameId: filterGameData.gameCode,
              gameType: this.gameCalledFrom,
              hasDemo: filterGameData.hasDemo,
            });
          }, 300);

          /*
            If user is in login state we call get Favovite game API
            & to check whether active game is favorite or not...
          */
          this.utilityService.setSEO(filterGameData, true);

          this.multiLanguageService.onSetCanonicalURL(
            filterGameData.multilingualUrl
          );
        } else {
          let gamePlay: GamePlay = {
            gameId: "",
            gameType: this.gameCalledFrom === "play" ? "realgame" : "freegame",
            hasDemo: "",
          };

          this.gamePlayService.onBroadCastGameLaunch(gamePlay);
        }

        window["prerenderReady"] = true;
      });
  }

  onSetGameProvidersUrlMapping(gamePregmatic: GamePregmatic): void {
    let gameLaunchLanguage: string = "";

    if (
      this.languageCode &&
      launchGameURLBasedOnMarketConfigurations &&
      launchGameURLBasedOnMarketConfigurations.hasOwnProperty(this.languageCode)
    ) {
      let launchGameURLBasedOnMarketClone: {
        [x: string]: LaunchGameBasedOnMarketConfigurations;
      } = {
        ...launchGameURLBasedOnMarketConfigurations,
      };

      gameLaunchLanguage =
        launchGameURLBasedOnMarketClone[this.languageCode][
          gamePregmatic.vendorCode
        ];
    }

    if (
      gamePregmatic &&
      gamePregmatic.vendorCode &&
      (gameLaunchLanguage ||
        gamePregmatic.vendorCode.toLowerCase().startsWith("mgs_") ||
        gamePregmatic.vendorCode.toLowerCase().startsWith("hub88_") ||
        gamePregmatic.vendorCode.toLowerCase().startsWith("relax_"))
    ) {
      this.gamePlayService.onSetGameVendorCode(gamePregmatic.vendorCode);
    } else {
      this.gamePlayService.onSetGameVendorCode(undefined);
    }
  }

  /*
    This functionality to handle Game window minimize & maximize
    Feature
  */
  onMakeMinimizedGame(): void {
    this.isGameMinimized = true;

    this.gamePlayService.onBroadCastGameWindowMinimized(true);

    setTimeout(() => {
      this.utilityService.backNavigationURlHandler();
    }, 200);
  }

  onMakeMaximizeGame(): void {
    if (this.windowType === "mobile") {
      this.onGoToFullScreen(document.documentElement);
    }

    if (!this.isLoggedIn && this.gamePlayService.isGamePlayWindowLaunched) {
      this.gamePlayService.onSetRegilyRegistrationPopTimer();
    }

    this.isGameMinimized = false;

    this.router.navigate([
      `${this.languageCode}/${this.translationService.get(
        "url.game"
      )}/${this.utilityService.convertGameNameToUrl(this.activeGameName)}`,
    ]);

    this.gamePlayService.onBroadCastGameWindowMinimized(false);

    this.getWindowType();
  }

  /*
    @param gameId
    game Id for which game we have to make favorite.

    @param gameState
    we have pass isFavorite state of current game in this flag
    suppose if isFavorite flag in game is true..we have to pass false to toggle it
      & if it's false,we have to pass true
  */
  onGetFavoriteGame(isForce: boolean): void {
    let activebeGameTypeId: number = 0;

    if (this.gamePlayService.getCurrentGamePregmatic()) {
      activebeGameTypeId = (this.gamePlayService.getCurrentGamePregmatic() as GamePregmatic)
        .beGameTypeId;
    }

    if (activebeGameTypeId) {
      this.gameGroupsService
        .onGetFavoriteGamesList(isForce)
        .subscribe((data: Favourite) => {
          if (data.favorite && data.favorite.favoriteGamesList.length > 0) {
            let filterredDataList: FavouriteGameList[] = _.filter(
              data.favorite.favoriteGamesList,
              { gameTypeId: activebeGameTypeId }
            );

            if (!_.isEmpty(filterredDataList[0])) {
              this.isFavoriteGame = filterredDataList[0].isFavourite;

              this.gamePlayService.onBroadcastIsFavouriteGame(
                this.isFavoriteGame
              );
            } else {
              this.isFavoriteGame = false;

              this.gamePlayService.onBroadcastIsFavouriteGame(
                this.isFavoriteGame
              );
            }
          }
        });
    }
  }

  onClearGamePlayData(): void {
    this.gamePlayService.onBroadCastGameLaunch({});

    this.gamePlayService.onBroadCastGameWindowMinimized(false);
  }

  onClosePopout(): void {
    this.onClearGamePlayData();
  }

  onCloseGame(): void {
    this.isGameMinimized = true;

    this.onClearGamePlayData();

    this.onClearGameVendorCode();

    this.gamePlayService.onClearRegistrationTimer();

    setTimeout(() => {
      this.utilityService.backNavigationURlHandler();
    });
  }

  onClearGameVendorCode(): void {
    if (this.gamePlayService.getGameVendorCode()) {
      this.gamePlayService.onSetGameVendorCode(undefined);
    }
  }

  onGetScreenHeight(): void {
    this.tagFilterHeight = 64;

    this.topHeaderRibbonHeight = 48;

    this.windowHeight =
      document.body.clientHeight -
      (this.tagFilterHeight + this.topHeaderRibbonHeight);
  }

  onHoverBottomRibbon(): void {
    this.utilityService.gameplayFooterMouseOver(true);
  }

  onMouseLeaveBottomRibbon(): void {
    if (!this.utilityService.isGamesToasterOpen.value) {
      this.utilityService.gameplayFooterMouseOver(false);
    }
  }

  onCloseQuickDepositBackdrop(): void {
    this.commonService.broadcastQuickDepositToggle(false);
  }

  onPlayRealGame(): void {
    this.gamePlayService.onSetGameCalledFrom("realgame");

    this.gameCalledFrom = this.gamePlayService.getGameCalledFrom();

    this.onGetGameDataById(this.activeGameName);
  }

  onMakeFullScreen(): void {
    let iframe: HTMLIFrameElement = document.querySelector(
      "#makeFullscreenFrame"
    );

    this.utilityService.fullScreenInit(iframe);
  }

  onExitFullscreen(): void {
    this.utilityService.exitFullScreen();
  }

  onOpenMaxBetWarningPopup(socketMessage: SocketMessage): void {
    this.maxBetWarningMessage = this.translationService.get(
      "gameplay.max_bet_text",
      {
        bonusBetLimitPerBet: socketMessage
          ? this.currencyFormatPipe.transform(
              socketMessage.bonusBetLimitPerBet,
              this.currencySymbol
            )
          : 0,
      }
    );

    this.isMaxBetPopup = true;
  }

  onCloseMaxBetWarningPopup(): void {
    this.maxBetWarningMessage = undefined;

    this.isMaxBetPopup = false;
  }

  onUpdateLastPlayedGames(): void {
    this.store.dispatch(lastPlayedGamesRequested());
  }

  onOpenAccountMenuList(): void {
    this.commonService.onBroadcastActiveAcountView("menuOptions");
  }

  onInitLiveChat(): void {
    this.liveChatService.onInitializeChat();
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.isLoggedIn) {
      this.onUpdateLastPlayedGames();
    }

    this.destroy$.next(true);

    this.destroy$.unsubscribe();

    if (this.subscription) this.subscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
