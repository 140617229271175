import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  SimpleChange,
  EventEmitter,
  Component,
  OnInit,
  Output,
  Input,
} from "@angular/core";

// Actions
import { userRequested } from "src/app/modules/user/store/actions/user.actions";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import * as _ from "underscore";

// Models
import { EligibleBonusItem } from "src/app/modules/rewards/models/eligible-bonus.model";
import { UserData } from "src/app/modules/user/models/user-data.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-payment-bonus",
  templateUrl: "./payment-bonus.component.html",
  styleUrls: ["./payment-bonus.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentBonusComponent implements OnInit {
  // Outputs
  @Output() callOnSelectBonus: EventEmitter<
    EligibleBonusItem
  > = new EventEmitter<EligibleBonusItem>();

  // Inputs
  @Input() depositBonusDetails: EligibleBonusItem[] = [];
  @Input() clearSelectedBonus: boolean = false;
  @Input() activeBonusData: EligibleBonusItem;

  // Numbers
  selectedBonusIndex: number = 0;

  // Strings
  userAffiliateId: string = "";
  currencySymbol: string = "";

  // Booleans
  isAffiliateBonus: boolean = false;
  isRealUser: boolean = false;
  isLoggedIn: boolean = false;

  // List
  depositBonusDetailsList: EligibleBonusItem[] = [];

  // Enums
  currencyCode: WorldCurrencyCode;

  // Objects
  selectedBonus: EligibleBonusItem;
  profileDetails: UserData;

  // Swiper Configurations
  swiperPaymentBonus: SwiperConfigInterface = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 8,
    resistanceRatio: 0,
    pagination: {
      el: ".paybonus-pagination",
      clickable: true,
    },
  };

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private userDetailsService: UserDetailsService,
    private cashierService: CashierService,
    private utilityService: UtilityService,
    private store: Store<AppState>
  ) {
    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onGetUserAffiliateId();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    /*
      Filter to check whether currency tier is available or not..
      we just ignore if their is not currency tier for any bonus...
    */
    if (
      changes["depositBonusDetails"] &&
      changes["depositBonusDetails"].previousValue !==
        changes["depositBonusDetails"].currentValue
    ) {
      let eligibleBonusList: EligibleBonusItem[] =
        changes["depositBonusDetails"].currentValue;

      this.depositBonusDetailsList = eligibleBonusList.filter(
        (eligibleBonusItem: EligibleBonusItem) => {
          return (
            eligibleBonusItem.currencyTier &&
            eligibleBonusItem.currencyTier[this.currencyCode] &&
            eligibleBonusItem.currencyTier[this.currencyCode].length > 0
          );
        }
      );

      this.depositBonusDetailsList = _.sortBy(
        this.depositBonusDetailsList,
        (eligibleBonusItem: EligibleBonusItem) => {
          return eligibleBonusItem.campaignStartDate;
        }
      ).reverse();

      /*
        Here we filter welcome bonus only user not navigated from banner/promotions/rewards
        with bonus code first prority goes to bonus code confiured on above then after
        welcome bonus related stuff
      */
      if (!this.cashierService.getActiveDepositBonus()) {
        this.onWelcomBonus();
      }
    }

    if (
      changes["clearSelectedBonus"] &&
      changes["clearSelectedBonus"].previousValue !==
        changes["clearSelectedBonus"].currentValue
    ) {
      if (changes["clearSelectedBonus"].currentValue) {
        this.onRemoveBonus();
      }
    }

    if (
      changes["activeBonusData"] &&
      changes["activeBonusData"].previousValue !==
        changes["activeBonusData"].currentValue
    ) {
      this.selectedBonus = changes["activeBonusData"].currentValue;

      if (this.selectedBonus.bonusCode) {
        this.onSetSelectedBonusIndex(this.selectedBonus.bonusCode);
      }
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          this.isLoggedIn = !isLoggedOut;
        }),
      this.userDetailsService.currencyCodeBehaviourSubject$.subscribe(
        (currencyCode: WorldCurrencyCode) => {
          if (this.isLoggedIn) {
            this.currencyCode = currencyCode;
          }
        }
      ),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.store
        .select(selectAuthUserDataLoaded)
        .subscribe(({ userData, isLoaded }) => {
          if (isLoaded) {
            this.profileDetails = userData;

            this.isRealUser =
              this.profileDetails.userStatus === "real" ? true : false;
          } else {
            if (this.isLoggedIn) {
              this.store.dispatch(userRequested());
            }
          }
        }),
    ];
  }

  onSelectBonus(eligibleBonusItem: EligibleBonusItem): void {
    if (
      this.selectedBonus &&
      this.selectedBonus.bonusCode === eligibleBonusItem.bonusCode
    ) {
      this.onRemoveBonus();
    } else if (eligibleBonusItem && eligibleBonusItem.bonusCode) {
      this.selectedBonus = eligibleBonusItem;

      this.callOnSelectBonus.emit(eligibleBonusItem);
    }
  }

  onSetSelectedBonusIndex(bonusCode: string): void {
    this.selectedBonusIndex = _.findIndex(this.depositBonusDetailsList, {
      bonusCode: bonusCode,
    });
  }

  /*
    Functionality to check Is user having as welcome bonus, If yes we activate
    that bonus by default to users
   */
  onWelcomBonus(): void {
    if (this.depositBonusDetailsList) {
      let eligibleBonusItem: EligibleBonusItem;

      /* 
        Here we are checking Is user having affiliate welcome bonus,
        If yes we activate affilate bonus by default to users
      */
      eligibleBonusItem = _.find(
        this.depositBonusDetailsList,
        (eligibleBonusItem: EligibleBonusItem, index: number) => {
          if (
            eligibleBonusItem.bonusName &&
            eligibleBonusItem.bonusName
              .toLowerCase()
              .startsWith("welcome offer") &&
            this.userAffiliateId &&
            eligibleBonusItem.affiliateId &&
            (eligibleBonusItem.affiliateId.includes(this.userAffiliateId) ||
              eligibleBonusItem.affiliateId.includes(
                this.userAffiliateId.split("_")[0]
              ))
          ) {
            this.selectedBonusIndex = index;

            this.isAffiliateBonus = true;

            return true;
          }
        }
      );

      if (!this.isAffiliateBonus) {
        eligibleBonusItem = _.find(
          this.depositBonusDetailsList,
          (eligibleBonusItem: EligibleBonusItem, index: number) => {
            if (
              eligibleBonusItem.bonusName &&
              eligibleBonusItem.bonusName
                .toLowerCase()
                .startsWith("welcome offer")
            ) {
              this.selectedBonusIndex = index;

              return true;
            }
          }
        );
      }

      if (eligibleBonusItem) {
        this.onSelectBonus(eligibleBonusItem);
      }
    }
  }

  onRemoveBonus(): void {
    this.selectedBonus = undefined;

    this.callOnSelectBonus.emit({});
  }

  onOpenRewardTCPopUp(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }

    this.utilityService.toggleRewardTCPopUp(true);
  }

  onGetUserAffiliateId(): void {
    if (this.profileDetails && this.profileDetails.affiliateLinkID) {
      this.userAffiliateId = this.profileDetails.affiliateLinkID;
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
