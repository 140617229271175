import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  SimpleChange,
  Component,
  OnDestroy,
  OnInit,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

// Models
import { SwiperLazy } from "src/app/modules/game-groups/models/swiper/swiper-lazy.model";
import { ProviderPregmatic } from "src/app/modules/game-groups/models/provider.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

// Utilities
import { swiperLazyConfigurationsChange } from "src/app/modules/game-groups/utilities/swiper.utility";

@Component({
  selector: "app-poster-live-casino",
  templateUrl: "./poster-live-casino.component.html",
  styleUrls: ["./poster-live-casino.component.scss"],
})
export class PosterLiveCasinoComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() gameGroupData: ProviderPregmatic;
  @Input() callingFromlobby: string = "";

  // Strings
  mediaUrlPath: string = environment.mediaUrlPath;
  currencySymbol: string = "";
  languageCode: string = "";
  imgixParams: string = "";

  // Arrays
  gamesList: GamePregmatic[] = [];

  // Swiper Configurations
  swiperPoster: SwiperConfigInterface = {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 16,
    freeMode: true,
    navigation: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    resistanceRatio: 0,
    breakpoints: {
      // breakpoints works like max-width css
      6000: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 16,
      },
      1599: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 16,
        // freeMode: false,
      },
      767: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 8,
      },
      480: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 8,
      },
    },
  };
  swiperLazyConfigurations: SwiperLazy = swiperLazyConfigurationsChange(10, 5);

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private userDetailsService: UserDetailsService,
    private utilityService: UtilityService,
    private store: Store<AppState>
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.imgixParams = this.utilityService.getImgixParams();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["gameGroupData"] &&
      changes["gameGroupData"].previousValue !==
        changes["gameGroupData"].currentValue
    ) {
      this.gameGroupData = changes["gameGroupData"].currentValue;

      if (this.gameGroupData.group_type === "manual") {
        this.gamesList = this.utilityService.sortGameByCountryOrder(
          this.gameGroupData.games
        );
      } else {
        this.gamesList = this.gameGroupData.games;
      }

      if (this.gamesList && this.gamesList.length > 0) {
        this.swiperLazyConfigurations.totalNumberOfGamesExist = this.gamesList.length;
      }
    }

    if (
      changes["callingFromlobby"] &&
      changes["callingFromlobby"].previousValue !==
        changes["callingFromlobby"].currentValue
    ) {
      this.callingFromlobby = changes["callingFromlobby"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  /*
    Function for Lazy loading of games goes here
    on every next click of silde below function will be trigerred
    & calculated how many game have to load amount that total games present

    This works on bases of swiperLazyConfig defined above...
  */
  onSwiperNextEvent() {
    if (this.swiperLazyConfigurations.backwardslideIndex) {
      this.swiperLazyConfigurations.backwardslideIndex =
        this.swiperLazyConfigurations.backwardslideIndex - 1;
      return;
    }
    if (
      this.swiperLazyConfigurations.indexNumberOfGameTilesToshow <
      this.swiperLazyConfigurations.totalNumberOfGamesExist
    ) {
      this.swiperLazyConfigurations.indexNumberOfGameTilesToshow =
        this.swiperLazyConfigurations.indexNumberOfGameTilesToshow +
        this.swiperLazyConfigurations.numberOfExtraGamesToLoadForSlide;

      this.swiperLazyConfigurations.forwardslideIndex =
        this.swiperLazyConfigurations.forwardslideIndex + 1;
    }
  }

  onSwiperPrevEvent(): void {
    this.swiperLazyConfigurations.backwardslideIndex =
      this.swiperLazyConfigurations.backwardslideIndex + 1;
  }

  onNavigateAllGamesPage(): void {
    this.utilityService.navigateAllGamesPage(this.gameGroupData);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
