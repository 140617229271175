import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  HostListener,
  SimpleChange,
  ElementRef,
  Component,
  ViewChild,
  Input,
} from "@angular/core";

// Actions
import { userRequested } from "src/app/modules/user/store/actions/user.actions";
import {
  userProfileBalanceRequested,
  profileBalanceRequested,
} from "src/app/modules/user/store/actions/profile.actions";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Configurations
import { withdrawalMethodsConfigurations } from "src/app/configurations/cashier-config/withdrawal-method-input-fields.configurations";
import { depositMethodsConfigurations } from "src/app/configurations/cashier-config/deposit-method-input-fields.configurations";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { StatusResponse } from "src/app/models/api/status.response";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Models
import { FrontendMethodConfigurationsExists } from "src/app/modules/account/models/frontend-method-configurations-exists/frontend-method-configurations-exists.model";
import { DeletePaymentMethodRequest } from "src/app/modules/transactions/models/delete-payment-method/delete-payment-method-request.model";
import { PiqTransactionStatusResponse } from "src/app/modules/account/models/piq-transaction-status/piq-transaction-status-response.model";
import { PiqTransactionStatusRequest } from "src/app/modules/account/models/piq-transaction-status/piq-transaction-status-request.model";
import { UserPaymentMethodsRequest } from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-request.model";
import { TransactionMethodTypes } from "src/app/modules/transactions/models/pending-withdrawals/pending-withdrawals-request.model";
import { TransactionStatusData } from "src/app/modules/account/models/transaction-status-data/transaction-status-data.model";
import { UserPIQAccountRequest } from "src/app/modules/transactions/models/user-piq-account/user-piq-account-request.model";
import { ProcessPaymentResponse } from "src/app/modules/account/models/process-payments/process-payment-response.model";
import { ProcessPaymentRequest } from "src/app/modules/account/models/process-payments/process-payment-request.model";
import { DepositLimitWarningMessage } from "src/app/modules/account/models/deposit-limit-warning-message.model";
import { AmountBonusWarningMessage } from "src/app/modules/account/models/amount-bonus-warning-message.model";
import { DepositMethodInputField } from "src/app/models/configurations/deposit-method-configuration.model";
import { UserBalanceDetails } from "src/app/modules/shared/models/profiles/user-balance-details.model";
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";
import {
  UserPaymentMethodsResponse,
  UserPaymentMethodFee,
  UserPaymentMethod,
} from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-response.model";
import { CashierAttributes } from "src/app/modules/account/models/cashier/cashier-attributes.model";
import { DepositMethodGroup } from "src/app/modules/account/models/deposit-method-group-form.model";
import { IFramePayment } from "src/app/modules/account/models/iframe-payment/iframe-payment.model";
import { BonusWarningMessage } from "src/app/modules/account/models/bonus-warning-message.model";
import { SelectedInputFields } from "src/app/modules/account/models/selected-input-fields.model";
import {
  UserPIQAccountResponse,
  UserPIQAccount,
} from "src/app/modules/transactions/models/user-piq-account/user-piq-account-response.model";
import { CashierDetails } from "src/app/modules/account/models/cashier/cashier-details.model";
import { FindPaymentMethod } from "src/app/modules/account/models/find-payment-method.model";
import { BonusDescription } from "src/app/modules/account/models/bonus-description.model";
import { EligibleRequest } from "src/app/modules/rewards/models/eligible-request.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";
import { DepositBonus } from "src/app/modules/account/models/deposit-bonus.model";
import { PaymentBonus } from "src/app/modules/account/models/payment-bonus.model";
import { PiqDetails } from "src/app/modules/account/models/piq-details.model";
import { UserData } from "src/app/modules/user/models/user-data.model";
import { TokenResponse } from "src/app/models/games/token.response";
import { TokenRequest } from "src/app/models/games/token.request";
import {
  EligibleBonusCriteriaType,
  EligibleBonusItem,
  CurrencyTierItem,
  EligibleBonus,
} from "src/app/modules/rewards/models/eligible-bonus.model";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Reducers
import { AppState } from "src/app/store/reducers";

// Reducers
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import {
  selectAuthProfileBalance,
  selectUserProfileBalance,
} from "src/app/modules/user/store/selectors/profile.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { CustomValidatorService } from "src/app/modules/shared/services/custom-validator.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { PaymentService } from "src/app/modules/transactions/services/payment.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { FormService } from "src/app/modules/dynamic-form/services/form.service";

// Utilities
import { getFormControlValidators } from "src/app/modules/account/utilities/form-control-validators.utilities";
import { getProcessPaymentRequest } from "src/app/modules/account/utilities/process-payment-request.utilities";
import { getMinMaxDepositAmount } from "src/app/modules/account/utilities/min-max-deposit-amount.utilities";
import { getSelectInputFields } from "src/app/modules/account/utilities/selected-input-fields.utilities";

declare let encryptData;

/*
  We are not using this component now but we may use it later
  when we plane to convert hosted cashier to our own custom cashier
  so please don't delete this

  may be useful later..
*/
@Component({
  selector: "app-cashier",
  templateUrl: "./cashier.component.html",
  styleUrls: ["./cashier.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CashierComponent extends FormValidationComponent {
  // View Child
  @ViewChild("inputPrice", { static: true }) inputPriceElement: ElementRef;

  @ViewChild("changeMethodBonusWarningPopup", { static: true })
  changeMethodBonusWarningPopupElement: ElementRef;

  @ViewChild("depositLimitWarningPopup", { static: true })
  depositLimitWarningPopupElement: ElementRef;

  @ViewChild("bonusAmountWarningPopup", { static: true })
  bonusAmountWarningPopupElement: ElementRef;

  // Inputs
  @Input() cashierType: string = "";
  @Input() cashierStep: string = "";

  // Numbers
  readonly defaultMinPaymentAmount: number = 1;
  transactionStatusIntervalCounter: number = 0;
  redirectionTimer: number = 0;
  fee: number = 0;

  // Strings
  mediaUrlPath: string = environment.mediaUrlPath;
  bonusOfferDataValue: string = "";
  currencySymbol: string = "";
  loadingMessage: string = "";
  stepType: string = "step1";
  imgixParams: string = "";
  txnRefId: string = "";
  txnId: string = "";

  // Booleans
  isFlipToShowUsedFormFields: boolean = false;
  isPaymentMethodsFecting: boolean = false;
  isShowAllPaymentMethods: boolean = false;
  isSavedPaymentListOpen: boolean = false;
  isClearSelectedBonus: boolean = false;
  isViewBalanceInfo: boolean = false;
  isAmountFocused: boolean = false;
  isRedirection: boolean = false;
  isTxnStatus: boolean = false;
  isLoggedIn: boolean = false;

  // Arrays
  selectedPaymentInputs: DepositMethodInputField[] = [];
  availableDepositBonusList: EligibleBonusItem[] = [];
  userPIQAccountList: UserPIQAccount[] = [];
  piqMethodsList: UserPaymentMethod[] = [];

  // Objects
  frontEndMethodConfigurationsExist: FrontendMethodConfigurationsExists;
  depositLimitWarningMessage: DepositLimitWarningMessage = {
    warningMessage: "",
    amount: 0,
    providerType: "",
    service: "",
  };
  amountBonusWarningMessage: AmountBonusWarningMessage = {
    message: "",
    amountVal: 0,
  };
  transactionStatusData: TransactionStatusData;
  bonusWarningMessage: BonusWarningMessage = {
    message: "",
    amount: 0,
    isEligible: false,
    providerType: "",
    service: "",
  };
  bonusOfferDataDescription: BonusDescription;
  bonusOfferData: string | BonusDescription;
  selectedUsedPaymentMethod: UserPIQAccount;
  selectedPaymentMethods: UserPaymentMethod;
  filteredPaymentPIQMethods: PaymentBonus;
  balanceDetails: UserBalanceDetails;
  activeDepositBonus: DepositBonus;
  piqDetails: PiqDetails = {
    sessionId: "",
    userId: environment.paymentIqUID,
    merchantId: environment.paymentIqMID,
    method: "",
  };
  providerTypeGroupedPaymentMethods: {
    [key: string]: UserPaymentMethod;
  };
  selectedBonus: EligibleBonusItem;
  profileDetails: UserData;

  // Enums
  windowType: "device" | "mobile" = "device";
  currencyCode: WorldCurrencyCode;

  // Form Groups
  cashierAttributesForm: FormGroup;
  cashierDetailsForm: FormGroup;
  form: FormGroup;

  // Timeouts
  timerInstance: NodeJS.Timer;

  // Interval
  transactionStatusInterval: NodeJS.Timer;

  // Subscriptions
  profileDetailsCombineLatestSubscription: Subscription;
  deleteSavedPaymentMethodSubscription: Subscription;
  piqTransactionStatusSubscription: Subscription;
  userUsedPIQAccountsSubscription: Subscription;
  userBalanceByPocketSubscription: Subscription;
  combineLatestSubscription: Subscription;
  makePaymentSubscription: Subscription;
  tokenSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private customValidatorService: CustomValidatorService,
    private userDetailsService: UserDetailsService,
    private currencyFormatPipe: CurrencyFormatPipe,
    private translateService: TranslateService,
    private cashierService: CashierService,
    private rewardsService: RewardsService,
    private paymentService: PaymentService,
    private utilityService: UtilityService,
    private formService: FormService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {
    super();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onInitializeForms();

    this.onInitializeProfileDetails();

    this.imgixParams = this.utilityService.getImgixParams();

    this.getWindowType();

    this.store.dispatch(profileBalanceRequested());

    this.store.dispatch(userProfileBalanceRequested());

    this.subscriptions = [
      this.store.select(selectAuthLoginIsLoggedIn).subscribe((isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.userDetailsService.currencyCodeBehaviourSubject$.subscribe(
        (currencyCode: WorldCurrencyCode) => {
          this.currencyCode = currencyCode;
        }
      ),
      this.store
        .select(selectAuthUserDataLoaded)
        .subscribe(({ userData, isLoaded }) => {
          if (isLoaded) {
            this.profileDetails = userData;
          } else {
            if (this.isLoggedIn) {
              this.store.dispatch(userRequested());
            }
          }
        }),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["cashierType"] &&
      changes["cashierType"].previousValue !==
      changes["cashierType"].currentValue
    ) {
      this.cashierType = changes["cashierType"].currentValue;

      if (this.cashierType === "deposit") {
        this.frontEndMethodConfigurationsExist = depositMethodsConfigurations;
      } else if (this.cashierType === "withdrawal") {
        this.frontEndMethodConfigurationsExist = withdrawalMethodsConfigurations;
      }
    }
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize")
  onResize(): void {
    this.getWindowType();
  }

  @HostListener("window:message", ["$event"])
  onMessage(event: MessageEvent): void {
    if (event && event.data && event.data.message === "getTransactionStatus") {
      this.onGetUserCurrentTransactionStatus();
    }
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    const clientWidth: number = document.body.clientWidth;

    if (clientWidth <= 1024) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getButtonDisabled(formGroup: FormGroup): boolean {
    if (
      (formGroup && formGroup.valid) ||
      (this.selectedPaymentInputs && this.selectedPaymentInputs.length === 0)
    ) {
      return false;
    } else {
      return true;
    }
  }

  getCreateGroup(): FormGroup {
    const formGroup: FormGroup = this.formBuilder.group({});

    if (this.selectedPaymentInputs) {
      this.selectedPaymentInputs.forEach((control: DepositMethodInputField) => {
        let defaultValue: string = "";

        if (
          (control.key === "cardHolder" || control.key === "beneficiaryName") &&
          this.profileDetails
        ) {
          defaultValue = `${this.profileDetails.firstName} ${this.profileDetails.lastName}`;
        }

        if (
          control.key === "phoneNumber" &&
          this.selectedPaymentMethods.providerType === "MUCHBETTER" &&
          this.profileDetails
        ) {
          let userPIQAccount: UserPIQAccount;

          if (this.userPIQAccountList && this.userPIQAccountList.length > 0) {
            userPIQAccount = this.userPIQAccountList.find(
              (userPIQAccount: UserPIQAccount) =>
                userPIQAccount.type ===
                this.selectedPaymentMethods.providerType.toLowerCase()
            );
          }

          if (userPIQAccount && Object.keys(userPIQAccount).length > 0) {
            defaultValue = userPIQAccount.maskedAccount;

            this.selectedPaymentInputs[0].readonly = true;
          } else {
            defaultValue = `${this.profileDetails.cellPhoneAreaCode}${this.profileDetails.cellPhone}`;

            this.selectedPaymentInputs[0].readonly = false;
          }
        }

        const formControl: FormControl = new FormControl(
          defaultValue,
          getFormControlValidators(control.key, this.customValidatorService)
        );

        formGroup.addControl(control.key, formControl);
      });
    }

    return formGroup;
  }

  getForAppliedBonus(callingFrom?: string): boolean {
    const amount: number = this.cashierDetailsForm.controls.amount.value;

    const bonus: string = this.cashierAttributesForm.controls.bnsCode.value;

    if (amount && bonus && bonus.length > 0) {
      this.bonusOfferData = this.cashierService.getBonusDescription(
        this.selectedBonus,
        amount,
        this.currencyCode,
        this.selectedPaymentMethods.providerType
      );

      if (typeof this.bonusOfferData === 'string') {
        this.bonusOfferDataValue = this.bonusOfferData;

      } else {
        this.bonusOfferDataDescription = this.bonusOfferData;
      }

      /*
        Condition to check & clear the bonus code from attribute from...
        If it's not eligible before jumping to next step
      */
      if (this.bonusOfferDataValue === "remove-bonus") {
        this.cashierAttributesForm.controls["bnsCode"].setValue("");

        this.isClearSelectedBonus = true;

        this.bonusOfferDataDescription = {};
      }

      if (callingFrom === "continue-btn" && _.isEmpty(this.bonusOfferDataDescription)) {
        this.onOpenAmountBonusCheckPopup(amount);

        return false;
      } else {
        return true;
      }
    } else {
      this.bonusOfferDataDescription = {};
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onInitializeProfileDetails(): void {
    this.isPaymentMethodsFecting = true;

    this.loadingMessage = this.translateService.instant(
      "cashier.fetchingPymentMethods"
    );

    if (!_.isEmpty(this.profileDetails) && !_.isEmpty(this.balanceDetails)) {
      this.piqDetails.userId = this.profileDetails.playerID;

      this.onGetCashierToken();
    } else {
      const tokenObservable: Observable<TokenResponse> = this.cashierService.onGetToken(
        {
          token: "cashier",
        }
      );

      const profileBalanceObservable: Observable<ProfileBalance> = this.store.select(
        selectAuthProfileBalance
      );

      const userProfileBalanceObservable: Observable<UserProfileBalance> = this.store.select(
        selectUserProfileBalance
      );

      this.profileDetailsCombineLatestSubscription = combineLatest([
        tokenObservable,
        profileBalanceObservable,
        userProfileBalanceObservable,
      ]).subscribe(
        ([
          tokenResponse,
          profileBalanceResponse,
          userProfileBalanceResponse,
        ]) => {
          const profileBalance: ProfileBalance = profileBalanceResponse;

          const token: TokenResponse = tokenResponse;

          const userProfileBalance: UserProfileBalance = userProfileBalanceResponse;

          if (
            profileBalance &&
            profileBalance.profile &&
            profileBalance.profile.playerID &&
            userProfileBalance
          ) {
            this.balanceDetails = this.userDetailsService.getUserBalanceDetails();

            this.piqDetails.userId = profileBalance.profile.playerID;

            this.onParseTokenData(token);
          }
        }
      );
    }

    this.balanceDetails = this.userDetailsService.getUserBalanceDetails();
  }

  onInitializeForms(): void {
    this.cashierDetailsForm = this.formBuilder.group({
      amount: [
        0,
        [
          this.customValidatorService.minValueNumber(
            this.defaultMinPaymentAmount,
            1000,
            ""
          ),
        ],
      ],
    });

    this.cashierAttributesForm = this.formBuilder.group({
      successUrl: [""],
      failureUrl: [""],
      pendingUrl: [""],
      cancelUrl: [""],
      labelId: [""],
      productId: [""],
      ipAddr: [""],
      bnsCode: [""],
    });
  }

  onGetCashierToken(): void {
    let tokenRequest: TokenRequest = {
      token: "cashier",
    };

    this.tokenSubscription = this.cashierService
      .onGetToken(tokenRequest)
      .subscribe((tokenResponse: TokenResponse) => {
        if (
          tokenResponse.status === StatusResponse.SUCCESS &&
          tokenResponse.token &&
          tokenResponse.token !== ""
        ) {
          this.onParseTokenData(tokenResponse);

          this.piqDetails.sessionId = tokenResponse.token;

          /*
            Payment related redirection url are fetch from CMS & passed to
            PaymentIQ so that they rediect back to this url after succes or failure
            any of above cases..
          */
          this.cashierAttributesForm.controls.successUrl.setValue(
            tokenResponse.cashierCallbackUrl
          );
          this.cashierAttributesForm.controls.failureUrl.setValue(
            tokenResponse.cashierCallbackUrl
          );
          this.cashierAttributesForm.controls.pendingUrl.setValue(
            tokenResponse.cashierCallbackUrl
          );
          this.cashierAttributesForm.controls.cancelUrl.setValue(
            tokenResponse.cashierCallbackUrl
          );
          this.cashierAttributesForm.controls.labelId.setValue(
            tokenResponse.labelId
          );
          this.cashierAttributesForm.controls.productId.setValue(
            tokenResponse.productId
          );
          this.cashierAttributesForm.controls.ipAddr.setValue(
            tokenResponse.ipAddr
          );
        } else {
          this.isPaymentMethodsFecting = false;

          this.onAutoFillMinimunDepositeAmount();

          this.piqMethodsList = [];
        }
      });
  }

  onParseTokenData(token: TokenResponse): void {
    if (
      token.status === StatusResponse.SUCCESS &&
      token.token &&
      token.token !== ""
    ) {
      this.piqDetails.sessionId = token.token;

      this.piqDetails.merchantId = environment.paymentIqMID;

      if (!this.cashierStep || this.cashierStep !== "transaction") {
        if (this.cashierType === "deposit") {
          this.piqDetails.method = "Deposit";
        } else if (this.cashierType === "withdrawal") {
          this.piqDetails.method = "Withdrawal";
        }

        let { merchantId, userId, sessionId, method } = this.piqDetails;

        let userPIQAccountRequest: UserPIQAccountRequest = {
          merchantId,
          userId,
          sessionId,
        };

        let userPIQAccountObservable: Observable<UserPIQAccountResponse> = this.paymentService.onGetUserUsedPIQAccounts(
          userPIQAccountRequest
        );

        let userPaymentMethodsRequest: UserPaymentMethodsRequest = {
          merchantId,
          userId,
          sessionId,
          method: TransactionMethodTypes[method],
        };

        let userPaymentMethodsObservable: Observable<UserPaymentMethodsResponse> = this.paymentService.onGetUserPIQMethods(
          userPaymentMethodsRequest
        );

        let eligibleBonusObservable: Observable<EligibleBonus>;

        if (this.cashierType === "deposit") {
          let eligibleRequest: EligibleRequest = { criteriaType: "DEPOSIT" };

          eligibleBonusObservable = this.rewardsService.onGetEligibleBonus(
            eligibleRequest
          );
        }

        this.combineLatestSubscription = combineLatest([
          userPIQAccountObservable,
          userPaymentMethodsObservable,
          eligibleBonusObservable ? eligibleBonusObservable : of(null),
        ]).subscribe(
          ([
            userPIQAccountResponse,
            userPaymentMethodsResponse,
            eligibleBonusResponse,
          ]) => {
            let userPIQAccount: UserPIQAccountResponse = userPIQAccountResponse;

            let userPaymentMethods: UserPaymentMethodsResponse = userPaymentMethodsResponse;

            let eligibleBonusDetails: EligibleBonusItem[] = [];

            if (eligibleBonusResponse) {
              let eligibleBonus: EligibleBonus = eligibleBonusResponse;

              if (eligibleBonus.status == StatusResponse.SUCCESS) {
                this.onPaymentMethods(userPaymentMethods, userPIQAccount);

                eligibleBonusDetails = eligibleBonus.eligibleBonusList;

                this.onProcessEligibleBonusDetails(eligibleBonusDetails);
              }
            } else {
              this.onPaymentMethods(userPaymentMethods, userPIQAccount);
            }
          }
        );
      } else if (this.cashierStep && this.cashierStep === "transaction") {
        this.onGetUserCurrentTransactionStatus();
      }
    } else {
      this.piqMethodsList = [];
    }
  }

  onPaymentMethods(
    userPaymentMethods: UserPaymentMethodsResponse,
    userPIQAccount: UserPIQAccountResponse
  ): void {
    this.onAutoFillMinimunDepositeAmount();

    if (userPaymentMethods.success && userPaymentMethods.methods.length > 0) {
      this.piqMethodsList = userPaymentMethods.methods;

      this.providerTypeGroupedPaymentMethods = {};

      this.piqMethodsList.forEach((userPaymentMethod: UserPaymentMethod) => {
        if (userPaymentMethod.service) {
          this.providerTypeGroupedPaymentMethods[
            `${userPaymentMethod.providerType
            }_${userPaymentMethod.service.toUpperCase()}`
          ] = userPaymentMethod;
        } else {
          this.providerTypeGroupedPaymentMethods[
            userPaymentMethod.providerType
          ] = userPaymentMethod;
        }
      });

      if (this.piqMethodsList) {
        this.onFilterPaymentMethodsBasedOnBonus();
      }

      this.onSetUserUsedPaymentMethods(userPIQAccount);

      let service: string = "";

      let type: string = "";

      let isUsedAccount: boolean = false;

      if (this.userPIQAccountList && this.userPIQAccountList.length > 0) {
        type = this.userPIQAccountList[0].type.toUpperCase();

        isUsedAccount = true;
      } else {
        type = this.piqMethodsList[0].providerType;

        service = this.piqMethodsList[0].service;

        isUsedAccount = false;
      }

      this.onSelectMethod(true, type, service, isUsedAccount, 0);
    } else {
      this.piqMethodsList = [];
    }

    if (
      this.selectedPaymentMethods &&
      this.selectedPaymentMethods.limit &&
      this.selectedPaymentMethods.limit.min &&
      +this.selectedPaymentMethods.limit.min > this.defaultMinPaymentAmount
    ) {
      this.onAutoFillMinimunDepositeAmount(
        +this.selectedPaymentMethods.limit.min
      );
    }

    this.isPaymentMethodsFecting = false;

    setTimeout(() => {
      this.onSetInputWidthResize();

      this.onSetAmountFocus();
    });
  }

  onSetUserUsedPaymentMethods(userPIQAccount: UserPIQAccountResponse): void {
    if (
      userPIQAccount &&
      userPIQAccount.success &&
      userPIQAccount.accounts.length > 0
    ) {
      this.userPIQAccountList = userPIQAccount.accounts.filter(
        (userPIQAccount: UserPIQAccount) => {
          if (
            userPIQAccount.status === "ACTIVE" &&
            userPIQAccount.visible &&
            !userPIQAccount.expired
          ) {
            if (
              userPIQAccount.service &&
              this.providerTypeGroupedPaymentMethods[
              `${userPIQAccount.type.toUpperCase()}_${userPIQAccount.service.toUpperCase()}`
              ]
            ) {
              return true;
            } else if (
              this.providerTypeGroupedPaymentMethods[
              userPIQAccount.type.toUpperCase()
              ]
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      );
    } else {
      this.userPIQAccountList = [];
    }
  }

  onProcessEligibleBonusDetails(
    eligibleBonusDetailsList: EligibleBonusItem[]
  ): void {
    this.availableDepositBonusList = eligibleBonusDetailsList.filter(
      (eligibleBonusDetail: EligibleBonusItem) =>
        eligibleBonusDetail.criteriaType === EligibleBonusCriteriaType.DEPOSIT
    );

    this.activeDepositBonus = this.cashierService.getActiveDepositBonus();

    if (this.activeDepositBonus) {
      this.onSelectedBonusHandler(this.activeDepositBonus);
    }
  }

  onSelectMethod(
    initial: boolean,
    type: string,
    service: string,
    usedAccount: boolean,
    usedAccountIdex?: number
  ): void {
    this.isFlipToShowUsedFormFields = false;

    let inputFieldReturn: SelectedInputFields;

    if (usedAccount) {
      this.selectedUsedPaymentMethod = usedAccountIdex
        ? this.userPIQAccountList[usedAccountIdex]
        : this.userPIQAccountList[0];

      type = this.selectedUsedPaymentMethod.type.toUpperCase();

      service = this.selectedUsedPaymentMethod.service;
    } else {
      this.selectedUsedPaymentMethod = undefined;
    }

    if (this.piqMethodsList && type) {
      const findPaymentMethodService: FindPaymentMethod = {
        providerType: type,
      };

      if (service) {
        findPaymentMethodService.service = service;
      }

      const findPaymentMethod: UserPaymentMethod = _.findWhere(
        this.piqMethodsList,
        findPaymentMethodService
      );

      this.selectedPaymentMethods = findPaymentMethod;

      const providerType: string = findPaymentMethod.service
        ? `${findPaymentMethod.providerType}_${findPaymentMethod.service}_PIQ`
        : `${findPaymentMethod.providerType}_PIQ`;

      if (
        (this.cashierType === "deposit" &&
          depositMethodsConfigurations[providerType]) ||
        (this.cashierType === "withdrawal" &&
          withdrawalMethodsConfigurations[providerType])
      ) {
        inputFieldReturn = getSelectInputFields(
          providerType,
          this.cashierType,
          usedAccount,
          this.profileDetails
        );

        this.selectedPaymentInputs = inputFieldReturn.selectedInputsList;
      } else {
        this.selectedPaymentInputs = undefined;
      }

      if (this.selectedPaymentMethods && this.selectedPaymentMethods.limit) {
        let minLimit: number = +this.selectedPaymentMethods.limit.min;

        let maxLimit: number = +this.selectedPaymentMethods.limit.max;

        if (minLimit && maxLimit) {
          this.cashierDetailsForm.controls.amount.setValidators([
            this.customValidatorService.minValueNumber(minLimit, maxLimit, ""),
          ]);
        }
      }

      this.form = this.getCreateGroup();

      if (inputFieldReturn && inputFieldReturn.isAccountIdAvailable) {
        this.form.controls.accountId.setValue(
          this.selectedUsedPaymentMethod.accountId
        );
      }
    }

    if (!usedAccount && this.stepType === "step1" && !initial) {
      if (!this.cashierDetailsForm.controls.amount.value) {
        this.onAutoFillMinimunDepositeAmount();
      }

      this.onNextStep();
    }

    this.onFeeForPaymentMethod();

    if (
      !initial &&
      !usedAccount &&
      this.selectedPaymentInputs &&
      this.selectedPaymentInputs.length === 0
    ) {
      this.onMakeTransaction();
    }
  }

  onFeeForPaymentMethod(): void {
    let length: number = (this.inputPriceElement
      .nativeElement as HTMLInputElement).value.length;

    (this.inputPriceElement.nativeElement as HTMLInputElement).style.width = `${length + 0.1
      }ch`;

    if (
      this.selectedPaymentMethods &&
      this.selectedPaymentMethods.fees &&
      this.selectedPaymentMethods.fees.length > 0
    ) {
      const userPaymentMethodFee: UserPaymentMethodFee = this
        .selectedPaymentMethods.fees[0];

      if (userPaymentMethodFee.percentageFee) {
        const amount: number = this.cashierDetailsForm.controls.amount.value;

        if (amount) {
          const calculatedFee: number =
            amount * +userPaymentMethodFee.percentageFee;

          if (
            userPaymentMethodFee.fixedFeeMin &&
            calculatedFee < +userPaymentMethodFee.fixedFeeMin
          ) {
            this.fee = +userPaymentMethodFee.fixedFeeMin;
          } else if (
            userPaymentMethodFee.fixedFeeMax &&
            calculatedFee > +userPaymentMethodFee.fixedFeeMax
          ) {
            this.fee = +userPaymentMethodFee.fixedFeeMax;
          } else {
            this.fee = calculatedFee;
          }
        } else {
          this.fee = 0;
        }
      } else {
        this.fee = +userPaymentMethodFee.fixedFee;
      }
    } else {
      this.fee = 0;
    }
  }

  /*
    Here we validate bonus code eligiblity criteria & before jumping
    into next to show some confirmation pop=up's
  */
  onNextStep(): void {
    if (
      this.cashierType === "withdrawal" &&
      (this.cashierDetailsForm.controls.amount.value <= 0 ||
        this.cashierDetailsForm.controls.amount.value >
        this.balanceDetails.withdrawableBalance)
    ) {
      this.cashierDetailsForm.controls.amount.setErrors({
        message: this.translateService.instant(
          "cashier.insufficent_fund_for_withdraw"
        ),
      });
    } else {
      if (this.cashierAttributesForm.controls.bnsCode.value) {
        if (this.getForAppliedBonus("continue-btn")) {
          this.onJumpToNextStep();
        }
      } else {
        this.onJumpToNextStep();
      }
    }
  }

  onJumpToNextStep(): void {
    if (this.cashierDetailsForm.controls.amount.value) {
      if (this.selectedUsedPaymentMethod) {
        if (this.form.valid) {
          this.onMakeTransaction();
        } else {
          this.isFlipToShowUsedFormFields = true;
        }
      } else {
        this.stepType = "step2";

        this.isViewBalanceInfo = false;
        /*
          Condition to check whther we have active bonus
          If yes we will filtered applicable bonus methods &
          non applicable bonus methods in order to display to user...
        */
        if (
          this.cashierAttributesForm.controls.bnsCode.value &&
          this.selectedBonus &&
          this.selectedBonus.depositOptions[0] !== "ANY"
        ) {
          this.cashierService.getPaymentMethodsBasedOnBonus(
            this.selectedBonus,
            this.piqMethodsList
          );
        }

        if (this.piqMethodsList) {
          this.onFilterPaymentMethodsBasedOnBonus();
        }
      }
    }
  }

  onFilterPaymentMethodsBasedOnBonus(): void {
    this.filteredPaymentPIQMethods = this.cashierService.getPaymentMethodsBasedOnBonus(
      this.selectedBonus,
      this.piqMethodsList
    );
  }

  onMakeTransaction(): void {
    this.isViewBalanceInfo = false;

    let cashierDetails: CashierDetails = this.formService.transformFormToData<
      CashierDetails
    >(this.cashierDetailsForm, {});

    let cashierAttributes: CashierAttributes = this.formService.transformFormToData<
      CashierAttributes
    >(this.cashierAttributesForm, {});

    let depositMethodGroup: DepositMethodGroup = this.formService.transformFormToData<
      DepositMethodGroup
    >(this.form, {});

    const processPaymentRequest: ProcessPaymentRequest = getProcessPaymentRequest(
      this.selectedPaymentMethods,
      this.cashierType,
      this.piqDetails,
      cashierDetails,
      cashierAttributes,
      depositMethodGroup,
      encryptData
    );

    if (
      this.cashierType === "deposit" ||
      (this.cashierType === "withdrawal" &&
        processPaymentRequest.amount > 0 &&
        processPaymentRequest.amount <= this.balanceDetails.withdrawableBalance)
    ) {
      this.isPaymentMethodsFecting = true;

      this.loadingMessage = this.translateService.instant(
        "cashier.transactionProcess"
      );

      this.makePaymentSubscription = this.paymentService
        .onMakePayment(processPaymentRequest)
        .subscribe((processPaymentResponse: ProcessPaymentResponse) => {
          this.txnId = processPaymentResponse.txRefId.substr(
            processPaymentResponse.txRefId.indexOf("A") + 1
          );

          this.txnRefId = processPaymentResponse.txRefId;

          sessionStorage.setItem("_userId", this.piqDetails.userId);

          sessionStorage.setItem("_sessionId", this.piqDetails.sessionId);

          sessionStorage.setItem("_tid", this.txnId);

          sessionStorage.setItem("_trid", btoa(this.txnRefId));

          sessionStorage.setItem(
            "_tam",
            btoa(processPaymentRequest.amount.toString())
          );

          if (processPaymentResponse && processPaymentResponse.success) {
            if (
              processPaymentResponse.success &&
              processPaymentResponse.txState &&
              processPaymentResponse.txState !== "WAITING_INPUT"
            ) {
              this.onGetUserCurrentTransactionStatus();
            } else if (
              processPaymentResponse.success &&
              processPaymentResponse.txState &&
              processPaymentResponse.txState === "WAITING_INPUT"
            ) {
              this.isPaymentMethodsFecting = false;

              this.stepType = "step3";

              const iFramePayment: IFramePayment = this.cashierService.getIframe(
                processPaymentResponse.redirectOutput
              );

              const { form, content } = iFramePayment;

              this.isTxnStatus = false;

              this.isRedirection = true;

              setTimeout(() => {
                $("#redirectionDiv").append(content);

                const $iframe = $("#loaderIframe");

                $iframe.ready(() => {
                  $iframe.contents().find("body").length > 0
                    ? $iframe.contents().find("body").append(form)
                    : $iframe.append(form);

                  if (
                    !processPaymentResponse.redirectOutput.html &&
                    ($("#loaderIframe").contents().find("#proxy_redirect")
                      .length ||
                      $("#loaderIframe").find("#proxy_redirect").length)
                  ) {
                    if (
                      $("#loaderIframe").contents().find("#proxy_redirect")
                        .length > 0
                    ) {
                      $("#loaderIframe")
                        .contents()
                        .find("#proxy_redirect")
                        .submit();
                    } else {
                      $("#loaderIframe").find("#proxy_redirect").submit();
                    }
                  }
                });
              }, 1);
            } else {
              let errorMessage: string = "invalid details";

              if (
                processPaymentResponse.errors &&
                processPaymentResponse.errors[0] &&
                processPaymentResponse.errors[0].msg
              ) {
                errorMessage = processPaymentResponse.errors[0].msg;
              }

              this.onShowTransactionStatus("failure", undefined);
            }
          } else {
            this.onGetUserCurrentTransactionStatus();
          }
        });
    } else {
      this.cashierDetailsForm.controls.amount.setErrors({
        message: this.translateService.instant(
          "cashier.insufficent_fund_for_withdraw"
        ),
      });
    }
  }

  onDynamicFormButtonClicked(event: MouseEvent): void {
    this.onMakeTransaction();
  }

  onShowAllPaymentMethods(): void {
    this.isShowAllPaymentMethods = !this.isShowAllPaymentMethods;
  }

  onGetUserCurrentTransactionStatus(): void {
    this.loadingMessage = this.translateService.instant(
      "cashier.transactionStatusFetch"
    );

    const piqTransactionStatusRequest: PiqTransactionStatusRequest = {
      sessionId: this.piqDetails.sessionId,
      userId: this.piqDetails.userId,
      merchantId: environment.paymentIqMID,
      transactionId: sessionStorage.getItem("_tid"),
    };

    this.piqTransactionStatusSubscription = this.paymentService
      .onGetPIQTransactionStatus(piqTransactionStatusRequest)
      .subscribe(
        (piqTransactionStatusResponse: PiqTransactionStatusResponse) => {
          this.isPaymentMethodsFecting = false;

          if (
            piqTransactionStatusResponse &&
            (piqTransactionStatusResponse.statusCode === "SUCCESS" ||
              piqTransactionStatusResponse.statusCode ===
              "WAITING_WITHDRAWAL_APPROVAL" ||
              piqTransactionStatusResponse.statusCode ===
              "SUCCESS_WAITING_AUTO_CAPTURE") &&
            (piqTransactionStatusResponse.txState === "SUCCESSFUL" ||
              piqTransactionStatusResponse.txState === "WAITING_APPROVAL")
          ) {
            this.store.dispatch(userProfileBalanceRequested());

            this.onShowTransactionStatus(
              "Success",
              atob(sessionStorage.getItem("_tam"))
            );
          } else if (
            piqTransactionStatusResponse.statusCode === "WAITING_NOTIFICATION"
          ) {
            this.transactionStatusInterval = setInterval(() => {
              if (this.transactionStatusIntervalCounter <= 3) {
                this.transactionStatusIntervalCounter++;

                this.onGetUserCurrentTransactionStatus();
              } else {
                this.onShowTransactionStatus("failure", undefined);
              }
            }, 2000);
          } else {
            this.onShowTransactionStatus("failure", undefined);
          }
        },
        () => {
          this.onShowTransactionStatus("failure", undefined);
        }
      );
  }

  onShowTransactionStatus(status: string, successAmount: string): void {
    clearInterval(this.transactionStatusInterval);

    this.transactionStatusIntervalCounter = 0;

    this.transactionStatusData = {
      status,
      successAmount,
    };

    this.stepType = "step4";

    let count: number = 6;

    this.timerInstance = setInterval(() => {
      this.redirectionTimer = --count;

      if (this.redirectionTimer === 0) {
        clearInterval(this.timerInstance);

        this.onCloseComponent();
      }
    }, 1000);

    sessionStorage.removeItem("_userId");

    sessionStorage.removeItem("_sessionId");

    sessionStorage.removeItem("_tid");

    sessionStorage.removeItem("_trid");

    sessionStorage.removeItem("_tam");

    if (status === "Success") {
      this.cashierService.onSetCastTransactionSuccessStatus(this.cashierType);
    }
  }

  onSetInputWidthResize(): void {
    let length: number = (this.inputPriceElement
      .nativeElement as HTMLInputElement).value.length;

    (this.inputPriceElement.nativeElement as HTMLInputElement).style.width = `${length + 0.1
      }ch`;
  }

  onAutoFillMinimunDepositeAmount(amount?: number): void {
    const setAmount: number = amount ? amount : this.defaultMinPaymentAmount;

    if (
      this.cashierType === "withdrawal" &&
      setAmount > this.balanceDetails.withdrawableBalance
    ) {
      this.cashierDetailsForm.controls.amount.setValue(
        this.balanceDetails.withdrawableBalance
      );
    } else {
      this.cashierDetailsForm.controls.amount.setValue(setAmount);
    }
  }

  onUpdateQuickAmount(value: number): void {
    this.cashierDetailsForm.controls.amount.setValue(value);

    this.onSetInputWidthResize();

    this.onFeeForPaymentMethod();
  }

  onCloseComponent(navigateTo?: string): void {
    if (this.timerInstance) {
      clearInterval(this.timerInstance);
    }

    this.utilityService.closeAccountComponent(navigateTo);
  }

  onSelectedBonusHandler(eligibleBonusItem: EligibleBonusItem): void {
    this.selectedBonus = eligibleBonusItem;

    if (eligibleBonusItem && eligibleBonusItem.bonusCode) {
      this.cashierAttributesForm.controls.bnsCode.setValue(
        eligibleBonusItem.bonusCode
      );

      this.isClearSelectedBonus = false;
    } else {
      this.cashierAttributesForm.controls.bnsCode.setValue("");

      this.isClearSelectedBonus = true;
    }

    this.onFilterPaymentMethodsBasedOnBonus();
    /*
      After bonus is seleted we call this method to check the eligiblity of bonus
      & display message
    */
    this.getForAppliedBonus();
  }

  onOpenAmountBonusCheckPopup(amount: number): void {
    const minDepositAmount: number = getMinMaxDepositAmount(
      this.selectedBonus,
      this.currencyCode
    ).minAmmount;

    this.amountBonusWarningMessage = {
      message:
        this.translateService.instant("cashier.bonus_eligible_min_dopt") +
        this.currencyFormatPipe.transform(
          minDepositAmount,
          this.currencySymbol
        ),
      amountVal: minDepositAmount,
    };

    (this.bonusAmountWarningPopupElement
      .nativeElement as HTMLDivElement).style.display = "flex";
  }

  onCloseAmountBonusCheckPopup(callingFrom: string, amount?: number): void {
    if (callingFrom === "cancel") {
      this.cashierAttributesForm.controls.bnsCode.setValue("");

      this.isClearSelectedBonus = true;
    } else if (callingFrom === "change-amount" && amount) {
      this.cashierDetailsForm.controls["amount"].setValue(amount);
    } else {
      this.cashierAttributesForm.controls.bnsCode.setValue("");

      this.isClearSelectedBonus = true;
    }

    (this.bonusAmountWarningPopupElement
      .nativeElement as HTMLDivElement).style.display = "none";

    this.onJumpToNextStep();
  }

  onDepositLimitsOnChangeMethod(providerType: string, service: string): void {
    const findPaymentMethod: FindPaymentMethod = {
      providerType: providerType,
    };

    if (service) {
      findPaymentMethod.service = service;
    }

    const filteredList: UserPaymentMethod = _.findWhere(
      this.piqMethodsList,
      findPaymentMethod
    );

    const minlimit: number = Number(filteredList.limit.min);

    const maxlimit: number = Number(filteredList.limit.max);

    const enterAmount: number = Number(
      this.cashierDetailsForm.controls.amount.value
    );

    if (
      enterAmount &&
      !isNaN(enterAmount) &&
      enterAmount >= minlimit &&
      enterAmount <= maxlimit
    ) {
      this.onSelectMethod(false, providerType, service, false);
    } else if (
      enterAmount &&
      (enterAmount < minlimit || enterAmount > maxlimit)
    ) {
      if (enterAmount && enterAmount < minlimit) {
        const message: string = this.translateService.instant(
          `cashier.${this.cashierType}LimitsMinWarning`,
          {
            amount: this.currencyFormatPipe.transform(
              minlimit,
              this.currencySymbol
            ),
          }
        );

        this.depositLimitWarningMessage = {
          warningMessage: message,
          amount: minlimit,
          providerType: providerType,
          service: service,
        };
      } else if (enterAmount > maxlimit) {
        const message: string = this.translateService.instant(
          `cashier.${this.cashierType}LimitsMaxWarning`,
          {
            amount: this.currencyFormatPipe.transform(
              maxlimit,
              this.currencySymbol
            ),
          }
        );

        this.depositLimitWarningMessage = {
          warningMessage: message,
          amount: maxlimit,
          providerType: providerType,
          service: service,
        };
      }

      this.onOpenDepositLimiWarningPopup();
    } else {
      this.onSelectMethod(false, providerType, service, false);
    }
  }

  onOpenDepositLimiWarningPopup(): void {
    (this.depositLimitWarningPopupElement
      .nativeElement as HTMLDivElement).style.display = "flex";
  }

  onCloseDepositLimiWarningPopup(
    callingFrom: string,
    providerType: string,
    service: string,
    amount?: number
  ): void {
    if (callingFrom === "cancel-method") {
      (this.depositLimitWarningPopupElement
        .nativeElement as HTMLDivElement).style.display = "none";
    } else if (callingFrom === "change-deposit" && amount) {
      this.cashierDetailsForm.controls["amount"].setValue(amount);

      (this.depositLimitWarningPopupElement
        .nativeElement as HTMLDivElement).style.display = "none";

      this.onSelectMethod(false, providerType, service, false);
    }

    (this.depositLimitWarningPopupElement
      .nativeElement as HTMLDivElement).style.display = "none";
  }

  onBonusOnChangPaymentMethod(
    providerType: string,
    service: string,
    callingFrom?: string
  ): void {
    if (
      callingFrom === "non-bonus-mtds" &&
      this.cashierAttributesForm.controls.bnsCode.value
    ) {
      this.bonusWarningMessage = {
        message: this.translateService.instant(
          "cashier.not_eligible_for_payment"
        ),
        amount: 0,
        isEligible: false,
        providerType,
        service,
      };

      this.onOpenBonusLimitWarningPopup();
    } else if (this.cashierAttributesForm.controls.bnsCode.value) {
      const enterAmount: number = this.cashierDetailsForm.controls.amount.value;

      if (
        enterAmount &&
        this.selectedBonus &&
        this.selectedBonus.currencyTier[this.currencyCode]
      ) {
        const currencyTierItem: CurrencyTierItem = this.selectedBonus
          .currencyTier[this.currencyCode][0];

        const minDepositValue: number = currencyTierItem.minDepositValue / 100;

        const maxDepositValue: number = currencyTierItem.maxDepositValue / 100;

        if (enterAmount < minDepositValue) {
          this.bonusWarningMessage = {
            message: `${this.translateService.instant(
              "cashier.only_applicable_with_min_dept"
            )}${this.currencyFormatPipe.transform(
              minDepositValue,
              this.currencySymbol
            )}`,
            amount: minDepositValue,
            isEligible: true,
            providerType: providerType,
            service: service,
          };

          this.onOpenBonusLimitWarningPopup();
        } else if (enterAmount > maxDepositValue) {
          this.cashierAttributesForm.controls["bnsCode"].setValue("");

          this.onDepositLimitsOnChangeMethod(providerType, service);
        } else {
          this.onDepositLimitsOnChangeMethod(providerType, service);
        }
      }
    } else {
      this.onDepositLimitsOnChangeMethod(providerType, service);
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  onOpenBonusLimitWarningPopup(): void {
    (this.changeMethodBonusWarningPopupElement
      .nativeElement as HTMLDivElement).style.display = "flex";
  }

  onCloseBonusLimitWarningPopup(
    callingFrom: string,
    providerType: string,
    service: string,
    amount?: number
  ): void {
    if (callingFrom === "change-method") {
      this.cashierAttributesForm.controls.bnsCode.setValue("");

      this.onDepositLimitsOnChangeMethod(providerType, service);
    } else if (callingFrom === "change-deposit" && amount) {
      this.cashierDetailsForm.controls.amount.setValue(amount);

      this.onDepositLimitsOnChangeMethod(providerType, service);
    }

    (this.changeMethodBonusWarningPopupElement
      .nativeElement as HTMLDivElement).style.display = "none";
  }

  onDeleteSavedPaymentMethod(accountId: string): void {
    this.isPaymentMethodsFecting = true;

    this.loadingMessage = this.translateService.instant(
      "cashier.deletingSavedPaymentMethod"
    );

    let { merchantId, userId, sessionId } = this.piqDetails;

    let deletePaymentMethodRequest: DeletePaymentMethodRequest = {
      accountId,
      merchantId,
      userId,
      sessionId,
    };

    this.deleteSavedPaymentMethodSubscription = this.paymentService
      .onDeletePaymentMethod(deletePaymentMethodRequest)
      .subscribe(
        () => {
          let userPIQAccountRequest: UserPIQAccountRequest = {
            merchantId,
            userId,
            sessionId,
          };

          this.userUsedPIQAccountsSubscription = this.paymentService
            .onGetUserUsedPIQAccounts(userPIQAccountRequest)
            .subscribe(
              (userPIQAccountResponse: UserPIQAccountResponse) => {
                this.onSetUserUsedPaymentMethods(userPIQAccountResponse);

                this.isPaymentMethodsFecting = false;
              },
              () => {
                this.userPIQAccountList = [];
              }
            );
        },
        () => {
          console.log("Unable to delete pyament Method.");
        }
      );
  }

  onBackToStep(stepType: string): void {
    this.onSelectMethod(false, undefined, undefined, true, 0);

    this.stepType = stepType;

    setTimeout(() => {
      this.onSetInputWidthResize();
    });
  }

  onSetAmountFocus(): void {
    (this.inputPriceElement.nativeElement as HTMLInputElement).focus();
  }

  onClearAmountInputField(): void {
    this.cashierDetailsForm.controls.amount.reset();

    (this.inputPriceElement.nativeElement as HTMLInputElement).focus();

    let length: number = (this.inputPriceElement
      .nativeElement as HTMLInputElement).value.length;

    (this.inputPriceElement.nativeElement as HTMLInputElement).style.width = `${length + 0.1
      }ch`;

    this.onSetInputWidthResize();

    this.onFeeForPaymentMethod();
  }

  onPreventBlur(event: MouseEvent): void {
    event.preventDefault();
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.activeDepositBonus = undefined;

    if (this.cashierService.getActiveDepositBonus()) {
      this.cashierService.onSetActiveDepositBonus(undefined);
    }

    if (this.profileDetailsCombineLatestSubscription)
      this.profileDetailsCombineLatestSubscription.unsubscribe();

    if (this.deleteSavedPaymentMethodSubscription)
      this.deleteSavedPaymentMethodSubscription.unsubscribe();

    if (this.piqTransactionStatusSubscription)
      this.piqTransactionStatusSubscription.unsubscribe();

    if (this.userUsedPIQAccountsSubscription)
      this.userUsedPIQAccountsSubscription.unsubscribe();

    if (this.userBalanceByPocketSubscription)
      this.userBalanceByPocketSubscription.unsubscribe();

    if (this.combineLatestSubscription)
      this.combineLatestSubscription.unsubscribe();

    if (this.makePaymentSubscription)
      this.makePaymentSubscription.unsubscribe();

    if (this.tokenSubscription) this.tokenSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
