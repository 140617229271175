import { MenuConfigurations } from "src/app/models/configurations/menu/menu-configuration.model";

export const menuConfigurations = (): MenuConfigurations => {
  const menuConfigurations: MenuConfigurations = {
    leftMenuConfig: [
      {
        icon: "icon-lobby icon-casino",
        linkText: "general.casino",
        navigateTo: "casino",
        onlyAfterLogin: false,
      },
      {
        icon: "icon-lobby icon-live-casino",
        linkText: "general.live_casino",
        navigateTo: "live-casino",
        onlyAfterLogin: false,
      },
      {
        icon: "icon-lobby icon-casino",
        linkText: "rewards.rewards",
        navigateTo: "rewards",
        onlyAfterLogin: true,
      },
      {
        icon: "icon-lobby icon-casino",
        linkText: "promotion.promotions",
        navigateTo: "promotions",
        onlyAfterLogin: false,
      },
      {
        icon: "icon-lobby icon-casino",
        linkText: "common.payment_methods",
        navigateTo: "payments",
        onlyAfterLogin: false,
      },
    ],
    casinoLobbyConfig: [
      {
        lobbyName: "Popular",
        filterKey: "casino",
        linkText: "general.popular",
        imageUrl: "/assets/img/game-nav/Popular.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Slots",
        filterKey: "slots",
        linkText: "general.slots",
        imageUrl: "/assets/img/game-nav/slots.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Instant Wins",
        filterKey: "instant-wins",
        linkText: "general.instant_Wins",
        imageUrl: "/assets/img/game-nav/instant-wins.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Table Games",
        filterKey: "table-games",
        linkText: "general.table_Games",
        imageUrl: "/assets/img/game-nav/table-game.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "All Games",
        filterKey: "all",
        linkText: "general.allgames",
        imageUrl: "/assets/img/game-nav/all-games.png",
        onlyAfterLogin: false,
      },
    ],
    liveCasinolobbyConfig: [
      {
        lobbyName: "All",
        filterKey: "all-live",
        linkText: "general.all",
        imageUrl: "/assets/img/game-nav/all-livecasino.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Play Again",
        filterKey: "play-again",
        linkText: "livecasino.play_again",
        imageUrl: "/assets/img/game-nav/playagain-livecasino.png",
        onlyAfterLogin: true,
      },
      {
        lobbyName: "Roulette",
        filterKey: "roulette",
        linkText: "livecasino.roulette",
        imageUrl: "/assets/img/game-nav/roulette-livecasino.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Games Shows",
        filterKey: "games-shows",
        linkText: "livecasino.game_shows",
        imageUrl: "/assets/img/game-nav/gameshows-livecasino.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Blackjack",
        filterKey: "blackjack",
        linkText: "livecasino.blackjack",
        imageUrl: "/assets/img/game-nav/blackjack-livecasino.png",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Baccarat",
        filterKey: "baccarat",
        linkText: "livecasino.baccarat",
        imageUrl: "/assets/img/game-nav/baccarate-livecasino.png",
        onlyAfterLogin: false,
      },
    ],
    lobbyByPageName: {
      casinolobbies: ["casino", "slots", "instant-wins", "table-games", "all"],
      livecasinolobbies: ["all-live", "play-again", "roulette", "games-shows", "blackjack", "baccarat"],
    },
  };

  return menuConfigurations;
};
