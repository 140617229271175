import { AbstractControl, FormGroup } from "@angular/forms";
import { Component } from "@angular/core";

@Component({
  selector: "app-form-validation",
  templateUrl: "./form-validation.component.html",
  styleUrls: ["./form-validation.component.scss"],
})
export class FormValidationComponent {
  // Element
  focusedElement: string;

  constructor() {}

  // -----------------------------------------------------------------
  // Get Methods
  getFormFieldClass(
    formControlName: string,
    formGroup: FormGroup
  ): "" | "has-error" | "has-success" {
    if (this.focusedElement && this.focusedElement == formControlName) {
      return "";
    } else {
      return this.getShouldShowErrorsInverted(formControlName, formGroup)
        ? "has-error"
        : this.isControlValid(formControlName, formGroup)
        ? "has-success"
        : "";
    }
  }

  getShouldShowErrors(formControlName: string, formGroup: FormGroup): boolean {
    if (this.focusedElement && this.focusedElement == formControlName) {
      return false;
    } else {
      let control: AbstractControl = formGroup.controls[formControlName];

      return control && control.errors && (control.dirty || control.touched);
    }
  }

  getShouldShowErrorsInverted(
    formControlName: string,
    formGroup: FormGroup
  ): boolean {
    if (this.focusedElement && this.focusedElement == formControlName) {
      return false;
    } else {
      let control: AbstractControl = formGroup.controls[formControlName];

      return control && control.errors && (control.dirty || control.touched);
    }
  }

  getErrorMessage(formControlName: string, formGroup: FormGroup): string {
    let control: AbstractControl = formGroup.controls[formControlName];

    return control.errors.message;
  }

  isButtonDisabled(formControl: FormGroup): boolean {
    return formControl.invalid || formControl.pending;
  }

  isControlValid(formControlName: string, formGroup: FormGroup): boolean {
    let control: AbstractControl = formGroup.controls[formControlName];

    return control && !control.errors && (control.dirty || control.touched);
  }

  // -----------------------------------------------------------------
  // Set Methods
  onFocusForElement(focusedElement: string): void {
    if (this.focusedElement != focusedElement) {
      this.focusedElement = focusedElement;
    }
  }

  onFocusOutForElement(): void {
    this.focusedElement = undefined;
  }

  onKeyPress(): void {
    this.focusedElement = undefined;
  }
}
