import { ViewEncapsulation, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { filter } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Models
import { LivespinsGameSymbol } from "src/app/modules/livespins/models/livespins-game-symbol.model";
import { LivespinsBetBehind } from "src/app/modules/livespins/models/livespins-bet-behind.model";

// Plugins
import { LivespinsSDK } from "livespins/@livespins";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { LivespinsService } from "src/app/modules/livespins/services/livespins.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

@Component({
  selector: "app-livespins-live-casino",
  templateUrl: "./livespins-live-casino.component.html",
  styleUrls: ["./livespins-live-casino.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LivespinsLiveCasinoComponent implements OnInit {
  // API Interactions
  apiInteractor: ApiInteractors;

  private params: Params;

  // Booleans
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Strings
  originalThemeColor: string = "";
  noGameUrlError: string = "";
  languageCode: string = "";
  authToken: string = "";
  sessionId: string = "";
  iframeURL: string = "";
  language: string = "";
  shareURL: string = "";
  backURL: string = "";

  // Subscriptions
  livespinsSDKSubscription: Subscription;
  livespinsBetSubscription: Subscription;
  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private livespinsService: LivespinsService,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private httpClient: HttpClient,
    private store: Store<AppState>
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    // We only want a passive initialisation here to set the correct service
    // urls, we do not need the websocket active for live updates.
    this.livespinsService.onInitialise();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.subscriptions = [
      this.activatedRoute.params.subscribe((params: Params) => {
        this.params = params;
      }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;

          if (this.isLoggedIn) {
            this.onInitializeLoggedInArea();
          } else {
            this.commonService.onBroadcastActiveAcountView("login");
          }
        }),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut),
    ];

    this.languageCode = this.utilityService.getLangCode();

    this.originalThemeColor = document
      .querySelector('meta[name="theme-color"]')
      .getAttribute("content");

    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", "#0D0D10");
    document.querySelector("body").classList.add("livespins");

    // @ts-ignore
    window.prerenderReady = false;

    // if (this.isLoggedIn) {
    //   this.onInitializeLoggedInArea();
    // } else {
    //   this.commonService.onBroadcastActiveAcountView("login");
    // }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onInitializeLoggedInArea(): void {
    this.livespinsBetSubscription = this.apiInteractor
      .get<LivespinsGameSymbol, LivespinsBetBehind>(
        `/ajax/launcher/getRealGames`,
        {
          gameSymbol: "livespinsBetBehind",
        }
      )
      .subscribe((realGameResponse: LivespinsBetBehind) => {
        if (
          !realGameResponse &&
          !realGameResponse.gameDetails &&
          !realGameResponse.gameDetails.url
        ) {
          this.noGameUrlError = this.translationService.get(
            "gameplay.gamenot_found"
          );
          return;
        }

        this.language =
          LivespinsService.langCodeToLivespinsLanguageCode[this.languageCode] ||
          "en";

        this.backURL = `${window.location.origin}/game-redirect.html?to=${this.languageCode}/casino`;

        this.shareURL = `${window.location.origin}/${this.languageCode}/livespins/:sessionId`;

        this.authToken = realGameResponse.gameDetails.url
          .split("&")[2]
          .replace("token=", "");

        this.sessionId = this.params.id;

        this.isLoading = false;

        this.onInvokeLivespinsSDK();
      });
  }

  onInvokeLivespinsSDK(): void {
    this.livespinsSDKSubscription = this.livespinsService.sdkSubject
      .pipe(filter((x: LivespinsSDK | null) => x !== null))
      .subscribe((value: LivespinsSDK) => {
        this.iframeURL = value.convert.toLobbyURL({
          authToken: this.authToken,
          backURL: this.backURL,
          shareURL: this.shareURL,
          openStream: `ifCount:${1}`,
          streamEndRedirect: true,
        });
      });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.livespinsBetSubscription)
      this.livespinsBetSubscription.unsubscribe();
    if (this.livespinsSDKSubscription)
      this.livespinsSDKSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );

    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", this.originalThemeColor);

    document.querySelector("body").classList.remove("livespins");
  }
}
