import { combineLatest, Observable, of, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  AfterViewInit,
  HostListener,
  Component,
  OnDestroy,
} from "@angular/core";

// Models
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Models
import { FilteredGamesBy } from "src/app/modules/game-groups/models/games/filtered-games-by.model";
import { LobbyGameGroup } from "src/app/modules/game-groups/models/lobby/lobby-game-group.model";
import { ActiveGroupData } from "src/app/modules/game-groups/models/active-group-data.model";
import { LobbyPregmatic } from "src/app/modules/game-groups/models/lobby/lobby.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAllGamesLoadingState,
  selectAllLobbyPregmatic,
  selectLastPlayedState,
  selectAllGames,
} from "src/app/modules/game-groups/store/selectors/games.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

@Component({
  selector: "app-live-casino",
  templateUrl: "./live-casino.component.html",
  styleUrls: ["./live-casino.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LiveCasinoComponent implements AfterViewInit, OnDestroy {
  // Strings
  activeLobbyName: string = "";
  languageCode: string = "";

  // Booleans
  isLastPlayedLiveGamesExist: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;
  isLoaded: boolean = false;

  // Arrays
  gameGGroupfilterByLobbyList: LobbyGameGroup[] = [];
  lobbyGameGroupsList: LobbyPregmatic[] = [];
  finalLobbyGamesList: GamePregmatic[] = [];
  finalGamesList: GamePregmatic[] = [];
  lastPlayedGamesList: number[] = [];
  gamesList: GamePregmatic[] = [];

  // Enums
  windowType: "desktop" | "mobile" = "desktop";

  // Node Timeout
  timeout: NodeJS.Timer;

  // Objects
  activeLobbyData: LobbyPregmatic | null;

  // Others
  activeLobbyProviderData: ActiveGroupData = {
    games: [],
  };
  activeGroupData: ActiveGroupData = {
    games: [],
  };
  filterByData: FilteredGamesBy = {
    typeOfGames: "live-game",
    unCheckedProviders: [],
  };

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private translationService: TranslationService,
    private gameGroupsService: GameGroupsService,
    private gamesFilterPipe: GamesFilterPipe,
    private sessionService: SessionService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private store: Store<AppState>
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onAddThemeChangeClass();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.languageCode = this.utilityService.getLangCode();

    this.subscriptions = [
      this.store
        .select(selectAllGamesLoadingState)
        .subscribe((result: { isLoaded: boolean; isLoading: boolean }) => {
          this.isLoaded = result.isLoaded;
        }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.onUpdateSEOContent();
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;

          this.onGetLobbyGroupsAndGames(true);
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = !isLoggedOut;

            this.isLastPlayedLiveGamesExist = false;

            this.onGetLobbyGroupsAndGames(true);
          }
        }),
      this.gameGroupsService.isLiveGamesLastPlayedAvailableBehaviorSubject$.subscribe(
        (isLastPlayedLiveGamesExist: boolean) => {
          this.isLastPlayedLiveGamesExist = isLastPlayedLiveGamesExist;
        }
      ),
    ];
  }

  ngAfterViewInit(): void {
    this.onUpdateSEOContent();

    this.timeout = setTimeout(() => {
      this.getWindowType();
    }, 100);
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:orientationchange") onRotate(): void {
    this.getWindowType();
  }

  @HostListener("window:resize") onResize(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Get Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.windowType = "desktop";
    } else {
      this.windowType = "mobile";
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getFilterGroupByAssetType(
    lobbyGGroupsWithGamesList: LobbyGameGroup[],
    assetType: string
  ): ActiveGroupData {
    let lobbyGameGroupList: LobbyGameGroup[] = lobbyGGroupsWithGamesList.filter(
      (lobbyGameGroup: LobbyGameGroup) => {
        if (lobbyGameGroup && lobbyGameGroup.game_asset_style === assetType) {
          return lobbyGameGroup;
        }
      }
    );

    return lobbyGameGroupList.length > 0
      ? lobbyGameGroupList[0]
      : { games: [] };
  }

  getApplyFilters(gamesList: GamePregmatic[]): GamePregmatic[] {
    return this.gamesFilterPipe.transform(gamesList, this.filterByData);
  }

  // -----------------------------------------------------------------
  // Set Methods
  onUpdateSEOContent(): void {
    this.utilityService.setSEO({
      metaTitle: this.translationService.get("SEO.liveCasinoPage_metaTitle"),
      metaDescription: this.translationService.get(
        "SEO.liveCasinoPage_metaDescription"
      ),
      metaTags: this.translationService.get("SEO.liveCasinoPage_metaTag"),
      title: this.translationService.get("SEO.liveCasinoPage_title"),
    });

    this.multiLanguageService.onSetCanonicalURL("liveCasinoPage");
  }

  onGetLobbyGroupsAndGames(isForce: boolean = false): void {
    this.isLoading = true;

    this.gameGGroupfilterByLobbyList = [];

    let lastPlayedGamesApi: Observable<number[]>;

    if (this.isLoggedIn) {
      lastPlayedGamesApi = this.store.select(selectLastPlayedState);
    }

    combineLatest([
      this.store.select(selectAllLobbyPregmatic),
      this.store.select(selectAllGames),
      lastPlayedGamesApi ? lastPlayedGamesApi : of(null),
    ]).subscribe(
      ([lobbyGameGroupsResponse, gamesResponse, lastPlayedResponse]) => {
        let lobbyGameGroupsList: LobbyPregmatic[] = lobbyGameGroupsResponse;

        let gamesList: GamePregmatic[] = gamesResponse;

        if (lastPlayedResponse) {
          let lastPlayedList: number[] = lastPlayedResponse;

          this.lastPlayedGamesList = [...lastPlayedList];
        }

        /*
          To break object referance we using below syntax because _clone works only
          one level of nested Objects..
        */
        this.lobbyGameGroupsList = JSON.parse(
          JSON.stringify(lobbyGameGroupsList)
        );

        this.gamesList = JSON.parse(JSON.stringify(gamesList));

        this.onUpdateMetaTagBasedOnLobby();

        let lobbyGGroupsWithGamesList: LobbyGameGroup[] = [];

        if (this.lobbyGameGroupsList && this.lobbyGameGroupsList.length > 0) {
          this.gameGGroupfilterByLobbyList = this.utilityService.getGameGroupsByLobby(
            [...this.lobbyGameGroupsList],
            this.activeLobbyName
          );
        }

        if (
          this.gameGGroupfilterByLobbyList &&
          this.gameGGroupfilterByLobbyList.length > 0 &&
          this.activeLobbyName &&
          this.gamesList &&
          this.gamesList.length > 0
        ) {
          lobbyGGroupsWithGamesList = this.gameGroupsService.getProcessGameGroupGames(
            this.gameGGroupfilterByLobbyList,
            this.gamesList,
            this.lastPlayedGamesList
          );
        }

        if (lobbyGGroupsWithGamesList && this.gamesList) {
          this.onSetGroupGameData(lobbyGGroupsWithGamesList, this.gamesList);

          this.onSetProviderLobbiesData(lobbyGGroupsWithGamesList);
        }
      }
    );
  }

  onUpdateMetaTagBasedOnLobby(): void {
    if (this.lobbyGameGroupsList && this.activeLobbyName) {
      this.activeLobbyData = this.utilityService.getLobbyDataByLobbyName(
        this.lobbyGameGroupsList,
        this.activeLobbyName
      );
    }
  }

  onSelectedLobby(activeLobbyName: string): void {
    this.activeLobbyName = activeLobbyName;

    if (this.activeLobbyName) {
      this.onGetLobbyGroupsAndGames();
    }
  }

  onSetGroupGameData(
    lobbyGGroupsWithGamesList: LobbyGameGroup[],
    gamesList: GamePregmatic[]
  ): void {
    this.activeGroupData = { games: [] };

    if (
      this.activeLobbyName === "all-live" &&
      gamesList &&
      gamesList.length > 0
    ) {
      this.activeGroupData.games = gamesList;
    } else if (
      lobbyGGroupsWithGamesList &&
      lobbyGGroupsWithGamesList.length > 0
    ) {
      if (this.activeLobbyName === "play-again") {
        this.activeGroupData = this.getFilterGroupByAssetType(
          lobbyGGroupsWithGamesList,
          "default-live-casino"
        );
      } else {
        this.activeGroupData = this.getFilterGroupByAssetType(
          lobbyGGroupsWithGamesList,
          "default-live-casino"
        );
      }
    } else {
      this.finalGamesList = [];

      this.activeGroupData.games = [];
    }

    this.onPrepareGroupGameData();
  }

  onPrepareGroupGameData(): void {
    if (this.activeGroupData && this.activeGroupData.games.length > 0) {
      this.finalGamesList = this.getApplyFilters(this.activeGroupData.games);

      if (this.activeLobbyName !== "all-live") {
        this.finalGamesList = this.utilityService.sortGameByCountryOrder(
          this.finalGamesList
        );
      }
    } else {
      this.finalGamesList = [];
    }

    this.isLoading = false;
  }

  onSetProviderLobbiesData(lobbyGameGroupList: LobbyGameGroup[]): void {
    /*
      This active Lobby filter code is common
      for all the categories
    */
    if (lobbyGameGroupList && lobbyGameGroupList.length > 0) {
      this.activeLobbyProviderData = this.getFilterGroupByAssetType(
        lobbyGameGroupList,
        "live-lobby-provider"
      );
    } else {
      this.activeLobbyProviderData = {};
    }

    this.onPrepareProviderLobbiesData();
  }

  onPrepareProviderLobbiesData(): void {
    if (
      this.activeLobbyProviderData &&
      this.activeLobbyProviderData.games &&
      this.activeLobbyProviderData.games.length > 0
    ) {
      this.finalLobbyGamesList = this.utilityService.sortGameByCountryOrder(
        this.getApplyFilters(this.activeLobbyProviderData.games)
      );
    } else {
      this.finalLobbyGamesList = [];
    }
  }

  onProviderListChanged(unCheckedProvidersList: string[]): void {
    this.filterByData.unCheckedProviders = unCheckedProvidersList;

    this.onPrepareGroupGameData();

    this.onPrepareProviderLobbiesData();
  }

  onAddThemeChangeClass(): void {
    this.commonService.broadCastIsLiveCasinoPage(true);
  }

  onRemoveThemeChangeClass(): void {
    this.commonService.broadCastIsLiveCasinoPage(false);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.onRemoveThemeChangeClass();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );

    if (this.timeout) clearTimeout(this.timeout);
  }
}
