import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// Configurations
import {
  marketLocaleCurrencyMappingConfigurations,
  avialableCountriesConfigurations,
  currencyMappingConfigurations,
} from "src/app/configurations/main.configurations";

// Enums
import { CurrencyPosition } from "src/app/models/configurations/enums/currency-position.enum";
import { CurrencySymbol } from "src/app/models/configurations/enums/currency-symbol.enum";

// Libraries
import * as _ from "underscore";

// Models
import { CurrencyConfigurations } from "src/app/models/configurations/general-configurations/currency-configuration.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/multi-languages/utilities/languages.utilities";

@Pipe({
  name: "currencyformat",
})
export class CurrencyFormatPipe implements PipeTransform {
  // Strings
  currencySymbol: string = "";
  languageCode: string = "";

  // Booleans
  isLoggedIn: boolean = false;

  // Arrays
  avialableCountriesList: string[] = [];

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private userDetailsService: UserDetailsService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private decimalPipe: DecimalPipe,
    private store: Store<AppState>
  ) {
    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Transform Methods
  transform(
    value: string | number,
    currencySymbol: string,
    isDecimalNeeded = true
  ): string {
    return this.getCurrencyFormat(value, currencySymbol, isDecimalNeeded);
  }

  // -----------------------------------------------------------------
  // Get Methods
  getCurrencyFormat(
    value: string | number,
    currencySymbol: string,
    isDecimalNeeded = true
  ): string {
    let isCountryExist: boolean = false;

    let countryCode: string = "";

    // Objects
    let marketLocaleCurrencyMappingClone: {
      [key: string]: CurrencyConfigurations;
    } = marketLocaleCurrencyMappingConfigurations;

    let currencyMapConfigClone: {
      [key: string]: CurrencyConfigurations;
    } = currencyMappingConfigurations;

    let data: CurrencyConfigurations;

    let languageCodeFromURL: string = this.utilityService
      .getDecodedCurrentPath()
      .split("/")[1];

    if (
      languageCodeFromURL &&
      languageCodeFromURL !== this.languageCode &&
      _.contains(supportedLanguagesList(), languageCodeFromURL)
    ) {
      this.languageCode = languageCodeFromURL;
    }

    if (this.userDetailsService.userProfileDetails) {
      countryCode = this.userDetailsService.userProfileDetails.country;
    } else {
      countryCode = this.commonService.getCountryCode();
    }

    if (!this.isLoggedIn) {
      data = marketLocaleCurrencyMappingClone.hasOwnProperty(this.languageCode)
        ? marketLocaleCurrencyMappingClone[this.languageCode]
        : undefined;
    } else {
      data = currencyMapConfigClone.hasOwnProperty(countryCode)
        ? currencyMapConfigClone[countryCode]
        : undefined;
    }

    if (data && currencySymbol) {
      data.currencySymbol = CurrencySymbol[currencySymbol];
    } else if (data) {
      data.currencySymbol =
        CurrencySymbol[this.userDetailsService.getCurrencySymbol()];
    }

    isCountryExist =
      this.avialableCountriesList.indexOf(countryCode) >= 0 ? true : false;

    if (isDecimalNeeded) {
      value = this.decimalPipe.transform(value, "1.2-2");
    }

    if (
      (isCountryExist || !this.isLoggedIn) &&
      data &&
      data.position === CurrencyPosition.before
    ) {
      return `${data.currencySymbol}${data.isSpaceRequired ? " " : ""}${value}`;
    } else if (
      (isCountryExist || !this.isLoggedIn) &&
      data &&
      data.position === CurrencyPosition.after
    ) {
      return `${value}${data.isSpaceRequired ? " " : ""}${data.currencySymbol}`;
    } else if (data) {
      return `${data.currencySymbol}${data.isSpaceRequired ? " " : ""}${value}`;
    } else {
      return `${this.currencySymbol} ${value}`;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    this.avialableCountriesList = avialableCountriesConfigurations;

    this.languageCode = this.utilityService.getLangCode();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    /*
      Here we receive both default currency(if we don't have user currency like
      before logged In state) & user currency
    */
    this.subscriptions = [
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store.select(selectAuthLoginIsLoggedIn).subscribe((isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut),
    ];
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
