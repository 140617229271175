import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { Subscription } from "rxjs";

// Modules
import { MbscFormOptions } from "src/app/modules/shared/libraries/mobiscroll/js/mobiscroll.custom-4.1.0.min";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";

@Component({
  selector: "app-bet-range-filter",
  templateUrl: "./bet-range-filter.component.html",
  styleUrls: ["./bet-range-filter.component.scss"],
})
export class BetRangeFilterComponent implements OnDestroy {
  // Outputs
  @Output() callOnBetRangeChanged: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();

  // Numbers
  betRangeMaxValue: number = 2500;
  betRangeMinValue: number = 0;

  // Strings
  currencySymbol: string = "";

  // Arrays
  betRangeValues: number[] = [100, 2200];

  // Objects
  formSettings: MbscFormOptions = {
    theme: "ios",
  };

  // Subscriptions
  subscription: Subscription;

  constructor(private userDetailsService: UserDetailsService) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.subscription = this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
      (currencySymbol: string) => {
        this.currencySymbol = currencySymbol;
      }
    );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onInputRangeChange(): void {
    this.callOnBetRangeChanged.emit(this.betRangeValues);
  }

  onClearBetRange(): void {
    this.betRangeValues = [this.betRangeMinValue, this.betRangeMaxValue];

    this.onInputRangeChange();
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
