import { FormBuilder, FormGroup } from "@angular/forms";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

// Actions
import { loginRequested } from "src/app/modules/auth/store/actions/auth.actions";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Configurations
import { mixPanelEventsConfigurations } from "src/app/configurations/main.configurations";

// Models
import { LoginEventProperties } from "src/app/modules/auth/models/login-event-properties.model";
import { LoginCredentials } from "src/app/modules/auth/models/login-credentials.model";
import { KycLevelDetails } from "src/app/modules/auth/models/kyc-level-details.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import { LoggedIn } from "src/app/modules/auth/models/logged-in.model";
import {
  NavigationRoute,
  NavigationData,
} from "src/app/modules/shared/models/navigation/navigation.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoggedIn } from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { CustomValidatorService } from "src/app/modules/shared/services/custom-validator.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { KYCService } from "src/app/modules/kyc/services/kyc.service";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent extends FormValidationComponent {
  // Strings
  languageCode: string = "";
  serverError: string = "";

  // Booleans
  isButtonLoader: boolean = false;
  isPassword: boolean = true;

  // Forms
  loginForm: FormGroup;

  // Subscriptions
  loginAuthSubscription: Subscription;
  languageSubscription: Subscription;
  loginSubscription: Subscription;
  kycSubscription: Subscription;

  constructor(
    private registrationService: RegistrationService,
    private customValidators: CustomValidatorService,
    private translationService: TranslationService,
    private gamePlayService: GamePlayService,
    private mixPanelService: MixPanelService,
    private cashierService: CashierService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private kycService: KYCService,
    private router: Router
  ) {
    super();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    // Forms Initialization
    this.loginForm = this.formBuilder.group({
      txtEmail: [
        "",
        [
          this.customValidators.validateUniqueness(
            "txtEmail",
            this.commonService,
            false
          ),
        ],
      ],
      txtPassword: ["", [this.customValidators.required]],
    });

    this.languageCode = this.utilityService.getLangCode();

    this.languageSubscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      });

    this.loginAuthSubscription = this.store
      .select(selectAuthLoggedIn)
      .subscribe((request: { loggedIn: LoggedIn; isLoaded: boolean }) => {
        if (request.isLoaded) {
          this.isButtonLoader = false;

          if (request.loggedIn && request.loggedIn.success === true) {
            this.commonService.onBroadcastActiveAcountView("");

            this.onNavigateAfterLogin();
          } else {
            this.onLoginFailure(request.loggedIn);
          }
        }
      });
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLogin(): void {
    this.isButtonLoader = true;

    this.serverError = undefined;

    if (this.loginForm.valid) {
      const credentials: LoginCredentials = {
        emailId: this.loginForm.controls["txtEmail"].value.trim(),
        password: this.loginForm.controls["txtPassword"].value,
      };

      this.store.dispatch(loginRequested({ credentials }));

      this.loginSubscription = this.store
        .select(selectAuthLoggedIn)
        .subscribe(
          (result: {
            loggedIn: LoggedIn;
            isLoaded: boolean;
            isLoading: boolean;
          }) => {
            if (result.isLoaded) {
              this.isButtonLoader = false;

              if (result.loggedIn && result.loggedIn.success) {
                this.commonService.onBroadcastActiveAcountView("");

                this.onNavigateAfterLogin();
              } else {
                this.onLoginFailure(result.loggedIn);
              }
            }
          }
        );
    }
  }

  onLoginFailure(loginData: LoggedIn): void {
    this.serverError = this.translationService.get("common.error44");

    if (loginData && loginData.errors) {
      if (loginData.errors.accountLocked) {
        let messase: string = loginData.errors.accountLocked;

        /*
          Below logic/work around is required to remove crossbrowser issue in 
          safari, firefox & windows
        */
        let accountLockedDate: Date = new Date(
          Date.parse(
            `${messase
              .substr(messase.indexOf(":") + 1)
              .trim()
              .replace(" ", "T")}+02:00`
          )
        );

        const errorMessage: string = this.translationService.get(
          "logincomponent.error_rg_cool_off"
        );

        this.serverError = `${errorMessage}${accountLockedDate}`;

        return;
      }

      let errorCode: string = "";

      if (loginData.errors.errorCode) {
        errorCode = `${loginData.errors.errorCode}`;
      } else if (loginData.errors.internalErrorCode) {
        errorCode = `${loginData.errors.internalErrorCode}`;
      }

      if (errorCode) {
        this.onDisplayLoginErrorMessage(errorCode);
      }
    }
  }

  onDisplayLoginErrorMessage(errorCode: string): void {
    switch (errorCode) {
      case "100152": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100152_loginAccountdisabled"
        );
        break;
      }
      case "100157": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100157_rg_closed"
        );
        break;
      }
      case "100173": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100173_rg_cool_off"
        );
        break;
      }
      case "100174": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100174_login_attempts_exceeded"
        );
        break;
      }
      case "100161": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100161_wrong_password"
        );
        break;
      }
      case "100166": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100166_wrong_emailid"
        );
        break;
      }
      default: {
        this.serverError = this.translationService.get("common.error44");
      }
    }
  }

  /*
    Below complete functioanlity deals with deep links before login
    basically when user try to access few part of product without login
    we useful ask user to force login before we ask for force login
    we store it's related data in service data layer & reuse it after successful
    login so that user lands on exact place where we preferred to be before login
  */
  onNavigateAfterLogin(): void {
    const navigateAfterLogin: NavigationRoute = this.commonService.getNavigateAfterLogin();

    if (navigateAfterLogin && navigateAfterLogin.type === "url") {
      this.onNavigateByUrlData(navigateAfterLogin);
    } else if (navigateAfterLogin && navigateAfterLogin.type === "view") {
      this.onNavigateByViewData(navigateAfterLogin);
    } else if (this.utilityService.getDecodedCurrentPath()) {
      this.onNavigationCurrentRoute();
    }
  }

  onNavigateByUrlData(navigateAfterLogin: NavigationRoute): void {
    if (navigateAfterLogin.data.navigateTo === "lobby") {
      this.commonService.onBroadcastNavigationData(navigateAfterLogin.data);

      if (navigateAfterLogin.data.group) {
        this.router.navigate([
          `${this.languageCode}/casino/${navigateAfterLogin.data.group}`,
        ]);
      }
    }

    if (navigateAfterLogin.data.navigateTo === "gameWindow") {
      this.gamePlayService.onSetGameCalledFrom("realgame");

      this.onSendMixPanelEvent(navigateAfterLogin.data);

      this.router.navigate([
        `${this.languageCode}/${this.translationService.get("url.game")}/${
          navigateAfterLogin.data.path
        }`,
      ]);
    }

    if (navigateAfterLogin.data.navigateTo === "livespins") {
      this.router.navigate([
        `${this.languageCode}/${navigateAfterLogin.data.navigateTo}`,
      ]);
    }
  }

  onNavigateByViewData(navigateAfterLogin: NavigationRoute): void {
    if (navigateAfterLogin.path === "confirmidentity") {
      this.onKycLevelDetails(navigateAfterLogin);
    } else {
      if (navigateAfterLogin.path === "rewards") {
        this.utilityService.updateActiveLeftMenu("rewards");
      }

      if (navigateAfterLogin.path === "deposit" && navigateAfterLogin.data) {
        this.cashierService.onSetActiveDepositBonus({
          bonusCode: navigateAfterLogin.data.bonusCode,
        });
      }

      this.utilityService.openAccountComponent(navigateAfterLogin.path);
    }
  }

  onKycLevelDetails(navigateAfterLogin: NavigationRoute): void {
    this.kycSubscription = this.kycService
      .getUserKycLevelDetails()
      .subscribe((kycLevelDetails: KycLevelDetails) => {
        if (!kycLevelDetails.enableKyc) {
          this.commonService.onBroadcastActiveAcountView("menuOptions");
        } else {
          this.utilityService.openAccountComponent(navigateAfterLogin.path);
        }
      });
  }

  onNavigationCurrentRoute(): void {
    const pathName: string = this.utilityService.getDecodedCurrentPath();

    if (pathName.length <= 7) {
      this.router.navigate([`${this.languageCode}/casino`]);
    } else if (pathName.includes(this.translationService.get("url.game"))) {
      let gamePregmatic: GamePregmatic = this.gamePlayService.getCurrentGamePregmatic() as GamePregmatic;

      if (gamePregmatic && gamePregmatic.gameType) {
        gamePregmatic.gameType = "realgame";
      }

      this.gamePlayService.onSetGameCalledFrom("realgame");

      this.gamePlayService.onSetCurrentGamePregmatic(gamePregmatic);

      this.gamePlayService.onBroadcastIsRelaunchGame(true);
    }
  }

  onCloseComponent(): void {
    if (this.commonService.getNavigateAfterLogin()) {
      this.commonService.onSetNavigateAfterLogin(undefined);
    }

    this.utilityService.closeAccountComponent("");
  }

  onGoToForgotPage(): void {
    this.onCloseComponent();

    this.commonService.onBroadcastActiveAcountView("forgot-password");
  }

  onOpenRegistrationPopup(): void {
    this.onCloseComponent();

    this.registrationService.onOpenRegistration();
  }

  onSendMixPanelEvent(gameDetails: NavigationData): void {
    if (Object.keys(gameDetails).length > 0) {
      let eventProperties: LoginEventProperties = {
        name: gameDetails.path,
        playType: "realgame",
        location: gameDetails.gameGroupName,
        category: gameDetails.lobbyName,
      };

      if (this.mixPanelService.getVerticalByUrl()) {
        eventProperties.vertical = this.mixPanelService.getVerticalByUrl();
      }

      this.mixPanelService.onTrackMixPanelEvents(
        mixPanelEventsConfigurations.launched_game,
        eventProperties
      );
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.loginAuthSubscription) this.loginAuthSubscription.unsubscribe();

    if (this.languageSubscription) this.languageSubscription.unsubscribe();

    if (this.loginSubscription) this.loginSubscription.unsubscribe();

    if (this.kycSubscription) this.kycSubscription.unsubscribe();
  }
}
