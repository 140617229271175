import { Router, NavigationEnd, RouterEvent } from "@angular/router";
import { Component, HostListener, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// Actions
import { logoutRequested } from "src/app/modules/auth/store/actions/auth.actions";
import { userRequested } from "src/app/modules/user/store/actions/user.actions";

// Configurations
import { livespinsExcludedMarketsConfigurations } from "src/app/modules/livespins/configurations/livespins.configurations";

// Environments
import { environment } from "src/environments/environment";

// Models
import { UserCurrentLevelUpDetails } from "src/app/modules/rewards/models/user-current-level-up-details.model";
import { NavigationRoute } from "src/app/modules/shared/models/navigation/navigation.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";
import { KYCService } from "src/app/modules/kyc/services/kyc.service";

@Component({
  selector: "app-account-menu",
  templateUrl: "./account-menu.component.html",
  styleUrls: ["./account-menu.component.scss"],
})
export class AccountMenuComponent implements OnInit {
  // Numbers
  userWithdrawableBalance: number = 0;
  userBonusBalance: number = 0;
  currentLevelID: number = 0;

  // Strings
  mediaUrlPath: string = environment.mediaUrlPath;
  activeLeftMenuOption: string = "";
  currencySymbol: string = "";
  languageCode: string = "";
  cashierStep: string = "";
  imgixParams: string = "";
  countryCode: string = "";
  activeView: string = "";
  activePage: string = "";
  username: string = "";
  theme: string = "";

  // Booleans
  isKycEnabled: boolean = true;
  isLoggedIn: boolean = false;

  // Arrays
  excludedMarketList: string[] = livespinsExcludedMarketsConfigurations;

  // Enums
  windowType: "desktop" | "mobile" = "desktop";

  // Timeouts
  timeout: NodeJS.Timer;

  // Subscriptions
  countryCodeSubscription: Subscription;
  activeMenuSubscription: Subscription;
  kycSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private liveChatService: LiveChatService,
    private gamePlayService: GamePlayService,
    private rewardsService: RewardsService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private kycService: KYCService,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onSetLocaleTheme();

    if (this.commonService.getCountryCode()) {
      this.countryCode = this.commonService.getCountryCode();
    }

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.imgixParams = this.utilityService.getImgixParams();

    this.languageCode = this.utilityService.getLangCode();

    this.countryCodeSubscription = this.commonService.updateCountryCode$.subscribe(
      (countryCode: string) => {
        this.countryCode = countryCode;
      }
    );

    this.activeMenuSubscription = this.commonService.activeLeftMenuSubject$.subscribe(
      (activeLeftMenuOption: string) => {
        this.activeLeftMenuOption = activeLeftMenuOption;
      }
    );

    this.subscriptions = [
      this.commonService.activeAccountViewSubject$.subscribe(
        (tabName: string) => {
          if (tabName && tabName.includes("transaction")) {
            this.cashierStep = "transaction";

            this.activeView = tabName.split("-")[0];
          } else {
            this.cashierStep = "";

            this.activeView = tabName;
          }

          if (this.activeView === "menuOptions" && this.isLoggedIn) {
            this.onGetUserKycLevelDetails();

            this.onGetCurrentUserLevel();
          }

          if (this.activeView) {
            this.utilityService.onAddClassToAppBody("overflow-hidden");
          } else {
            this.utilityService.onRemoveClassFromAppBody("overflow-hidden");
          }

          this.utilityService.updateActiveLeftMenu(this.activeView);
        }
      ),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          this.isLoggedIn = !isLoggedOut;
        }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.onSetLocaleTheme();
        }),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.userDetailsService.userBalanceUpdatedSubject$.subscribe(() => {
        this.userBonusBalance = this.userDetailsService.getUserTotalBonus();

        this.userWithdrawableBalance = this.userDetailsService.getWithdrawableBalance();
      }),
      this.store
        .select(selectAuthUserDataLoaded)
        .subscribe(({ userData, isLoaded }) => {
          if (isLoaded) {
            this.username = userData.firstName;
          } else {
            if (this.isLoggedIn) {
              this.store.dispatch(userRequested());
            }
          }
        }),
      this.rewardsService.userLevelUpDetailsSubject$.subscribe(
        (userCurrentLevelUpDetails: UserCurrentLevelUpDetails) => {
          if (
            userCurrentLevelUpDetails &&
            Object.keys(userCurrentLevelUpDetails).length > 0
          ) {
            this.currentLevelID = userCurrentLevelUpDetails.newLevelId;
          }
        }
      ),
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.activePage = this.utilityService
            .getDecodedCurrentPath()
            .split("/")[2];
        }
      }),
    ];
  }

  ngAfterViewInit(): void {
    this.timeout = setTimeout(() => {
      this.getWindowType();
    }, 100);
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:orientationchange") onRotate(): void {
    this.getWindowType();
  }

  @HostListener("window:resize") onResize(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.windowType = "desktop";
    } else {
      this.windowType = "mobile";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetLocaleTheme(): void {
    this.theme = this.utilityService.setLocaleBasedTheme();
  }

  onGetCurrentUserLevel(): void {
    if (this.rewardsService.getUserCurrentLevelId()) {
      this.currentLevelID = this.rewardsService.getUserCurrentLevelId();
    }
  }

  onOpenAccountComponent(viewName: string): void {
    if (viewName === "rewards") {
      this.rewardsService.onBroadcastLevelUpNotifications(true);
    }

    this.utilityService.openAccountComponent(viewName);
  }

  onCloseAccountOverlay(): void {
    if (this.activeView !== "deposit" && this.activeView !== "withdrawal") {
      this.utilityService.closeAccountComponent();
    }
  }

  onCloseAccountComponent(): void {
    this.utilityService.closeAccountComponent();
  }

  onGoToPromotion(): void {
    this.router.navigate([
      `${this.languageCode}/${this.translationService.get("url.promotions")}`,
    ]);

    this.onCloseAccountComponent();
  }

  onGoToPayments(): void {
    this.router.navigate([
      `${this.languageCode}/${this.translationService.get("url.payments")}`,
    ]);

    this.onCloseAccountComponent();
  }

  onNavigateTo(path?: string): void {
    if (path === "livespins") {
      if (this.sessionService.getIsUserLoggedIn()) {
        this.router.navigate([`${this.languageCode}/livespins`]);
        this.onCloseAccountComponent();
      } else {
        let navigationData: NavigationRoute = {
          type: "url",
          data: {
            navigateTo: "livespins",
            path: path,
          },
        };

        this.commonService.onSetNavigateAfterLogin(navigationData);

        this.commonService.onBroadcastActiveAcountView("login");
      }
    } else if (path === "casino") {
      this.router.navigate([`${this.languageCode}/casino`]);

      this.onCloseAccountComponent();

      this.gamePlayService.onClearRegistrationTimer();
    } else if (path === "live-casino") {
      this.router.navigate([`${this.languageCode}/live-casino`]);

      this.onCloseAccountComponent();
    } else if (path === "login") {
      this.commonService.onBroadcastActiveAcountView("login");
    } else if (path) {
      this.router.navigate([`${this.languageCode}/${path}`]);
    } else {
      this.router.navigate([`${this.languageCode}/casino`]);
    }
  }

  onGetUserKycLevelDetails(): void {
    this.kycSubscription = this.kycService
      .getUserKycLevelDetails()
      .subscribe((data) => {
        this.isKycEnabled = data["enableKyc"];
      });
  }

  onInitializeLiveChat(): void {
    this.liveChatService.onInitializeChat();

    this.onCloseAccountComponent();
  }

  onLogout(): void {
    this.store.dispatch(logoutRequested({}));

    this.onCloseAccountComponent();

    if (this.countryCodeSubscription)
      this.countryCodeSubscription.unsubscribe();

    if (this.activeMenuSubscription) this.activeMenuSubscription.unsubscribe();

    if (this.kycSubscription) this.kycSubscription.unsubscribe();
  }

  onLivespins(): void {
    if (this.commonService.getActiveAccountMenu()) {
      this.commonService.onBroadcastActiveAcountView("");
    }

    this.utilityService.updateActiveLeftMenu("livespins");

    this.router.navigate([`${this.languageCode}/livespins`]);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );

    if (this.countryCodeSubscription)
      this.countryCodeSubscription.unsubscribe();

    if (this.activeMenuSubscription) this.activeMenuSubscription.unsubscribe();

    if (this.kycSubscription) this.kycSubscription.unsubscribe();

    if (this.timeout) clearTimeout(this.timeout);
  }
}
