import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  withLatestFrom,
  catchError,
  exhaustMap,
  filter,
  map,
} from "rxjs/operators";

// Actions
import {
  userRequested,
  userLoaded,
  userError,
} from "src/app/modules/user/store/actions/user.actions";

// Models
import { UserData } from "src/app/modules/user/models/user-data.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthLoginIsLoggedIn } from "src/app/modules/auth/store/selectors/auth.selectors";
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";

@Injectable()
export class UserEffects {
  constructor(
    private userDetailsService: UserDetailsService,
    private store: Store<AppState>,
    private actions$: Actions
  ) {}

  userRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRequested),
      withLatestFrom(
        this.store.select(selectAuthUserDataLoaded),
        this.store.select(selectAuthLoginIsLoggedIn)
      ),
      filter(
        ([, { isLoaded, isError }, isLoggedIn]) =>
          !isLoaded && !isError && isLoggedIn
      ),
      exhaustMap(() => {
        return this.userDetailsService.onGetUserData().pipe(
          map((userData: UserData) => {
            if (userData.errors || userData.error) {
              throw new Error("Error");
            }

            return userData;
          }),
          map((userData: UserData) => {
            userData.firstName = userData.firstName
              ? userData.firstName.toLowerCase()
              : "";

            userData.lastName = userData.lastName
              ? userData.lastName.toLowerCase()
              : "";

            userData.affiliateLinkID = userData.affiliateLinkID
              ? userData.affiliateLinkID
              : "";

            return userData;
          }),
          map((userData: UserData) => userLoaded({ userData })),
          catchError(() => of(userError()))
        );
      })
    )
  );
}
