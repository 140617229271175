import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

@Component({
  selector: "app-promotion-card",
  templateUrl: "./promotion-card.component.html",
  styleUrls: ["./promotion-card.component.scss"],
})
export class PromotionCardComponent implements OnInit {
  // Strings
  languageCode: string = "";

  // Subscriptions
  subscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.subscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      });
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGoToPromotionPage(): void {
    this.router.navigate([`${this.languageCode}/promotions/promo1`]);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
