import {
  SimpleChange,
  Component,
  OnChanges,
  OnDestroy,
  Input,
} from "@angular/core";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";

@Component({
  selector: "app-countdown-timer",
  templateUrl: "./countdown-timer.component.html",
  styleUrls: ["./countdown-timer.component.scss"],
})
export class CountdownTimerComponent implements OnChanges, OnDestroy {
  // Inputs
  @Input() expiryDate: Date | string = "";
  @Input() callingFrom: string = "";
  @Input() promoRaceId: string = "";

  // Strings
  countTimer: string = "";

  // Interval
  calculateInterval: NodeJS.Timer;

  constructor(
    private CashbackPromoService: CashbackPromoService,
    private translationService: TranslationService
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["expiryDate"] &&
      changes["expiryDate"].previousValue !== changes["expiryDate"].currentValue
    ) {
      if (typeof changes["expiryDate"].currentValue === "string") {
        changes["expiryDate"].currentValue = parseInt(
          changes["expiryDate"].currentValue
        );
      }

      this.expiryDate = new Date(changes["expiryDate"].currentValue);

      this.onStartCountDownTimer();
    }

    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !==
        changes["callingFrom"].currentValue
    ) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }

    if (
      changes["promoRaceId"] &&
      changes["promoRaceId"].previousValue !==
        changes["promoRaceId"].currentValue
    ) {
      this.promoRaceId = changes["promoRaceId"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  /*
    Below Function will decide left out expire time of reward & also display UI
    on all the rewards present in system
   
    Like congrats screen, all rewards page, cashier page etc...
  */
  onStartCountDownTimer(): void {
    let translated_days: string = this.translationService.get(
      "rewards.days"
    );

    let translated_day: string = this.translationService.get("rewards.day");

    let date_future: Date;
    let date_now: Date;

    let seconds: number = 0;
    let minutes: number = 0;
    let hours: number = 0;
    let days: number = 0;

    this.calculateInterval = setInterval(() => {
      date_future = new Date(this.expiryDate);

      date_now = new Date();

      if (date_future > date_now) {
        seconds = Math.floor(
          (date_future.getTime() - date_now.getTime()) / 1000
        );

        minutes = Math.floor(seconds / 60);
        hours = Math.floor(minutes / 60);
        days = Math.floor(hours / 24);
        hours = hours - days * 24;

        minutes = minutes - days * 24 * 60 - hours * 60;

        seconds =
          seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

        if (days > 30) {
          this.countTimer = `>30 ${translated_days}`;
        } else if (days > 0) {
          /*
            Logic to round up days if left time is 2 days 23h 10mins 1 sec/23 days 0h 0mins 1sec is 3 days
            (2 days plus X we always display as 3 days)

            below 24h will have a timer in that format: 23h 59m 20s
          */

          if (days > 0 && (hours || minutes || seconds)) {
            days = days + 1;
          }

          this.countTimer = `${days} ${
            days === 1 ? translated_day : translated_days
          }`;
        } else if (hours > 0) {
          this.countTimer = `${hours}h ${minutes}m ${seconds}s `;
        } else if (minutes > 0) {
          this.countTimer = `${minutes}m ${seconds}s `;
        } else if (seconds > 0) {
          this.countTimer = `${seconds}s `;
        } else {
          this.countTimer = `${seconds}s `;
          if (
            seconds <= 0 &&
            this.promoRaceId &&
            this.callingFrom === "promoCounter"
          ) {
            clearInterval(this.calculateInterval);

            this.CashbackPromoService.onBroadcastStopCashbackPromo(
              this.promoRaceId
            );
          }
        }
      } else if (this.promoRaceId && this.callingFrom === "promoCounter") {
        clearInterval(this.calculateInterval);
        this.CashbackPromoService.onBroadcastStopCashbackPromo(this.promoRaceId);
      }
    }, 1000);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.calculateInterval && this.callingFrom !== "promoCounter") {
      clearInterval(this.calculateInterval);
    }
  }
}
