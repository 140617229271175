import { EnvironmentsDetails } from "src/app/models/environments/environments.model";

let isEnabledBunnyCDN: boolean = false;

const MEDIA_URL: string = isEnabledBunnyCDN ? "https://cdn.casinofriday.com" : "https://hpyhr-ps.imgix.net";

export const environment: EnvironmentsDetails = {
  production: false,
  // Site URL
  siteUrl: "https://stage-casinofriday.uatsecure.com",
  apiUrl: "https://stage-casinofriday.uatsecure.com",
  // Media URL
  mediaUrl: `${MEDIA_URL}`,
  mediaUrlPath: `${MEDIA_URL}/uploads/media/CAF`,
  // Game URL
  gamesUrlPath: `${MEDIA_URL}/uploads/games/CAF`,
  // Vimeo
  vimeoUrl: "https://player.vimeo.com/video/",
  // Website Code
  websiteCode: "CAF",
  websiteName: "Casinofriday",
  // Languages
  languagePath: "/languages/CAF/",
  availableLanguages: [
    "en",
    "no",
    "fi",
    "cd",
    "nz",
    "nl",
    "ja",
    "de",
    "pt",
    "es",
    "hu"
  ],
  defaultLanguage: "en-row",
  // Payments
  paymentIqUrl: "https://test-api.paymentiq.io/paymentiq",
  paymentIqMID: "100264999",
  paymentIqUID: "casinofriday",
  // Micro Gaming
  microGamingJackpotsUrl: "https://api32.gameassists.co.uk/casino/progressive/public/v1",
  // Live Gaming
  liveGameUrl: "prelive-dga.pragmaticplaylive.net",
  liveGameCasioID: "ppcdk00000004555",
  // Netent
  netentBrand: "oneupent",
  netentGameLiveServerUrl: "https://oneupent-livegame-test.casinomodule.com",
  netentGameStaticUrl: "https://oneupent-static-test.casinomodule.com",
  // Mix Panel Project Token
  mixPanelProjectToken: "80fc93f621b1883c55734d5d188a447b",
  // Default Currency
  defaultCurrencySymbol: "€",
  defaultCurrencyCode: "EUR",
  // Livespins URL
  livespinsApiUrl: "https://happyhour-apigateway-stage.a.hpyhr.cloud/stream",
  livespinsUiUrl: "https://livespins-stage.a.hpyhr.cloud",
  // Livespins Configurations
  livespins: {
    configurations: {
      language: "en",
      tenant: "CAF",
      serverConfig: {
        api: "https://happyhour-apigateway-stage.a.hpyhr.cloud/stream",
        ui: "https://stream-livespins-stage.a.hpyhr.cloud",
      },
    },
    shareURL: "/livespins/:sessionId/play",
  },
  // Franky LandingPage
  frankyMarket: false,
  // Features
  features: {
    hungarian: true,
    // Live Chat VIP
    liveChatVip: true,
    showConsoles: {
      regily: false
    },
    liveCasino: {
      livespins: true,
    },
  },
};
