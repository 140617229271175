import { HttpHeaders } from "@angular/common/http";

// Models
import { GeneralHeaderOptions } from "src/app/modules/shared/models/headers/general-header-options.model";

export const generalHeaderOptions = (): GeneralHeaderOptions => {
  return {
    headers: new HttpHeaders({
      "content-type": "application/x-www-form-urlencoded",
      accept: "application/json, text/plain, */*",
    }),
    withCredentials: true,
    observe: "body",
  };
};

export const jackpotHeaderOptions = (): GeneralHeaderOptions => {
  return {
    headers: new HttpHeaders({
      "content-type": "application/json",
      "X-CorrelationId": "4d8a92b5-d836-4354-95c2-75924cede1d5",
      "X-ClientTypeId": "1231",
    }),
    observe: "body",
  };
};
