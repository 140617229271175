import { TitleCasePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

// Actions
import { userRequested } from "src/app/modules/user/store/actions/user.actions";

// Configurations
import { liveChatGroupsConfigurations } from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Models
import { LiveChatGroupsConfigurations } from "src/app/models/configurations/general-configurations/live-chat-groups-configuration.model";
import { UserData } from "src/app/modules/user/models/user-data.model";
import { UserTags } from "src/app/modules/user/models/user-tags.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

// Utilities
import { vipTagsList } from "src/app/modules/chat/utilities/vip-tags.utilities";
import {
  appendInitializeLiveChatNoScript,
  appendInitializeLiveChatScript,
} from "src/app/modules/chat/utilities/live-chat-script.utilities";

@Injectable({
  providedIn: "root",
})
export class LiveChatService {
  // Booleans
  isLoggedIn: boolean = false;

  // Objects
  profileDetails: UserData;

  // Numbers
  //Live Chat VIP group id
  rowVipGroupId: number = 25;
  rowGroupId: number = 2;

  // Subscriptions
  userDataSubscription: Subscription;
  userTagsSubscription: Subscription;
  subscriptions: Subscription[] = [];

  // Strings
  languageCode: string = "";

  constructor(
    private userDetailsService: UserDetailsService,
    private rewardsService: RewardsService,
    private utilityService: UtilityService,
    private titleCasePipe: TitleCasePipe,
    private store: Store<AppState>
  ) {
    this.languageCode = this.utilityService.getLangCode();

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;

          if (this.isLoggedIn) {
            this.onLoadLiveChat();
          }
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          this.isLoggedIn = !isLoggedOut;
          if (isLoggedOut) this.onLoadLiveChat();
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Get Methods
  getTitleCase(value: string): string {
    return this.titleCasePipe.transform(value);
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoadLiveChat(): void {
    if (!document.getElementById("lc")) {
      appendInitializeLiveChatNoScript();

      if (environment.features.liveChatVip && this.isLoggedIn) {
        // calling the onGetUserTags to get the user tags to check if the user is a VIP or not.
        this.userTagsSubscription = this.userDetailsService
          .onGetUserTagsList()
          .subscribe(
            (tags: UserTags) => {
              // if the user has a tag which is part of the VIP list then pass the group id.
              if (
                tags &&
                tags.tag &&
                tags.tag.some((r) => {
                  let tag: string = vipTagsList.find(
                    (x: string) => x.toLowerCase() == r.toLowerCase()
                  );

                  if (tag) return true;
                })
              ) {
                this.onAssignGroupIdBasedOnLanguage(true);
              } else {
                this.onAssignGroupIdBasedOnLanguage(false);
              }
            },
            () => {
              appendInitializeLiveChatScript(this.rowGroupId);
            }
          );
      } else {
        this.onAssignGroupIdBasedOnLanguage(false);
      }
    }
  }

  onAssignGroupIdBasedOnLanguage(isVip: boolean): void {
    if (this.languageCode && !isVip) {
      let language: LiveChatGroupsConfigurations = liveChatGroupsConfigurations.find(
        (liveChatGroupsConfiguration: LiveChatGroupsConfigurations) =>
          liveChatGroupsConfiguration.country == this.languageCode
      );

      language
        ? appendInitializeLiveChatScript(language.groupId)
        : appendInitializeLiveChatScript(this.rowGroupId);
    } else if (this.languageCode && isVip) {
      let language: LiveChatGroupsConfigurations = liveChatGroupsConfigurations.find(
        (liveChatGroupsConfiguration: LiveChatGroupsConfigurations) =>
          liveChatGroupsConfiguration.country == `${this.languageCode}-vip`
      );

      language
        ? appendInitializeLiveChatScript(language.groupId)
        : appendInitializeLiveChatScript(this.rowVipGroupId);
    } else {
      appendInitializeLiveChatScript(this.rowGroupId);
    }
  }

  onInitializeChat(): void {
    if (!document.getElementById("lc")) this.onLoadLiveChat();

    if (window.LiveChatWidget && document.getElementById("lc")) {
      if (this.isLoggedIn) this.onGetUserProfileData();

      window.LiveChatWidget.call("maximize");
    }
  }

  onGetUserProfileData(): void {
    this.userDataSubscription = this.store
      .select(selectAuthUserDataLoaded)
      .subscribe(({ userData, isLoaded }) => {
        if (isLoaded) {
          this.profileDetails = userData;

          this.onSetUserDetails(this.profileDetails);
        } else {
          if (this.isLoggedIn) {
            this.store.dispatch(userRequested());
          }
        }
      });
  }

  onSetUserDetails(profileData: UserData): void {
    /*
      Please lines of code is used to set customer name & customer email Id
      which will be auto populated & visisble directly when customer
      opens chat
    */
    if (profileData.email && profileData.firstName) {
      window.LiveChatWidget.call("set_customer_email", profileData.email);

      window.LiveChatWidget.call(
        "set_customer_name",
        `${this.getTitleCase(profileData.firstName)} ${this.getTitleCase(
          profileData.lastName
        )}`
      );
    }

    if (profileData) {
      this.onSetSessionData(profileData);
    }
  }

  /*
    This set of code is used to create a user session on live chat,
    which help used in identifying  user session along
    with back on live chat back office
  */
  onSetSessionData(profileData: UserData): void {
    window.LiveChatWidget.call("set_session_variables", {
      name: `${this.getTitleCase(profileData.firstName)} ${this.getTitleCase(
        profileData.lastName
      )}`,
      emailID: profileData.email,
      ecrID: profileData.playerID,
      currentLevel: this.rewardsService.getUserCurrentLevelId(),
      loginStatus: "logged In",
    });
  }

  onUpdateSessionData(): void {
    if (window.LiveChatWidget && document.getElementById("lc")) {
      window.LiveChatWidget.call("update_session_variables", {
        loginStatus: "logged out",
      });
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.userDataSubscription) this.userDataSubscription.unsubscribe();

    if (this.userTagsSubscription) this.userTagsSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
