export const defaultHostedCashierStyle: string = `
* {
    font-family: "Inter", Arial, sans-serif !important;
}

:root {

    --loader-color: #1CFDD2 !important;
    --spinnerLoaderColor: var(--loader-color)!important;
    --main-container-top-down: 16px;
    --main-container-side: 0;
    --margin-size: 0;
    --border-color: transparent !important;
    --inputbackground-color: #F7F7FB !important;
    --card-bg: var(--inputbackground-color) !important;
    --cashierbackground-color: transparent !important;
    --background-color: var(--cashierbackground-color)!important;
    --input-fontSize: 14px !important;
    --input-height: 48px !important;
    --input-color: #01062B !important;
    --input-borderRadius: 4px !important;
    --labels-fontSize: 12px !important;
    --labels-color: #9795AF !important;
    --headings-color: #01062B !important;
    --buttons-color: #1CFDD2 !important;
    --button-hover-color: var(--buttons-color) !important;
    --error-color: #ff2e56 !important;
    --success-color: #4BB543 !important;
    --border-radius: 4px !important;
    --margin-size: 16px !important;
    --submitBtnTextColor: #01062B !important;
    --submitBtnDisabledBorderColor: #CECDDB;
    --submitBtnDisabledBgColor: transparent;
    --config-border-color: transparent !important;
    --dropdownBorderColor: #EBEDF5;
    --dropdownSelectedBgColor: #EBEDF5;
    --dropdownSelectedTextColor: #01062B;
    --dropdownTextColor: #666C87;
    --hosted-fields-border-color: transparent;
    --hosted-fields-border-focus-color: transparent;
    --hosted-fields-border-error-color: #FF006B!important;
    --containerWidth: 100%;
    --headings-color:  #01062B !important;
}

#cashier {
    background: var(--cashierbackground-color);
}

#app-container {
    height: 100%;
}

.spinner .spinner-left:after,
.spinner .spinner-right:after {
    border-color: var(--spinnerLoaderColor)!important;
}

.single-page-flow-container {
    margin: 0 !important;
    padding: 0 !important;
}

.payment-method .set-amount input:active,
.payment-method .set-amount input:focus,
.input:focus,
.control .input:focus {
    border-color: transparent !important;
}

.payment-method .hosted-input-container .input-container input.error,
.hosted-input-container .input-container input.error{
    opacity: 1px!important;
}

.floating-input.input:focus {
    border-color: transparent !important;
}

#cashier input:not([type=checkbox]) {
    border-color: transparent !important;
}

#cashier .submit-button {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    border-radius: 80px;
    width: 100%;
    height: 48px;
    padding: 0 8px;
    color: var(--submitBtnTextColor) !important;
    box-shadow: none;
}

#cashier .submit-button:disabled {
    border: 1px solid var(--submitBtnDisabledBorderColor);
    color: var(--submitBtnDisabledBorderColor) !important;
    background: var(--submitBtnDisabledBgColor);
    filter: opacity(100%) !important;
    cursor: not-allowed;
}

.dropdown-content {
    box-shadow: none !important;
    background:var(--inputbackground-color)!important;
}

.single-page-flow-container .dropdown-container {
    padding: 4px 16px !important;
}

.dropdown-container {
    border-radius: var(--input-borderRadius) !important;
}

.dropdown-content .dropdown-item {
    padding: 11px 16px 9px;
    border-radius: 0;
    position: relative;
    height: 44px;
    color: var(--dropdownTextColor) !important;
    font-size: 14px !important;
    font-weight: bold;

}

.dropdown-content .dropdown-item::after {
    content: '';
    border-bottom: 1px solid var(--dropdownBorderColor);
    position: absolute;
    bottom: 0;
    width: calc(100% - 32px);
    margin: auto;
    left: 0;
    right: 0;
}

.dropdown-content .dropdown-item:last-child::after {
    display: none;
}

.dropdown-content[data-v-6bdb4c07] {
    padding: 0;
}

.dropdown-content .dropdown-item.selected,
.dropdown-content .dropdown-item:hover {
    background: var(--dropdownSelectedBgColor) !important;
    color: var(--dropdownSelectedTextColor) !important;
}

.dropdown-content .dropdown-item .headings-font-size {
    margin-top: 2px;
}

.dropdown-content .dropdown-item .headings-font-size,
.dropdown-toggler span {
    max-width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

}

.dropdown-toggler ion-icon {
    font-size: 17px !important;
    color: var(--dropdownSelectedActiveTextColor);
}

.dropdown-toggler span {
    color: var(--dropdownSelectedActiveTextColor);
    font-size: 14px !important;
    font-weight: bold;
}

.dropdown:not(.account-dropdown) .logo-container .logo-icon {
    min-height: 32px !important;
    max-height: 32px !important;
    bottom: 6px;
}

.input-border .dropdown-toggler {
    padding: 4px 16px !important;
}

.input-border input {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.input-wrapper .input-label-float.active {
    margin-top: 5px !important;
    margin-left: 0px !important;
}

.input-border .input-label-float {
    left: 16px !important;
}

input:-ms-input-placeholder {
    color: var(--labels-color) !important;
}

input::-ms-input-placeholder {
    color: var(--labels-color) !important;
}

input::placeholder {
    color: var(--labels-color) !important;
    opacity: 1;
}

.label-input-floating,
.input-wrapper .input-label-float,
#label-cccard.label-input-floating.active,
.hosted-input-container .input-container .label-input-floating.active {
    color: var(--labels-color) !important;
    opacity: 1;
}

.input-wrapper .input-label-float {
    left: 16px !important;

}

#hosted-field-container-frmCCCVC,
#hosted-field-container-frmCCExp {
    margin-top: 12px !important;
}

.hosted-input-container .input-container input {
    font-weight: 700;
}

.notice,
.notice-container {
    width: 100% !important;
    margin: 0 auto !important;

}

[class*="-custom-template"] {
    background: var(--card-bg) !important;
    padding: 16px !important;
    border-radius: var(--input-borderRadius) !important;
}

[class*="-custom-template"] * {
    color: var(--labels-color) !important;
    font-size: 14px;
    font-weight: bold;
}

.selected-account-container .selected-dropdown-provider-label{
    min-width:150px;
    text-align: left;
}
.link {
    color: #0059ff !important;
}
.pending-status-polling-tries{
    color: var(--headings-color) !important;
}
`;

export const purpleThemeHostedCashierStyle: string = `
:root {
    --loader-color: #F8FD03 !important;
    --input-color: #39296D !important;
    --buttons-color: #F8FD03 !important;
    --submitBtnTextColor: #39296D !important;
}
`;
