import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Models
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  // API Interactions
  apiInteractor: ApiInteractors;

  constructor(
    private userDetailsService: UserDetailsService,
    private httpClient: HttpClient
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetProfileBalanceCurrency(): Observable<ProfileBalance> {
    return this.apiInteractor
      .get<null, ProfileBalance>(`/ajax/profile/getProfileBalanceCurrency`)
      .pipe(
        map((profileBalanceCurrency: ProfileBalance) => {
          if (profileBalanceCurrency) {
            this.userDetailsService.onSetUserCurrencyCode(
              profileBalanceCurrency.currency.code
            );

            this.userDetailsService.onSetCurrencySymbol(
              profileBalanceCurrency.currency.symbol
            );

            this.userDetailsService.onSetUserCurrencyDetails(
              profileBalanceCurrency.currency
            );

            this.userDetailsService.onSetUserProfileDetails(
              profileBalanceCurrency.profile
            );

            sessionStorage.setItem(
              "_player",
              btoa(profileBalanceCurrency.profile.playerID)
            );
          }

          return profileBalanceCurrency;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetUserBalanceByPockets(): Observable<UserProfileBalance> {
    return this.apiInteractor
      .get<null, UserProfileBalance>(`/ajax/profile/getBalanceByPocket`)
      .pipe(
        map((userProfileBalance: UserProfileBalance) => {
          if (userProfileBalance) {
            this.userDetailsService.onSetUserBalanceDetails(userProfileBalance);
          }

          return userProfileBalance;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
