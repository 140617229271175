import { SwiperConfigInterface } from "ngx-swiper-wrapper";

export const livespinsSwiper = (): SwiperConfigInterface => {
  return {
    slidesPerView: 5,
    slidesPerGroup: 5,
    spaceBetween: 16,
    freeMode: true,
    navigation: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    resistanceRatio: 0,
    breakpoints: {
      // breakpoints works like max-width css
      2500: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 16,
      },
      2000: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 16,
      },
      1640: {
        slidesPerView: 3.5,
        slidesPerGroup: 3.5,
        spaceBetween: 16,
      },
      1375: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 16,
      },
      1285: {
        slidesPerView: 2.5,
        slidesPerGroup: 2.5,
        spaceBetween: 16,
      },
      1120: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 8,
      },
      630: {
        slidesPerView: 1.5,
        slidesPerGroup: 1.5,
        spaceBetween: 8,
      },
      480: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 8,
        freeMode: false,
      },
    },
  };
};
