import { Router, NavigationEnd, RouterEvent } from "@angular/router";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  SimpleChanges,
  HostListener,
  Component,
  OnDestroy,
  Input,
} from "@angular/core";

// Actions
import { userRequested } from "src/app/modules/user/store/actions/user.actions";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Models
import { UserCurrentLevelUpPointDetails } from "src/app/modules/rewards/models/user-current-level-up-point-details.model";
import { UserCurrentLevelUpDetails } from "src/app/modules/rewards/models/user-current-level-up-details.model";
import { UserLevelUpDetails } from "src/app/modules/rewards/models/user-level-up-details.model";
import {
  RewardsIndicatorPointsDetails,
  RewardsIndicator,
} from "src/app/modules/rewards/models/rewards-indicator/rewards-indicator.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

@Component({
  selector: "app-rewards-indicator",
  templateUrl: "./rewards-indicator.component.html",
  styleUrls: ["./rewards-indicator.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RewardsIndicatorComponent implements OnDestroy {
  // Inputs
  @Input() value: number = 0;

  // Numbers
  playButtonCutOffValue: number = 5;
  notificationCount: number = 0;
  progressBarValue: number = 0;
  circumference: number = 0;
  dashOffset: number = 0;
  radius: number = 45;

  // Strings
  username: string = "";
  theme: string = "";

  // Booleans
  isLiveCasinoPage: boolean = false;
  isLoggedIn: boolean = false;

  // Enums
  windowType: "desktop" | "mobile" = "desktop";

  // Location
  addressPath: Location;

  // Objects
  rewardsIndicator: RewardsIndicator = {
    levelDetails: {
      oldLevelId: 0,
      currentLevelID: 0,
      gameId: undefined,
    },
    pointsDetails: {
      currentSpinCount: undefined,
      spinsNeededForNextLevel: undefined,
    },
  };

  // Subscriptions
  userLevelUpDetailsSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private rewardsService: RewardsService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.circumference = 2 * Math.PI * this.radius;
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    /*
      SVG fill/mask from url ID attribute not work in Safari, we adding window localtion
      @refernace : https://github.com/airbnb/lottie-web/issues/360
    */
    this.addressPath = window.location;

    this.theme = this.utilityService.setLocaleBasedTheme();

    this.onInitializeProgressCircle();

    /*
      Code to check isLiveCasinoPage or Not based on URL segments
    */
    this.onGetIsLiveCasino();

    this.subscriptions = [
      this.store.select(selectAuthLoginIsLoggedIn).subscribe((isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut),
      this.store
        .select(selectAuthUserDataLoaded)
        .subscribe(({ userData, isLoaded }) => {
          if (isLoaded) {
            if (userData) {
              this.username = userData.firstName;
            }
          } else {
            if (this.isLoggedIn) {
              this.store.dispatch(userRequested());
            }
          }
        }),
      this.rewardsService.userLevelUpDetailsSubject$.subscribe(
        (userCurrentLevelUpDetails: UserCurrentLevelUpDetails) => {
          if (
            userCurrentLevelUpDetails &&
            Object.keys(userCurrentLevelUpDetails).length > 0
          ) {
            this.rewardsIndicator.levelDetails = {
              oldLevelId: userCurrentLevelUpDetails.oldLevelId,
              currentLevelID: userCurrentLevelUpDetails.newLevelId,
              gameId: userCurrentLevelUpDetails.gameId,
            };

            this.onUpdateNotificationCount();
          } else {
            this.onClearNotification();

            this.onGetUserLevelUpDetails();
          }
        }
      ),
      this.rewardsService.userLevelUpPointDetailsSubject$.subscribe(
        (userCurrentLevelUpPointDetails: UserCurrentLevelUpPointDetails) => {
          if (
            userCurrentLevelUpPointDetails &&
            Object.keys(userCurrentLevelUpPointDetails).length > 0
          ) {
            this.rewardsIndicator.pointsDetails = {
              currentSpinCount: userCurrentLevelUpPointDetails.currentSpinCount,
              spinsNeededForNextLevel:
                userCurrentLevelUpPointDetails.spinsNeededForNextLevel,
            };

            this.rewardsIndicator.levelDetails.currentLevelID =
              userCurrentLevelUpPointDetails.levelId;

            this.onCalculateNextLevelProgressBarValue(
              this.rewardsIndicator.pointsDetails
            );
          }
        }
      ),
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.onGetIsLiveCasino();
        }
      }),
      this.rewardsService.isClearLevelUpNotificationSubject$.subscribe(
        (isClearLevelUpNotification: boolean) => {
          if (this.notificationCount && isClearLevelUpNotification) {
            this.onClearNotification();
          }
        }
      ),
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["value"] &&
      changes["value"].previousValue !== changes["value"].currentValue
    ) {
      this.onSetRewardIndicatorProgressValue(changes["value"].currentValue);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getWindowType();
    }, 100);
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:orientationchange")
  onRotate(): void {
    this.onGetIsLiveCasino();

    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.windowType = "desktop";
    } else {
      this.windowType = "mobile";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetUserLevelUpDetails(): void {
    this.userLevelUpDetailsSubscription = this.rewardsService
      .onGetUserLevelUpDetails()
      .subscribe((userLevelUpDetails: UserLevelUpDetails) => {
        if (
          userLevelUpDetails &&
          userLevelUpDetails.status === StatusResponse.SUCCESS
        ) {
          this.rewardsIndicator.levelDetails.currentLevelID =
            userLevelUpDetails.levelId;

          this.rewardsIndicator.pointsDetails = {
            currentSpinCount: userLevelUpDetails.currentSpinCount,
            spinsNeededForNextLevel: userLevelUpDetails.spinsNeededForNextLevel,
          };

          this.rewardsService.onSetLevelBeforeLevelUpgraded(
            this.rewardsIndicator.levelDetails.currentLevelID
          );

          this.onCalculateNextLevelProgressBarValue(
            this.rewardsIndicator.pointsDetails
          );
        }
      });
  }

  onUpdateNotificationCount(): void {
    this.rewardsIndicator.pointsDetails = {
      currentSpinCount: 0,
      spinsNeededForNextLevel: 0,
    };

    this.progressBarValue = 0;

    this.onSetRewardIndicatorProgressValue(this.progressBarValue * 100);

    this.notificationCount = this.rewardsService.getNotificationCount(
      this.rewardsIndicator.levelDetails.currentLevelID
    );
  }

  onCalculateNextLevelProgressBarValue(
    rewardsIndicatorPointsDetails: RewardsIndicatorPointsDetails
  ): void {
    if (
      rewardsIndicatorPointsDetails &&
      rewardsIndicatorPointsDetails.hasOwnProperty("currentSpinCount") &&
      rewardsIndicatorPointsDetails.hasOwnProperty("spinsNeededForNextLevel")
    ) {
      let totalSpins: number =
        rewardsIndicatorPointsDetails.currentSpinCount +
        rewardsIndicatorPointsDetails.spinsNeededForNextLevel;

      if (totalSpins) {
        let percentageOfSpinsNeedForNextLevel: number =
          (rewardsIndicatorPointsDetails.currentSpinCount / totalSpins) * 100;

        this.progressBarValue = parseFloat(
          (percentageOfSpinsNeedForNextLevel / 100).toFixed(2)
        );

        this.onSetRewardIndicatorProgressValue(this.progressBarValue * 100);
      }
    }
  }

  onGetIsLiveCasino(): void {
    let segmentsList: string[] = this.utilityService
      .getDecodedCurrentPath()
      .split("/");

    if (
      segmentsList &&
      segmentsList.length >= 2 &&
      this.multiLanguageService.getActiveLobby(segmentsList[2]) ===
      "live-casino"
    ) {
      this.isLiveCasinoPage = true;
    } else {
      this.isLiveCasinoPage = false;
    }
  }

  onInitializeProgressCircle(): void {
    this.dashOffset = this.circumference;

    this.onGetUserLevelUpDetails();
  }

  onOpenCongratulationsPopUp(): void {
    this.commonService.onBroadcastActiveAcountView("reward-congratulations");

    if (this.notificationCount) {
      this.onClearNotification();
    }
  }

  onOpenAccount(viewName: string): void {
    this.onClearNotification();

    this.utilityService.openAccountComponent(viewName);
  }

  onClearNotification(): void {
    this.rewardsService.onSetLevelBeforeLevelUpgraded(
      this.rewardsIndicator.levelDetails.currentLevelID
    );

    this.notificationCount = 0;
  }

  onSetRewardIndicatorProgressValue(value: number): void {
    const progress: number = value / 100;

    this.dashOffset = this.circumference * (1 - progress);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.userLevelUpDetailsSubscription)
      this.userLevelUpDetailsSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
