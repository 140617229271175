import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Models
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAllGames } from "src/app/modules/game-groups/store/selectors/games.selectors";

// Services
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-game-play-full-window",
  templateUrl: "./game-play-full-window.component.html",
  styleUrls: ["game-play-full-window.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GamePlayFullWindowComponent implements OnInit {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Strings
  gameCode: string = "";
  gameId: string = "";

  // Subscriptions
  subscriptionGame: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private gamePlayService: GamePlayService,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private httpClient: HttpClient,
    private store: Store<AppState>
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit() {
    this.subscriptions = [
      this.activatedRoute.params.subscribe((params: Params) => {
        this.gameId = params.gameId;
      }),
      this.store
        .select(selectAllGames)
        .subscribe((gamePregmaticList: GamePregmatic[]) => {
          const gamePregmatic: GamePregmatic = gamePregmaticList.find(
            (gamePregmaticItem: GamePregmatic) => {
              return (
                this.utilityService.convertSpecialCharactersWithSpace(
                  gamePregmaticItem.pristineGameId
                ) ===
                this.utilityService.convertSpecialCharactersWithSpace(
                  this.gameId
                )
              );
            }
          );

          if (gamePregmatic.gameCode) {
            this.subscriptionGame = this.apiInteractor
              .get<
                {
                  gameSymbol: string;
                },
                any
              >(`/ajax/launcher/getRealGames`, {
                gameSymbol: gamePregmatic.gameCode,
              })
              .subscribe(() => {
                this.gamePlayService.onLoadGamePlay({
                  gameId: gamePregmatic.gameCode,
                  gameType: "realgame",
                  hasDemo: gamePregmatic.hasDemo,
                });
              });
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscriptionGame) this.subscriptionGame.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
