import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { TotalJackpotFeed } from "src/app/modules/game-groups/models/jackpot/total-jackpot-feed.model";
import { JackpotFeed } from "src/app/modules/game-groups/models/jackpot/jackpot-feed.model";

@Injectable()
export class MainService {
  // API Interactions
  apiInteractor: ApiInteractors;

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Micro Gaming
  onGetMicroGamingJackpotFeed(
    currencyCode: WorldCurrencyCode
  ): Observable<JackpotFeed[]> {
    return this.apiInteractor.getJackpot<JackpotFeed[]>(
      `${environment.microGamingJackpotsUrl}/counters?currencyIsoCode=${currencyCode}`
    );
  }

  onGetMicroGamingTotalJackpotsAmount(
    currencyCode: WorldCurrencyCode
  ): Observable<TotalJackpotFeed> {
    return this.apiInteractor.getJackpot<TotalJackpotFeed>(
      `${environment.microGamingJackpotsUrl}/jackpots/total?currencyIsoCode=${currencyCode}`
    );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Get User Data

  // -----------------------------------------------------------------
  // Get Methods - Observables Get User Data
  onProfileUpdate(request): Observable<any> {
    return this.apiInteractor.post<any, any>(`/ajax/profile/update`, request);
  }

  onGetProfileCountryDetails(): Observable<any> {
    return this.apiInteractor.get<any, any>(`/ajax/profile/getcountryDetails`);
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Account
  onGetAccountVerificationStatus(): Observable<any> {
    return this.apiInteractor.get<null, any>(
      `/ajax/profile/getAccountVerificationStatus`
    );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables KYC

  // -----------------------------------------------------------------
  // Get Methods - Observables Zendesk Token
  onGetZendeskToken(): Observable<any> {
    return this.apiInteractor.get<null, any>(`/ajax/profile/getZendeskToken`);
  }

  // -----------------------------------------------------------------
  // Promises
  // -----------------------------------------------------------------
  // Get Methods - Promises
  onGetPromiseCountryBlockData(): Promise<any> {
    return this.apiInteractor
      .get<null, any>(`/ajax/country/countryblock`)
      .toPromise<any>();
  }

 




  getPromotions(requestObject): Observable<any> {
    return this.apiInteractor.get<any, any>(
      `/ajax/promotion/getPromotions`,
      requestObject
    );
  }


  getFooterContent(requestObj): Observable<any> {
    return this.apiInteractor.get<any, any>(
      `/ajax/staticPage/getPage`,
      requestObj
    );
  }

  getAllPromotions(): Observable<any> {
    return this.apiInteractor.get<any, any>(`/ajax/promotion/getPromotions`);
  }

  /***********promise call ********** */


  

  getZendeskRubikoDetails(userName: string): Observable<any> {
    let url: string = `https://zdapi.rubiko.tech/tracking?user=${encodeURIComponent(
      userName
    )}&page=${encodeURIComponent(window.location.href)}`;

    return this.apiInteractor.get(url);
  }

  deleteNetDepositLimits(requestObj): Observable<any> {
    return this.apiInteractor.post<any, any>(
      `/ajax/ResponsibleGaming/deleteNetDepositLimit`,
      requestObj
    );
  }

  

  getPlayerPromotions(): Observable<any> {
    return this.apiInteractor.get<any, any>(`/ajax/bonus/getPlayerPromotions`);
  }

  getPlayerPromoRankingDetails(requestObject): Observable<any> {
    return this.apiInteractor.get<any, any>(
      `/ajax/bonus/getPlayerPromoRankingDetails`,
      requestObject
    );
  }

}
