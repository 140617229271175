// Configurations
import { withdrawalMethodsConfigurations } from "src/app/configurations/cashier-config/withdrawal-method-input-fields.configurations";
import { depositMethodsConfigurations } from "src/app/configurations/cashier-config/deposit-method-input-fields.configurations";

// Libraries
import * as _ from "underscore";

// Models
import { SelectedInputFields } from "src/app/modules/account/models/selected-input-fields.model";
import {
  DepositMethodConfigurations,
  DepositMethodInputField,
} from "src/app/models/configurations/deposit-method-configuration.model";
import { UserData } from "src/app/modules/user/models/user-data.model";

export const getSelectInputFields = (
  providerType: string,
  txnType: string,
  isUsedAccount: boolean,
  profileDetails: UserData
): SelectedInputFields => {
  const selectedInputsList: DepositMethodInputField[] = [];

  let selectedMethod: DepositMethodConfigurations;

  let isAccountIdAvailable: boolean = false;

  if (txnType === "deposit") {
    /*
        We need this condition for deep cloning of nested object,
        To break the reference
      */
    selectedMethod = depositMethodsConfigurations[providerType];
  } else if (txnType === "withdrawal") {
    selectedMethod = withdrawalMethodsConfigurations[providerType];
  }

  if (selectedMethod && !_.isEmpty(selectedMethod)) {
    _.each(selectedMethod.inputs, (input: DepositMethodInputField) => {
      if (
        !isUsedAccount &&
        (input.requiredNewPayment === true ||
          (input.requiredNewPayment === "basedOnCountry" &&
            _.contains(
              input.countries,
              profileDetails.country
            )))
      ) {
        selectedInputsList.push(input);
      } else if (isUsedAccount && input.requiredRepeatPayment === true) {
        if (input.key === "accountId") {
          isAccountIdAvailable = true;
        }

        selectedInputsList.push(input);
      }
    });
  }

  return { selectedInputsList, isAccountIdAvailable };
};
