import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

// Actions
import { logoutRequested } from "src/app/modules/auth/store/actions/auth.actions";

// Libraries
import * as socketIo from "socket.io-client";

// Models
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";
import { SocketMessage } from "src/app/modules/shared/models/socket-message.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  // Strings
  pragmaticSessionId: string = "";
  pragmaticUrl: string = "";

  // Socket IO
  socket: socketIo;

  constructor(
    private userDetailsService: UserDetailsService,
    private emitterService: EmitterService,
    private rewardsService: RewardsService,
    private store: Store<AppState>
  ) {}

  onConnectToSockets(
    pragmaticUrl: string,
    pragmaticSessionId: string,
    force?: boolean
  ): void {
    if (pragmaticSessionId) {
      this.pragmaticSessionId = pragmaticSessionId;

      this.pragmaticUrl = pragmaticUrl;

      /*
        Comment below condition to run local code in IE until issue resolved
      */
      if (!this.socket || (this.socket && !this.socket.connected) || force) {
        this.socket = socketIo(pragmaticUrl);

        this.socket.on("connect", () => {
          this.socket.emit("setAuthCode", pragmaticSessionId);

          this.socket.on("message", (socketMessage: SocketMessage) => {
            /*
              To keep session active when user playing games
            */
            if (
              socketMessage &&
              socketMessage.messageName &&
              socketMessage.messageName.includes("PushRealBalance")
            ) {
              /*
                Commenting below line because mutliple status Api calls are hitting
                when user playing games
              */
              setTimeout(() => {
                const socketBalanceData: UserProfileBalance = {
                  realCashBalance: Number(socketMessage.cashBalance),
                  crpBalance: socketMessage.crpBalance / 100,
                  wrpBalance: socketMessage.wrpBalance / 100,
                  drpBalance: socketMessage.drpBalance / 100,
                  rrpBalance: socketMessage.rrpBalance / 100,
                };

                this.userDetailsService.onSetUserBalanceDetails(
                  socketBalanceData
                );
              });
            } else if (
              socketMessage &&
              socketMessage.messageName &&
              socketMessage.messageName.includes("LEVELUP_LEVEL_UPGRADE")
            ) {
              socketMessage.dataFrom = "socket";
              
              this.rewardsService.onBroadLevelUpDetails(socketMessage);
            } else if (
              socketMessage &&
              socketMessage.messageName &&
              socketMessage.messageName.includes("LEVELUP_POINTS_UPDATE")
            ) {
              this.rewardsService.onBroadcastLevelUpPointDetails(socketMessage);
            } else if (
              socketMessage &&
              socketMessage.messageName.includes(
                "BET_FROM_BONUS_EXCEEDED_LIMIT"
              )
            ) {
              this.emitterService.onBroadcastSocketMessage(
                socketMessage
              );
            } else if (
              socketMessage &&
              socketMessage.messageName &&
              socketMessage.messageName.includes("PushLogoutEvent")
            ) {
              this.store.dispatch(logoutRequested({}));
            }
          });
        });
      }
    }
  }
}
