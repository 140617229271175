import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  withLatestFrom,
  catchError,
  exhaustMap,
  switchMap,
  filter,
  map,
  tap,
} from "rxjs/operators";

// Actions
import {
  logoutRequestedRefresh,
  loginStatusRequested,
  loginStatusLoaded,
  loginStatusError,
  logoutRequested,
  loginRequested,
  logoutLoaded,
  loginLoaded,
  logoutError,
  loginError,
} from "src/app/modules/auth/store/actions/auth.actions";

// Models
import { LogoutResponse } from "src/app/modules/auth/models/logout/logout-response.model";
import { LoginStatus } from "src/app/modules/auth/models/login-status.model";
import { LoggedIn } from "src/app/modules/auth/models/logged-in.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoading } from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { LoginAPIService } from "src/app/modules/auth/store/services/login-api.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

@Injectable()
export class AuthEffects {
  constructor(
    private userDetailsService: UserDetailsService,
    private loginAPIService: LoginAPIService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private actions$: Actions,
    private router: Router
  ) {}

  loginRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginRequested),
      exhaustMap((action) => {
        return this.loginAPIService.onLogin(action.credentials).pipe(
          map((loggedIn: LoggedIn) => loginLoaded({ loggedIn })),
          catchError(() => of(loginError()))
        );
      })
    )
  );

  loginStatusRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginStatusRequested),
      withLatestFrom(this.store.select(selectAuthLoading)),
      filter(([, isLoading]) => !!isLoading),
      exhaustMap(() => {
        return this.loginAPIService.onGetLoginStatus().pipe(
          map((loginStatus: LoginStatus) => loginStatusLoaded({ loginStatus })),
          catchError(() => of(loginStatusError()))
        );
      })
    )
  );

  logoutRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutRequested, logoutRequestedRefresh),
      switchMap(() => {
        return this.loginAPIService.onGetLogout().pipe(
          map((logoutResponse: LogoutResponse) =>
            logoutLoaded({ logoutResponse })
          ),
          catchError(() => of(logoutError()))
        );
      })
    )
  );

  logoutLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutLoaded),
        withLatestFrom(this.store.select(selectLanguageCode)),
        filter(([, languageCode]) => !!languageCode),
        tap(([, languageCode]) => {
          if (this.commonService.getActiveAccountMenu()) {
            this.commonService.onBroadcastActiveAcountView("");
          }

          localStorage.removeItem("caf_user");

          sessionStorage.removeItem("session");

          sessionStorage.removeItem("_player");

          this.userDetailsService.onResetAllDetails();

          this.router.navigate([`${languageCode}/casino`]);

          this.commonService.setCurrencyByLocality();
        })
      ),
    { dispatch: false }
  );
}
