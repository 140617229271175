import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  AfterViewInit,
  Component,
  OnDestroy,
} from "@angular/core";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { LivespinsService } from "src/app/modules/livespins/services/livespins.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements AfterViewInit, OnDestroy {
  // Strings
  activeLobby: string = "";

  // Subscriptions
  subscription: Subscription;

  constructor(
    private multiLanguageService: MultiLanguageService,
    private translationService: TranslationService,
    private livespinsService: LivespinsService,
    private utilityService: UtilityService,
    private store: Store<AppState>
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.livespinsService.onInitialise();

    this.subscription = this.store.select(selectLanguageCode).subscribe(() => {
      this.onUpdateSEOContent();
    });
  }

  ngAfterViewInit(): void {
    this.onUpdateSEOContent();
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSelectedLobby(activeLobby: string): void {
    this.activeLobby = activeLobby;
  }

  onUpdateSEOContent(): void {
    this.utilityService.setSEO({
      metaTitle: this.translationService.get("SEO.casinoHomePage_metaTitle"),
      metaDescription: this.translationService.get(
        "SEO.casinoHomePage_metaDescription"
      ),
      metaTags: this.translationService.get("SEO.casinoHomePage_metaTag"),
      title: this.translationService.get("SEO.casinoHomePage_title"),
    });

    this.multiLanguageService.onSetCanonicalURL("homepage");
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
