import { createFeatureSelector, createSelector } from "@ngrx/store";

// Reducers
import {
  AuthStateFeatureKey,
  AuthReducerState,
} from "src/app/modules/auth/store/reducers/auth.reducer";

// Select the Main State
const selectAuthReducerState = createFeatureSelector<AuthReducerState>(
  AuthStateFeatureKey
);

// Login
export const selectAuthLoggedIn = createSelector(
  selectAuthReducerState,
  (state: AuthReducerState) => {
    return {
      loggedIn: state.loggedIn,
      isLoaded: state.isLoaded,
      isLoading: state.isLoading,
    };
  }
);

// Logout
export const selectAuthLogout = createSelector(
  selectAuthReducerState,
  (state: AuthReducerState) => {
    return {
      loggedIn: state.logout,
      isLoaded: state.isLoadedLogout,
    };
  }
);

export const selectAuthLoading = createSelector(
  selectAuthReducerState,
  (state: AuthReducerState) => state.isLoading
);

// Login Is Logged In
export const selectAuthLoginIsLoggedIn = createSelector(
  selectAuthReducerState,
  (state: AuthReducerState) => state.isLoggedIn
);

export const selectAuthLoginIsLoggedOut = createSelector(
  selectAuthReducerState,
  (state: AuthReducerState) => state.isLoggedOut
);

// Login Status
export const selectAuthLoginStatus = createSelector(
  selectAuthReducerState,
  (state: AuthReducerState) => state.loginStatus
);

export const selectAuthLoginStatusLoaded = createSelector(
  selectAuthReducerState,
  (state: AuthReducerState) => state.isLoadedLoginStatus
);
