import { Component, OnInit, Output, Input, EventEmitter, SimpleChange, ViewChild, ElementRef } from "@angular/core";

// Configurations
import { maxSupportedFileSizeInBytesConfigurations } from "src/app/configurations/main.configurations";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

@Component({
  selector: "app-proof-of-payment",
  templateUrl: "./proof-of-payment.component.html",
  styleUrls: ["./proof-of-payment.component.scss"],
})
export class ProofOfPaymentComponent implements OnInit {
  @ViewChild("proofOfPymCamera", { static: false }) proofOfPymCamera: ElementRef;
  @ViewChild("proofOfPym", { static: false }) proofOfPym: ElementRef;
  @Output() uploadProofOfPaymentFiles: EventEmitter<any> = new EventEmitter();
  @Input() uploadResponseData;
  @Input() isButtonDisabled: boolean;

  proofOfPaymentData;
  sizeExceeded: string;
  constructor(private translationService: TranslationService, private commonService: CommonService) {}

  ngOnInit() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["uploadResponseData"]) {
      this.uploadResponseData = changes["uploadResponseData"].currentValue;
      setTimeout(() => {
        this.uploadResponseData = undefined;
      }, 5000);
      this.clearField();
    } else if (changes["isButtonDisabled"]) {
      this.isButtonDisabled = changes["isButtonDisabled"].currentValue;
    }
  }

  upload(event, callingFrom?) {
    this.sizeExceeded = undefined;
    if (event.target.files[0].size >= maxSupportedFileSizeInBytesConfigurations) {
      this.sizeExceeded = this.translationService.get("confirmidentity.file_size_alert");
      this.clearField();
    } else {
      this.proofOfPaymentData = {
        event: event,
        fileName: event.target.files[0].name,
        type: "caf",
      };
    }
  }

  subProofOfpayment() {
    if (this.proofOfPaymentData) {
      this.uploadProofOfPaymentFiles.emit(this.proofOfPaymentData);
    }
  }
  clearField() {
    this.proofOfPaymentData = undefined;
    if (this.proofOfPymCamera) {
      this.proofOfPymCamera.nativeElement.value = "";
    }
    if (this.proofOfPym) {
      this.proofOfPym.nativeElement.value = "";
    }
    if (this.sizeExceeded) {
      setTimeout(() => {
        this.sizeExceeded = undefined;
      }, 5000);
    }
  }
}
