export const converterObjectQueryString = <K>(value: K): string => {
    let queryParameters: string = "";

    if (value) {
      queryParameters = `?${Object.keys(value)
        .map((key) => `${key}=${encodeURIComponent(value[key])}`)
        .join("&")}`;
    }

    return queryParameters;
}