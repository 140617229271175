import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";

// Components
import { CustomBannerComponent } from "src/app/modules/banner/components/custom-banner/custom-banner.component";

// Effects
import { BannersEffects } from "src/app/modules/banner/store/effects/banners.effects";

// Module
import { SharedModule } from "src/app/modules/shared/shared.module";

// Reducers
import * as fromBannersReducer from "src/app/modules/banner/store/reducers/banners.reducer";

@NgModule({
  declarations: [CustomBannerComponent],
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([BannersEffects]),
    StoreModule.forFeature(
      fromBannersReducer.BannerStateFeatureKey,
      fromBannersReducer.reducer
    ),
  ],
  exports: [CustomBannerComponent],
})
export class BannerModule {}
