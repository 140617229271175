import { Subscription } from "rxjs";
import {
  SimpleChange,
  ElementRef,
  Component,
  OnChanges,
  ViewChild,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Models
import { StaticPageRequest } from "src/app/modules/static-pages/models/static-page-request.model";
import { StaticContent } from "src/app/modules/static-pages/models/static-content.model";

// Services
import { StaticPageService } from "src/app/modules/static-pages/services/static-page.service";

// Utilities
import { replaceAllUtility } from "src/app/modules/shared/utilities/replace-all.utilities";

@Component({
  selector: "app-reward-bonus-terms-conditions",
  templateUrl: "./reward-bonus-terms-conditions.component.html",
  styleUrls: ["./reward-bonus-terms-conditions.component.scss"],
})
export class RewardBonusTermsConditionsComponent implements OnChanges {
  // View Child
  @ViewChild("dynamicContent", { static: true }) element: ElementRef;

  // Inputs
  @Input() urlpath: string = "";

  // Booleans
  isPageAvailable: boolean = false;
  isLoading: boolean = true;

  // Subscriptions
  staticSubscription: Subscription;

  constructor(private staticPageService: StaticPageService) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["urlpath"] &&
      changes["urlpath"].previousValue !== changes["urlpath"].currentValue
    ) {
      this.urlpath = changes["urlpath"].currentValue;

      if (this.urlpath) {
        this.onLoadStaticPage(this.urlpath);
      }
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoadStaticPage(urlPath: string): void {
    let staticRequest: StaticPageRequest = {
      urlPath,
      isForce: false,
    };

    /*
      Re-initializing the properties before
      every request...
    */
    this.staticSubscription = this.staticPageService
      .onGetStaticContent<StaticPageRequest, StaticContent>(staticRequest)
      .subscribe((staticResponse: StaticContent) => {
        this.isLoading = false;

        /*
          Block to handle if no content is configured for this
          specific path
        */
        if (!Object.keys(staticResponse).length || staticResponse.errorCode) {
          this.isPageAvailable = false;
        } else {
          /*
            Block to handle successfull content from cms..
          */
          this.isPageAvailable = true;

          const staticContent: string = replaceAllUtility(
            staticResponse.content,
            {
              "{{site_url}}": environment.siteUrl,
              "{{media_url}}": environment.mediaUrl,
            }
          );

          setTimeout(() => {
            if (this.element && this.element.nativeElement) {
              this.element.nativeElement.innerHTML = staticContent;
            }
          });
        }
      });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.staticSubscription) this.staticSubscription.unsubscribe();
  }
}
