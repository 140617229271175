import { Observable, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import {
  AfterViewInit,
  HostListener,
  ElementRef,
  Component,
  OnDestroy,
  ViewChild,
  OnInit,
} from "@angular/core";

// Configurations
import { marketLocaleCurrencyMappingConfigurations } from "src/app/configurations/main.configurations";
import { landingConfigurations } from "src/app/modules/shared/configurations/landing.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { TotalJackpotFeed } from "src/app/modules/game-groups/models/jackpot/total-jackpot-feed.model";
import { LandingPage } from "src/app/modules/shared/models/landing-pages/landing-page.model";
import { QueryParams } from "src/app/modules/shared/models/parameters.model";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { AffiliateService } from "src/app/modules/auth/services/affiliate.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { SessionService } from "src/app/modules/auth/services/session.service";
import { MainService } from "src/app/modules/shared/services/main.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/multi-languages/utilities/languages.utilities";
import { getQueryParams } from "src/app/modules/shared/utilities/query-parameter.utilities";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit, OnDestroy, AfterViewInit {
  // View Child
  @ViewChild("observerElementHeight", { static: false })
  observerElementHeight: ElementRef;

  // Numbers
  affixElementPosition?: number = undefined;
  footerHeight?: number = undefined;
  visibilityLimit: number = 0;

  // Strings
  mediaUrlPath: string = environment.mediaUrlPath;
  rewardSpecificationDeviceType: string = "";
  frankyDesktopWelcomeImage: string = "";
  frankyMobileWelcomeImage: string = "";
  affiliateOfferJsonText: string = "";
  jackpotTotalAmount: string = "";
  userLanguageCode: string = "";
  bonusRewardsBgJa: string = "";
  userAffiliateId: string = "";
  bonusRewardsBg: string = "";
  currencySymbol: string = "";
  vipCharacter: string = "";
  languageCode: string = "";
  imgixParams: string = "";
  theme: string = "";

  // Booleans
  isScrollCheckEnabled: boolean = false;
  isJapanRegion: boolean = false;
  isAffiliate: boolean = false;
  isVipImage: boolean = false;
  isLoggedIn: boolean = false;
  isScrolled: boolean = false;
  isFixedBar: boolean = true;

  // Enums
  deviceType: "desktop" | "mobile" = "desktop";

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private registrationService: RegistrationService,
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private currencyFormatPipe: CurrencyFormatPipe,
    private affiliateService: AffiliateService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private mainService: MainService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.getWindowType();

    this.onScrollEnabled();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.imgixParams = this.utilityService.getImgixParams();

    this.theme = this.utilityService.setLocaleBasedTheme();

    if (this.languageCode == "ja-jp") {
      this.isJapanRegion = true;
    }
  }

  ngAfterViewInit(): void {
    this.affixElementPosition =
      this.observerElementHeight.nativeElement.offsetTop - window.innerHeight;

    this.onUpdateSEOContent();

    this.onSetAffiliateCookie();
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onResize(): void {
    this.onScrollEnabled();

    this.getWindowType();
  }

  @HostListener("window:scroll") onScroll(): void {
    if (this.isScrollCheckEnabled && window.scrollY >= 250) {
      this.isScrolled = true;

      const element: Element = document.querySelector(
        ".welcome--feel__sectionwrap"
      );

      element.classList.add("welcomeBg-onscorll");
    } else {
      this.isScrolled = false;

      const element: Element = document.querySelector(
        ".welcome--feel__sectionwrap"
      );

      element.classList.remove("welcomeBg-onscorll");
    }

    this.onLoadOnScroll();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    this.rewardSpecificationDeviceType = undefined;

    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1023) {
      this.deviceType = "desktop";
    } else {
      this.deviceType = "mobile";
    }

    if (
      (clientWidth >= 481 &&
        clientWidth < 1023 &&
        window.matchMedia("(orientation: landscape)").matches) ||
      (clientWidth >= 768 &&
        clientWidth < 1023 &&
        window.matchMedia("(orientation: portrait)").matches)
    ) {
      this.rewardSpecificationDeviceType = "mobile_landscape";
    }

    if (clientWidth > 1366) {
      this.isVipImage = true;
    } else {
      this.isVipImage = false;
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getFrankyIsEnabled(): boolean {
    let value: boolean = false;

    let isPopulated: boolean = this.translationService.get(
      "images.welcome-franky-enable-promotion"
    )
      ? true
      : false;

    if (isPopulated) {
      value =
        this.translationService.get("images.welcome-franky-enable-promotion") ==
          "true"
          ? true
          : false;
    }

    return value;
  }

  getJackpotsTotalAmount(): Observable<TotalJackpotFeed> {
    return this.mainService
      .onGetMicroGamingTotalJackpotsAmount(
        this.userDetailsService.getCurrencyCode()
      )
      .pipe(
        map((jackpotFeed: TotalJackpotFeed) => {
          return jackpotFeed;
        })
      );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    /*  
      NB: leave language code here inside contructor since
      user will recall language code whenever clicking on
      the brand image
    */
    this.languageCode = this.utilityService.getLangCode();

    this.onLoadImages(this.languageCode);

    if (!!this.languageCode) {
      this.onSetCountryRegionBackground(this.languageCode);
    }

    this.subscriptions = [
      this.store.select(selectAuthLoginIsLoggedIn).subscribe((isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut),
      this.userDetailsService.currencyCodeBehaviourSubject$.subscribe(() => {
        this.onGetJackpotTotal();
      }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.onLoadImages(this.languageCode);

          this.theme = this.utilityService.setLocaleBasedTheme();

          this.onUpdateSEOContent();

          this.onSetCountryRegionBackground(languageCode);

          this.onSetAffiliateCookie();
        }),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
    ];
  }

  onLoadImages(languageCode: string): void {
    let promotion: LandingPage = landingConfigurations(languageCode);

    this.frankyDesktopWelcomeImage = `${this.mediaUrlPath}/LP/${promotion.frankyDesktop}${this.imgixParams}`;

    this.frankyMobileWelcomeImage = `${this.mediaUrlPath}/LP/${promotion.frankyMobile}${this.imgixParams}`;
  }

  onUpdateSEOContent(): void {
    this.utilityService.setSEO({
      metaTitle: this.translationService.get("SEO.landingPage_metaTitle"),
      metaDescription: this.translationService.get(
        "SEO.landingPage_metaDescription"
      ),
      metaTags: this.translationService.get("SEO.landingPage_metaTag"),
      title: this.translationService.get("SEO.landingPage_title"),
    });

    this.multiLanguageService.onSetCanonicalURL("landingpage");
  }

  onGetJackpotTotal(): void {
    this.getJackpotsTotalAmount().subscribe((jackpotfeed: TotalJackpotFeed) => {
      if (
        jackpotfeed.currencyIsoCode ==
        marketLocaleCurrencyMappingConfigurations[this.languageCode]
          .currencyCode
      ) {
        if (jackpotfeed && jackpotfeed.jackpotsTotal) {
          this.jackpotTotalAmount = this.currencyFormatPipe.transform(
            jackpotfeed.jackpotsTotal
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,"),
            this.currencySymbol,
            false
          );
        }
      }
    });
  }

  onOpenRegistrationPopup(): void {
    this.registrationService.onOpenRegistration();
  }

  onScrollEnabled(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.isScrollCheckEnabled = true;
    } else {
      this.isScrollCheckEnabled = false;
    }
  }

  onLoadOnScroll(): void {
    const footerContainerDom: HTMLCollectionOf<Element> = document.getElementsByClassName(
      "footer-conatiner"
    );

    if (this.footerHeight === undefined) {
      this.footerHeight = footerContainerDom[0].clientHeight + 150;
    }

    if (
      window.scrollY + window.outerHeight >=
      document.body.scrollHeight - this.footerHeight &&
      this.visibilityLimit <= 5
    ) {
      this.visibilityLimit = this.visibilityLimit + 1;
    }

    this.affixElementPosition =
      this.observerElementHeight.nativeElement.offsetTop - window.innerHeight;

    const windowScroll: number = window.pageYOffset;

    if (windowScroll >= this.affixElementPosition) {
      this.isFixedBar = false;
    } else {
      this.isFixedBar = true;
    }
  }

  onSetCountryRegionBackground(languageCode: string): void {
    let rewardURL: string = this.rewardSpecificationDeviceType
      ? "desktop"
      : this.deviceType;

    this.bonusRewardsBg = `${this.mediaUrlPath}/LP/rewards-${rewardURL}_${languageCode}.png${this.imgixParams}`;

    this.vipCharacter = `${this.mediaUrlPath}/LP/feelVIP_${languageCode}.png${this.imgixParams}`;

    this.bonusRewardsBgJa = `/assets/img/landing-page/bonus-ja-${rewardURL}.png${this.imgixParams}`;
  }

  onSetAffiliateCookie(): void {
    const queryParams: QueryParams = getQueryParams();

    let userAffilateValue: string = queryParams.affid;

    if (!this.isLoggedIn && userAffilateValue) {
      let affiliateValuesList: string[] = this.translationService
        .get("affiliateids.affiliateValue")
        .split(",");

      if (
        affiliateValuesList &&
        affiliateValuesList.indexOf(userAffilateValue.split("_")[0]) > -1
      ) {
        let languageCodeFromURL: string = this.utilityService
          .getDecodedCurrentPath()
          .split("/")[1];

        if (
          languageCodeFromURL &&
          _.contains(supportedLanguagesList(), languageCodeFromURL)
        ) {
          this.userLanguageCode = languageCodeFromURL;
        }

        this.affiliateService.onSetAffiliateCookie(
          userAffilateValue,
          this.userLanguageCode
        );
      }
    }

    this.onGetUserAffiliateValue();
  }

  onGetUserAffiliateValue(): void {
    let affilateLanguage: string = this.affiliateService.getAffiliateCookies(
      "affiliateLang"
    );

    let affiliateValuesList: string[] = this.translationService
      .get("affiliateids.affiliateValue")
      .split(",");

    if (this.affiliateService.getAffiliateCookies("affId")) {
      this.userAffiliateId = this.affiliateService
        .getAffiliateCookies("affId")
        .split("_")[0];
    }

    if (
      this.userAffiliateId &&
      affilateLanguage &&
      affiliateValuesList &&
      affiliateValuesList.indexOf(this.userAffiliateId) > -1 &&
      affilateLanguage === this.languageCode
    ) {
      this.isAffiliate = true;

      this.onRenderAffiliteJsonOfferTextAsHtml();
    } else {
      this.isAffiliate = false;
    }
  }

  onRenderAffiliteJsonOfferTextAsHtml(): void {
    this.affiliateOfferJsonText = this.translationService.get(
      `affiliate.${this.userAffiliateId}_offer`
    );
  }

  onGoToAllProvidersPage(): void {
    this.router.navigate([
      `${this.languageCode}/casino/${this.translationService.get(
        "url.allProvider-page"
      )}`,
    ]);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
