import { createAction, props } from "@ngrx/store";

// Models
import { LobbyPregmatic } from "src/app/modules/game-groups/models/lobby/lobby.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

export enum GamesActionTypes {
  // Games
  GamesRequested = "[Games API] Games Requested",
  GamesRequestedRefresh = "[Games API] Games Requested Refresh",
  GamesLoaded = "[Games API] Games Loaded",
  GamesError = "[Games API] Games Error",

  // Lobby
  LobbyRequested = "[Lobby API] Lobbies Requested",
  LobbyRequestedRefresh = "[Lobby API] Lobbies Requested Refresh",
  LobbyLoaded = "[Lobby API] Lobbies Loaded",
  LobbyError = "[Lobby API] Lobbies Error",
  
  // Last Played Games
  LastPlayedGamesRequested = "[Last Played Games API] Last Played Games Requested",
  LastPlayedGamesRequestedRefresh = "[Last Played Games API] Last Played Games Requested Refresh",
  LastPlayedGamesLoaded = "[Last Played Games API] Last Played Games Loaded",
  LastPlayedGamesError = "[Last Played Games API] Last Played Games Error",
}

// Games
export const gamesRequested = createAction(GamesActionTypes.GamesRequested);
export const gamesRequestedRefresh = createAction(
  GamesActionTypes.GamesRequestedRefresh
);
export const gamesLoaded = createAction(
  GamesActionTypes.GamesLoaded,
  props<{ gamesList: GamePregmatic[] }>()
);
export const gamesError = createAction(GamesActionTypes.GamesError);

// Lobbies
export const lobbyRequested = createAction(GamesActionTypes.LobbyRequested);
export const lobbyRequestedRefresh = createAction(
  GamesActionTypes.LobbyRequestedRefresh
);
export const lobbyLoaded = createAction(
  GamesActionTypes.LobbyLoaded,
  props<{ lobbyPregmaticList: LobbyPregmatic[] }>()
);
export const lobbyError = createAction(GamesActionTypes.LobbyError);

// Last Played Games
export const lastPlayedGamesRequested = createAction(GamesActionTypes.LastPlayedGamesRequested);
export const lastPlayedGamesRequestedRefresh = createAction(
  GamesActionTypes.LastPlayedGamesRequestedRefresh
);
export const lastPlayedGamesLoaded = createAction(
  GamesActionTypes.LastPlayedGamesLoaded,
  props<{ lastPlayedList: number[] }>()
);
export const lastPlayedGamesError = createAction(GamesActionTypes.LastPlayedGamesError);
