import { UrlSegment } from "@angular/router";

export function extractPathUtility(url: UrlSegment[]): string {
  return url[1].path;
};

export function lobbyRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
} {
  if (url.length === 2) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = ["casino", "kaszino"];

    if (translationList.includes(path)) {
      return { consumed: url };
    }
  }

  return null;
};

export function liveLobbyRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
} {
  if (url.length === 2) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = ["live-casino", "elo-kaszino"];

    if (translationList.includes(path)) {
      return { consumed: url };
    }
  }

  return null;
};

export function allGamePageRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
} {
  if (url.length === 3 || url.length === 4) {
    const path: string = extractPathUtility(url);

    if (path === "casino") {
      return { consumed: url };
    }
  }

  return null;
};

export function allGameComponentRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
} {
  if (url.length === 2) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = ["all", "kaikki", "todos", "alle", "tudo", "osszes"];

    if (translationList.includes(path)) {
      return { consumed: url };
    }
  }
  return null;
};

export function gamePageRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
  posParams: {
    gameId: UrlSegment;
  };
} {
  if (url.length === 3) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = [ "game", "spill", "spiel", "juego", "peli", "jogo", "jatek"];

    if (translationList.includes(path)) {
      return {
        consumed: url,
        posParams: {
          gameId: new UrlSegment(url[2].path, {}),
        },
      };
    }
  }

  return null;
};

export function gamePlayFullPageRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
  posParams: {
    gameId: UrlSegment;
  };
} {
  if (url.length === 3) {
    const path: string = extractPathUtility(url);

    if (path === "gamefull") {
      return {
        consumed: url,
        posParams: {
          gameId: new UrlSegment(url[2].path, {}),
        },
      };
    }
  }

  return null;
};

export function promotionPageRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
} {
  if (url.length === 2) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = ["promotions", "promociones", "kampanjer", "kampanjat", "promoções", "aktionen", "promociok"];

    if (translationList.includes(path)) {
      return {
        consumed: url,
      };
    }
  }

  return null;
};

export function promotionContentRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
  posParams: {
    promoUrl: UrlSegment;
  };
} {
  if (url.length === 3) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = ["promotions", "promociones","kampanjer", "kampanjat", "promoções", "aktionen", "promociok"];

    if (translationList.includes(path)) {
      return {
        consumed: url,
        posParams: {
          promoUrl: new UrlSegment(url[2].path, {}),
        },
      };
    }
  }

  return null;
};
