import { FormBuilder, FormGroup } from "@angular/forms";
import { Component } from "@angular/core";
import { Subscription } from "rxjs";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Models
import { RgLimitsResponse } from "src/app/modules/limits/models/rg-limits/rg-limits-response.model";
import { RgLimitsRequest } from "src/app/modules/limits/models/rg-limits/rg-limits-request.model";
import { ActiveSessionLimit } from "src/app/modules/limits/models/active-session-limit.model";
import { LimitsResponse } from "src/app/modules/limits/models/limits-response.model";
import { LimitItem } from "src/app/modules/limits/models/limits-intervals.model";

// Services
import { CustomValidatorService } from "src/app/modules/shared/services/custom-validator.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { LimitsService } from "src/app/modules/limits/services/limits.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

@Component({
  selector: "app-session-limits",
  templateUrl: "./session-limits.component.html",
  styleUrls: ["./session-limits.component.scss"],
})
export class SessionLimitsComponent extends FormValidationComponent {
  // Numbers
  defaultValue: number = 0;

  // Strings
  successResponseMessage: string = "";
  failedResponseMessage: string = "";
  sessionTimeText: string = "";

  // Objects
  activeSessionLimit: ActiveSessionLimit = {
    remainingTime: 0,
    value: 0,
  };

  sessionLimitForm: FormGroup;

  // Subscriptions
  responsibleGamingLimitsSubscription: Subscription;
  subscription: Subscription;

  constructor(
    private customValidatorService: CustomValidatorService,
    private translationService: TranslationService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private limitsService: LimitsService,
    private formBuilder: FormBuilder
  ) {
    super();
    
    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onGetSessionLimits();

    this.sessionTimeText = `0 ${this.translationService.get("limits.minutes")}`;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    this.sessionLimitForm = this.formBuilder.group(
      {
        limit: [""],
      },
      { validator: this.customValidatorService.validateNumericValue }
    );
  }

  onSetSessionLimit(): void {
    if (this.sessionLimitForm.valid) {
      let rgLimitsRequest: RgLimitsRequest = {
        type: "session",
        overall: this.sessionLimitForm.controls["limit"].value,
      };

      this.subscription = this.limitsService
        .onSetResponsibleGamingLimits(rgLimitsRequest)
        .subscribe((data: RgLimitsResponse) => {
          if (data && data.success) {
            this.successResponseMessage = this.translationService.get(
              "limits.limit_updated_success"
            );

            let limit: number = this.sessionLimitForm.controls["limit"].value;

            this.sessionService.onSetSessionVariable("session", {
              sessionLimit: limit,
              availableSessionLimit: limit * 60,
            });

            this.sessionService.onStartLoginSession();

            this.onClearLimitsFields();

            this.onGetSessionLimits();
          } else {
            this.failedResponseMessage = this.translationService.get(
              "limits.limits_update_falied"
            );

            this.onClearLimitsFields();
          }

          setTimeout(() => {
            this.successResponseMessage = undefined;

            this.failedResponseMessage = undefined;
          }, 5000);
        });
    }
  }

  onGetSessionLimits(): void {
    this.responsibleGamingLimitsSubscription = this.limitsService
      .onGetResponsibleGamingLimits("session")
      .subscribe((limitsResponse: LimitsResponse) => {
        if (
          limitsResponse &&
          limitsResponse.limits &&
          limitsResponse.limits.overall
        ) {
          let limits: LimitItem = limitsResponse.limits.overall;

          this.activeSessionLimit = {
            remainingTime: limits.remainingTime,
            value: limits.value,
          };

          this.onSetSessionTimeDisplayText();
        }
      });
  }

  onClearLimitsFields(): void {
    this.sessionLimitForm.controls["limit"].setValue(null);

    this.sessionLimitForm.controls["limit"].markAsUntouched({ onlySelf: true });

    this.sessionLimitForm.controls["limit"].markAsPristine({ onlySelf: true });
  }

  onSetSessionTimeDisplayText(): void {
    const activeMinutes: number = this.activeSessionLimit.value;

    if (activeMinutes) {
      this.sessionTimeText = this.utilityService.getSessionTimeText(
        activeMinutes
      );
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.responsibleGamingLimitsSubscription)
      this.responsibleGamingLimitsSubscription.unsubscribe();

    if (this.subscription) this.subscription.unsubscribe();
  }
}
