import { CCconfigurations } from "src/app/models/configurations/cc-configuration.model";

export const ccConfigurations: CCconfigurations = {
  blocks: {
    uatp: [4, 5, 6],
    amex: [4, 6, 5],
    diners: [4, 6, 4],
    discover: [4, 4, 4, 4],
    mastercard: [4, 4, 4, 4],
    dankort: [4, 4, 4, 4],
    instapayment: [4, 4, 4, 4],
    jcb: [4, 4, 4, 4],
    maestro: [4, 4, 4, 4],
    visa: [4, 4, 4, 4],
    mir: [4, 4, 4, 4],
    general: [4, 4, 4, 4],
    unionPay: [4, 4, 4, 4],
    generalStrict: [4, 4, 4, 7],
  },
  re: {
    // starts with 51-55/2221–2720; 16 digits
    mastercard: /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}/,
    // starts with 4; 16 digits
    visa: /^4\d{0,15}/,
  },
  minMaxlines: {
    // starts with 1; 15 digits, not starts with 1800 (jcb card)
    uatp: /\d{4,14}/,
    //
    // // starts with 34/37; 15 digits
    amex: /\d{4,15}/,
    //
    // // starts with 6011/65/644-649; 16 digits
    discover: /\d{4,16}/,
    //
    // // starts with 300-305/309 or 36/38/39; 14 digits
    diners: /\d{4,14}/,
    // starts with 51-55/2221–2720; 16 digits
    mastercard: /\d{4,16}/,

    // starts with 5019/4175/4571; 16 digits
    dankort: /\d{4,16}/,
    //
    // // starts with 637-639; 16 digits
    instapayment: /\d{4,16}/,
    //
    // // starts with 2131/1800/35; 16 digits
    jcb: /\d{4,16}/,
    //
    // // starts with 50/56-58/6304/67; 16 digits
    maestro: /\d{4,16}/,
    //
    // // starts with 22; 16 digits
    mir: /\d{4,16}/,

    // starts with 4; 16 digits
    visa: /\d{4,16}/,

    // starts with 62; 16 digits
    unionPay: /\d{4,16}/,
  },
  maxdigits: {
    // starts with 1; 15 digits, not starts with 1800 (jcb card)
    uatp: 15,
    //
    // // starts with 34/37; 15 digits
    amex: 15,
    //
    // // starts with 6011/65/644-649; 16 digits
    discover: 16,
    //
    // // starts with 300-305/309 or 36/38/39; 14 digits
    diners: 14,

    // starts with 51-55/2221–2720; 16 digits
    mastercard: 16,

    // starts with 5019/4175/4571; 16 digits
    dankort: 16,
    //
    // // starts with 637-639; 16 digits
    instapayment: 16,
    //
    // // starts with 2131/1800/35; 16 digits
    jcb: 16,
    //
    // // starts with 50/56-58/6304/67; 16 digits
    maestro: 16,
    //
    // // starts with 22; 16 digits
    mir: 16,

    // starts with 4; 16 digits
    visa: 16,

    // starts with 62; 16 digits
    unionPay: 16,
  },
};
