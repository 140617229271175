import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";

// Components
import { CsnLivespinsScheduleRoomComponent } from "src/app/modules/livespins/components/csn-livespins-schedule-room/csn-livespins-schedule-room.component";
import { LivespinsLiveCasinoComponent } from "src/app/modules/livespins/components/livespins-live-casino/livespins-live-casino.component";
import { CsnLivespinsIFrameComponent } from "src/app/modules/livespins/components/csn-livespins-iframe/csn-livespins-iframe.component";
import { CsnLivespinsRoomComponent } from "src/app/modules/livespins/components/csn-livespins-room/csn-livespins-room.component";
import { LiveSpinsPageComponent } from "src/app/modules/livespins/components/livespins-page/livespins-page.component";
import { LiveSpinsComponent } from "src/app/modules/livespins/components/livespins/livespins.component";

// Modules
import { MbscModule } from "src/app/modules/shared/libraries/mobiscroll/js/mobiscroll.custom-4.1.0.min";
import { SharedModule } from "src/app/modules/shared/shared.module";

// Pipes
import { SafePipe } from "src/app/modules/shared/pipes/safe.pipe";

@NgModule({
  declarations: [
    CsnLivespinsScheduleRoomComponent,
    LivespinsLiveCasinoComponent,
    CsnLivespinsIFrameComponent,
    CsnLivespinsRoomComponent,
    LiveSpinsPageComponent,
    LiveSpinsComponent,
    SafePipe
  ],
  imports: [BrowserModule, CommonModule, SharedModule, MbscModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    CsnLivespinsScheduleRoomComponent,
    LivespinsLiveCasinoComponent,
    CsnLivespinsIFrameComponent,
    CsnLivespinsRoomComponent,
    LiveSpinsPageComponent,
    LiveSpinsComponent,
    CommonModule,
  ],
})
export class LivespinsModule {}
