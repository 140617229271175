import { SwiperLazy } from "src/app/modules/game-groups/models/swiper/swiper-lazy.model";

export const swiperLazyConfigurations: SwiperLazy = {
  totalNumberOfGamesExist: 0,
  indexNumberOfGameTilesToshow: 14,
  numberOfExtraGamesToLoadForSlide: 7,
  forwardslideIndex: 0,
  backwardslideIndex: 0,
};

export const swiperLazyConfigurationsChange = (indexNumberOfGameTilesToshow: number, numberOfExtraGamesToLoadForSlide: number): SwiperLazy => {
  return {
    totalNumberOfGamesExist: 0,
    indexNumberOfGameTilesToshow,
    numberOfExtraGamesToLoadForSlide,
    forwardslideIndex: 0,
    backwardslideIndex: 0,
  };
};
