import { environment } from "src/environments/environment";

export const supportedLanguagesList = (): string[] => {
  let languages: string[] = ["en-row", "nb-no", "fi-fi", "en-ca", "en-nz", "en-eu", "ja-jp", "de-de", "pt-br", "es-sa"];

  if (environment.features.hungarian) {
    languages = [...languages, "hu-hu"];
  }

  return languages;
};
