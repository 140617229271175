import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

// Actions
import { loggedInStatus } from "src/app/modules/auth/store/actions/auth.actions";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Libraries
import * as _ from "lodash";

// Models
import { ChangePasswordResult } from "src/app/modules/auth/models/change-password-result.model";
import { LogoutResponse } from "src/app/modules/auth/models/logout/logout-response.model";
import { LoginCredentials } from "src/app/modules/auth/models/login-credentials.model";
import { ChangePassword } from "src/app/modules/auth/models/change-password.model";
import { LoginStatus } from "src/app/modules/auth/models/login-status.model";
import { LoggedIn } from "src/app/modules/auth/models/logged-in.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Services
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { AffiliateService } from "src/app/modules/auth/services/affiliate.service";
import { SocketService } from "src/app/modules/shared/services/socket.service";
import { SessionService } from "src/app/modules/auth/services/session.service";
import { LoginService } from "src/app/modules/auth/services/login.service";

@Injectable({
  providedIn: "root",
})
export class LoginAPIService {
  // API Interactions
  apiInteractor: ApiInteractors;

  constructor(
    private cashBackPromoService: CashbackPromoService,
    private affiliateService: AffiliateService,
    private sessionService: SessionService,
    private socketService: SocketService,
    private loginService: LoginService,
    private httpClient: HttpClient,
    private store: Store<AppState>
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Observables
  onLogin(credentials: LoginCredentials): Observable<LoggedIn> {
    return this.apiInteractor
      .post<LoginCredentials, LoggedIn>(`/ajax/login`, credentials)
      .pipe(
        map((loginResponse: LoggedIn) => {
          if (loginResponse && loginResponse.success) {
            this.affiliateService.onDeleteAffiliateCookies();

            this.loginService.onNavigateToUserProfileLanguage();

            this.socketService.onConnectToSockets(
              loginResponse.pragmaticUrl,
              loginResponse.pragmaticSessionId,
              true
            );

            localStorage.setItem("isLoggedIn", "true");

            this.loginService.onLoginSuccessGtmEvents(loginResponse);

            if (loginResponse.sessionLimit > 0) {
              this.sessionService.onSetSessionVariable("session", {
                sessionLimit: loginResponse.sessionLimit,
                availableSessionLimit: loginResponse.sessionLimit * 60,
              });
            }

            localStorage.setItem("caf_user", "true");

            this.store.dispatch(loggedInStatus({ isLoggedIn: true }));

            if (loginResponse && loginResponse.user) {
              this.sessionService.onSetSocketDetails(loginResponse.user);
            }

            if (loginResponse.lastLoginTime) {
              this.cashBackPromoService.onSetUserLastAuthTime(
                +loginResponse.lastLoginTime
              );
            }
          }

          return loginResponse;
        })
      );
  }

  onChangePassword(
    changePassword: ChangePassword
  ): Observable<ChangePasswordResult> {
    return this.apiInteractor
      .post<ChangePassword, ChangePasswordResult>(
        `/ajax/profile/changePassword`,
        changePassword
      )
      .pipe(
        map((changePasswordResult: ChangePasswordResult) => {
          return changePasswordResult;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetLoginStatus(): Observable<LoginStatus> {
    return this.apiInteractor.get<null, LoginStatus>(`/ajax/login/status`).pipe(
      map((loginStatus: LoginStatus) => {
        if (loginStatus && loginStatus.status) {
          this.socketService.onConnectToSockets(
            loginStatus.pragmaticUrl,
            loginStatus.pragmaticSessionId,
            true
          );

          localStorage.setItem("caf_user", "true");

          /* 
            this double check is need for one different case.
          */
          this.loginService.onVerifyIsLandingPage();

          this.sessionService.onSetSocketDetails({
            message: "",
            pragmaticSessionId: loginStatus.pragmaticSessionId,
            socketUrl: loginStatus.pragmaticUrl,
            partnerID: loginStatus.partnerId,
          });
        } else {
          localStorage.removeItem("caf_user");

          sessionStorage.removeItem("session");
        }

        return loginStatus;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /*
    Here we will logout user..
    even Logout api fails by removing active user &
    active session from sessionStorage
  */
  onGetLogout(): Observable<LogoutResponse> {
    return this.apiInteractor
      .post<null, LogoutResponse>(`/ajax/login/logout`)
      .pipe(
        map((logoutResponse: LogoutResponse) => {
          return logoutResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
