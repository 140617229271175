import { Component, OnDestroy } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// Configurations
import { time24FormatConfigurations } from "src/app/configurations/main.configurations";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

@Component({
  selector: "app-time",
  templateUrl: "./time.component.html",
  styleUrls: ["./time.component.scss"],
  providers: [DatePipe],
})
export class TimeComponent implements OnDestroy {
  // Strings
  formattedDate: string = "";
  languageCode: string = "";
  timeFormat: string = "";

  // Timeouts
  timeouts: NodeJS.Timer;

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private datePipe: DatePipe
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onStartTimer();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          if (time24FormatConfigurations.indexOf(this.languageCode) !== -1) {
            this.timeFormat = "HH:mm";
          } else {
            this.timeFormat = "hh:mm a";
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Set Methods
  onStartTimer(): void {
    this.timeouts = setInterval(() => {
      const todayDate: Date = new Date();

      this.formattedDate = this.datePipe.transform(todayDate, this.timeFormat);
    }, 1000);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.timeouts) clearInterval(this.timeouts);

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
