import { Router, ActivatedRoute, Params } from "@angular/router";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  HostListener,
  ElementRef,
  Component,
  ViewChild,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { BannerBonus } from "src/app/modules/banner/models/banner-bonus.model";
import { Promotion } from "src/app/modules/promotions/models/promotion.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { PromotionsService } from "src/app/modules/promotions/services/promotions.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/multi-languages/utilities/languages.utilities";
import { replaceAllUtility } from "src/app/modules/shared/utilities/replace-all.utilities";

@Component({
  selector: "app-promotion-content-page",
  templateUrl: "./promotion-content-page.component.html",
  styleUrls: ["./promotion-content-page.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PromotionContentPageComponent {
  // View Child
  @ViewChild("pageHeader", { static: false }) pageHeader: ElementRef;
  @ViewChild("popupOver", { static: false }) popupOver: ElementRef;

  // Strings
  promotionPageClassName: string = "";
  promotionURL: string = "";
  languageCode: string = "";

  // Booleans
  isPageAvailable: boolean = false;
  isPageHeaderBg: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Arrays
  promotionList: Promotion[] = [];

  // Object
  activePromotion: Promotion;

  // Subscriptions
  subscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private registrationService: RegistrationService,
    private translationService: TranslationService,
    private promotionsService: PromotionsService,
    private gamePlayService: GamePlayService,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private store: Store<AppState>,
    private router: Router
  ) { }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.languageCode = this.utilityService.getLangCode();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.onSetPromotionsContent(true);
        }),
      /*
        this.goBackToAllPromotions() call is not required bacause on logout ,
        we navigate user back to home page
      */
      this.store.select(selectAuthLoginIsLoggedIn).subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;

        this.onSetPromotionsContent(true);
      }),
      this.store.select(selectAuthLoginIsLoggedOut).subscribe((isLoggedOut: boolean) => this.isLoggedIn = !isLoggedOut),
      this.activatedRoute.params.subscribe((params: Params) => {
        /*
          need to remove this appended ''promotions/' after CMS change
        */
        let languageCodeFromURL: string = this.utilityService
          .getDecodedCurrentPath()
          .split("/")[1];

        if (
          languageCodeFromURL &&
          languageCodeFromURL !== this.languageCode &&
          _.contains(supportedLanguagesList(), languageCodeFromURL)
        ) {
          this.languageCode = languageCodeFromURL;
        }

        if (
          this.multiLanguageService.getTranslatedText(
            "promotions",
            this.languageCode
          ) !== "promotions"
        ) {
          this.promotionURL = `promotions/${this.multiLanguageService.getTranslatedText(
            "promotions",
            this.languageCode
          )}/${params.promoUrl}`;
        } else {
          this.promotionURL = `promotions/${params.promoUrl}`;
        }

        this.promotionPageClassName = params.promoUrl;

        this.onSetPromotionsContent();
      }),
    ];
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:scroll") onScroll(): void {
    this.isPageHeaderBg = this.utilityService.scrollAddClass();
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetPromotionsContent(isForce?: boolean): void {
    this.isLoading = true;

    this.isPageAvailable = false;

    this.subscription = this.promotionsService
      .onGetPromotionList(false)
      .subscribe((promotionList: Promotion[]) => {
        this.isLoading = false;

        this.promotionList = promotionList;

        this.activePromotion = _.findWhere(this.promotionList, {
          url: this.promotionURL,
        });

        if (!_.isEmpty(this.activePromotion)) {
          this.isPageAvailable = true;

          this.activePromotion.content = replaceAllUtility(
            this.activePromotion.content,
            {
              "{{site_url}}": environment.siteUrl,
              "{{media_url}}": environment.mediaUrl,
            }
          );

          setTimeout(() => {
            this.utilityService.onToggleCMSButtons();

            this.utilityService.setSEO(this.activePromotion);

            this.multiLanguageService.onSetCanonicalURL(
              this.activePromotion.multilingualUrl
            );
          }, 100);
        } else {
          this.isPageAvailable = false;
        }
      });
  }

  onOpenContent(event: MouseEvent): void {
    let target: HTMLInputElement = <HTMLInputElement>event.target;

    let routerLink: string = target.getAttribute("routerLink");

    let bannerBonus: BannerBonus;

    if (routerLink === "deposit" && target.getAttribute("bonusCode")) {
      bannerBonus = { bonusCode: target.getAttribute("bonusCode") };
    }

    /*
      We are handling this registration related here instead of cmsRouting
      handler because of circular dependances..
    */
    if (
      routerLink &&
      (routerLink === "registration" || routerLink === "register")
    ) {
      if (!this.isLoggedIn) {
        this.registrationService.onOpenRegistration();
      }
    } else if (routerLink) {
      if (
        !_.isEmpty(this.gamePlayService.getCurrentGamePregmatic()) &&
        this.multiLanguageService.getActiveLobby(routerLink.split("/")[1]) ===
        "game"
      ) {
        this.gamePlayService.onClearGameWindowData();
      }

      this.utilityService.onCmsRoutingHandler(routerLink, bannerBonus);
    }
  }

  onGoToAllPromotions(): void {
    this.router.navigate([
      `${this.languageCode}/${this.translationService.get("url.promotions")}`,
    ]);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");

    if (this.subscription) this.subscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
