import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";

// Api Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Models
import { ForgotPassword } from "src/app/modules/auth/models/forgot-password.model";
import { ResetPassword } from "src/app/modules/auth/models/reset-password.model";

@Injectable({
  providedIn: "root",
})
export class ForgotPasswordAPIService {
  // API Interactions
  apiInteractor: ApiInteractors;

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  onSendResetPasswordLink(
    forgotPassword: ForgotPassword
  ): Observable<ResetPassword> {
    return this.apiInteractor
      .post<ForgotPassword, ResetPassword>(
        `/ajax/resetPassword`,
        forgotPassword
      )
      .pipe(
        map((resetPassword: ResetPassword) => {
          return resetPassword;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
