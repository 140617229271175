import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { Subscription, forkJoin } from "rxjs";
import { Store } from "@ngrx/store";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { StatusResponse } from "src/app/models/api/status.response";

// Libraries
import * as _ from "underscore";

// Models
import { UserLevelUpDetails } from "src/app/modules/rewards/models/user-level-up-details.model";
import { ActiveBonusDetails } from "src/app/modules/rewards/models/active-bonus-details.model";
import { CampaignItem } from "src/app/modules/rewards/models/campaign-items.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import {
  UnclaimedLevelItems,
  UnclaimedLevels,
} from "src/app/modules/rewards/models/unclaimed-levels.model";
import {
  EligibleBonusItem,
  EligibleBonus,
} from "src/app/modules/rewards/models/eligible-bonus.model";
import {
  CampaignDetails,
  UserCampaign,
} from "src/app/modules/rewards/models/user-campaign.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAllGames } from "src/app/modules/game-groups/store/selectors/games.selectors";

// Services
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-all-rewards",
  templateUrl: "./all-rewards.component.html",
  styleUrls: ["./all-rewards.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AllRewardsComponent implements OnInit, OnDestroy {
  // Booleans
  isLoading: boolean = false;

  // Arrays
  unclaimedLevelsItemsList: UnclaimedLevelItems[] = [];
  activeBonusDetailsList: ActiveBonusDetails[] = [];
  filteredCampaignDetailsList: CampaignItem[] = [];
  campaignDetailsList: CampaignDetails[] = [];
  depositBonusList: EligibleBonusItem[] = [];
  unclaimedLevelsList: string[] = [];
  gamesList: GamePregmatic[] = [];

  // Enums
  currencyCode: WorldCurrencyCode;

  // Objects
  userLevelUpDetails: UserLevelUpDetails;

  // Subscriptions
  gamePregmaticListSubscription: Subscription;
  currencyCodeSubscription: Subscription;

  constructor(
    private cashbackPromoService: CashbackPromoService,
    private userDetailsService: UserDetailsService,
    private rewardsService: RewardsService,
    private utilityService: UtilityService,
    private store: Store<AppState>
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onGetAllAvailableBonus();

    this.currencyCodeSubscription = this.userDetailsService.currencyCodeBehaviourSubject$.subscribe(
      (currencyCode: WorldCurrencyCode) => {
        this.currencyCode = currencyCode;
      }
    );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetAllAvailableBonus(): void {
    this.isLoading = true;

    this.filteredCampaignDetailsList = [];

    forkJoin([
      this.cashbackPromoService.onGetUserCampaigns(),
      this.rewardsService.onGetActiveBonusDetails(),
      this.rewardsService.onGetEligibleBonus({ criteriaType: "DEPOSIT" }),
      this.rewardsService.onGetUnclaimedLevels(),
      this.rewardsService.onGetUserLevelUpDetails(),
    ]).subscribe(
      ([
        userCampaignsResponse,
        activeBonusResponse,
        eligibleBonusResponse,
        unclaimedLevelsResponse,
        userLevelUpDetailsResponse,
      ]) => {
        let userCampaigns: UserCampaign = userCampaignsResponse;

        let activeBonus: ActiveBonusDetails[] = activeBonusResponse;

        let eligibleResponse: EligibleBonus = eligibleBonusResponse;

        let unclaimedLevels: UnclaimedLevels = unclaimedLevelsResponse;

        let userLevelUpDetails: UserLevelUpDetails = userLevelUpDetailsResponse;

        if (
          userCampaigns &&
          userCampaigns.campaignDetails &&
          userCampaigns.status === StatusResponse.SUCCESS
        ) {
          this.campaignDetailsList = userCampaigns.campaignDetails;

          this.campaignDetailsList = this.campaignDetailsList.filter(
            (campaignDetails: CampaignDetails) => {
              let currentTime: Date = new Date();

              let promoStartTime: Date = new Date(
                campaignDetails.campaignStartDate
              );

              let promoOfferExpiryDate: Date = new Date(
                campaignDetails.campaignEndDate
              );

              if (
                campaignDetails &&
                campaignDetails.campaignType === "CASHBACK_PROMO" &&
                campaignDetails.optInRequired &&
                !campaignDetails.playerOptinTime &&
                currentTime >= promoStartTime &&
                currentTime < promoOfferExpiryDate
              ) {
                campaignDetails.bonusTypeFD = "cashback-promotion";

                return campaignDetails;
              }
            }
          );

          if (this.campaignDetailsList && this.campaignDetailsList.length > 0) {
            this.filteredCampaignDetailsList = [
              ...this.filteredCampaignDetailsList,
              ...this.campaignDetailsList,
            ];
          }
        }

        if (activeBonus && activeBonus.length > 0) {
          this.activeBonusDetailsList = activeBonus;

          this.activeBonusDetailsList = this.activeBonusDetailsList.filter(
            (activeBonusDetails: ActiveBonusDetails) => {
              if (
                activeBonusDetails.isFreeSpinBonus &&
                !activeBonusDetails.isFreeSpinsCompleted
              ) {
                activeBonusDetails.bonusTypeFD = "freeSpin-bonus";

                return activeBonusDetails;
              }
            }
          );

          if (
            this.activeBonusDetailsList &&
            this.activeBonusDetailsList.length > 0
          ) {
            this.filteredCampaignDetailsList = [
              ...this.filteredCampaignDetailsList,
              ...this.activeBonusDetailsList,
            ];
          }
        }

        if (
          eligibleResponse &&
          eligibleResponse.status === StatusResponse.SUCCESS &&
          eligibleResponse.eligibleBonusList
        ) {
          this.depositBonusList = eligibleResponse.eligibleBonusList;

          this.depositBonusList = this.depositBonusList.filter(
            (eligibleBonusItem: EligibleBonusItem) => {
              if (eligibleBonusItem.playerTargetType !== "LEVELUP") {
                eligibleBonusItem.bonusTypeFD = "deposit-bonus";

                return eligibleBonusItem;
              }
            }
          );

          if (this.depositBonusList && this.depositBonusList.length > 0) {
            this.filteredCampaignDetailsList = [
              ...this.filteredCampaignDetailsList,
              ...this.depositBonusList,
            ];
          }
        }

        if (
          this.filteredCampaignDetailsList &&
          this.filteredCampaignDetailsList.length > 0
        ) {
          this.onGetGameGroupGames(false);
        }

        if (
          unclaimedLevels &&
          unclaimedLevels.status === "SUCCESS" &&
          unclaimedLevels.unclaimedLevels.length > 0
        ) {
          this.unclaimedLevelsList = unclaimedLevels.unclaimedLevels;

          this.unclaimedLevelsItemsList = this.unclaimedLevelsList.map(
            (unclaimLevelId: string) => {
              return {
                bonusTypeFD: "unclaimedFreeSpin-bonus",
                levelId: +unclaimLevelId,
              };
            }
          );

          if (
            this.unclaimedLevelsItemsList &&
            this.unclaimedLevelsItemsList.length > 0
          ) {
            this.filteredCampaignDetailsList = [
              ...this.filteredCampaignDetailsList,
              ...this.unclaimedLevelsItemsList,
            ];
          }
        }

        if (
          userLevelUpDetails &&
          userLevelUpDetails.status === StatusResponse.SUCCESS
        ) {
          this.userLevelUpDetails = userLevelUpDetails;

          this.userLevelUpDetails.bonusTypeFD = "upcoming-bonus";

          this.filteredCampaignDetailsList = [
            ...this.filteredCampaignDetailsList,
            this.userLevelUpDetails,
          ];
        }

        this.isLoading = false;
      }
    );
  }

  onGetGameGroupGames(isForce: boolean): void {
    this.gamePregmaticListSubscription = this.store
      .select(selectAllGames)
      .subscribe((gamesList: GamePregmatic[]) => {
        if (gamesList && gamesList.length > 0) {
          this.gamesList = gamesList;

          this.filteredCampaignDetailsList = this.filteredCampaignDetailsList.filter(
            (campaignItem: CampaignItem) => {
              if (
                campaignItem.freeSpinGameIds &&
                campaignItem.freeSpinGameIds.length > 0
              ) {
                let filteredGameItem: GamePregmatic = _.findWhere(gamesList, {
                  gameCode: campaignItem.freeSpinGameIds[0],
                });

                if (
                  filteredGameItem &&
                  Object.keys(filteredGameItem).length > 0
                ) {
                  campaignItem.gameData = filteredGameItem;
                }
              } else if (
                campaignItem.gameIds &&
                campaignItem.gameIds.length > 0
              ) {
                let filteredGameItem: GamePregmatic = _.findWhere(gamesList, {
                  gameCode: campaignItem.gameIds[0],
                });

                if (
                  filteredGameItem &&
                  Object.keys(filteredGameItem).length > 0
                ) {
                  campaignItem.gameData = filteredGameItem;
                }
              }

              return campaignItem;
            }
          );
        }
      });
  }

  onOpenRewardTCPopUp(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }

    this.utilityService.toggleRewardTCPopUp(true);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.gamePregmaticListSubscription)
      this.gamePregmaticListSubscription.unsubscribe();

    if (this.currencyCodeSubscription)
      this.currencyCodeSubscription.unsubscribe();
  }
}
