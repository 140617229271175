import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  SimpleChange,
  EventEmitter,
  HostListener,
  ElementRef,
  Component,
  OnDestroy,
  ViewChild,
  Output,
  Input,
} from "@angular/core";

// Configurations
import { livespinsExcludedMarketsConfigurations } from "src/app/modules/livespins/configurations/livespins.configurations";
import { mixPanelEventsConfigurations } from "src/app/configurations/main.configurations";

// Libraries
import * as _ from "underscore";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Models
import { FilteredGamesBy } from "src/app/modules/game-groups/models/games/filtered-games-by.model";
import { TrackGameEvent } from "src/app/modules/shared/models/mix-panel/track-game-event.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { GamesState } from "src/app/modules/game-groups/store/reducers/games.reducer";
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAllGamesIsLoaded,
  selectAllGames,
} from "src/app/modules/game-groups/store/selectors/games.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

@Component({
  selector: "app-recent-search-games",
  templateUrl: "./recent-search-games.component.html",
  styleUrls: ["./recent-search-games.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RecentSearchGamesComponent implements OnDestroy {
  // View Child
  @ViewChild("searchInput", { static: false }) searchInput: ElementRef;

  // Output
  @Output() closeSearchResults: EventEmitter<void> = new EventEmitter<void>();

  // Inputs
  @Input() callingFrom: string = "";

  // Numbers
  searchResultsDefaultSize: number = 18;
  loadMoreSearchGames: number = 0;

  // Strings
  languageCode: string = "";
  searchText: string = "";

  // Booleans
  isEnableSearching: boolean = false;
  isLiveCasinoPage: boolean = false;
  isGamesLoading: boolean = false;
  isGamesLoaded: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Arrays
  excludedMarketList: string[] = livespinsExcludedMarketsConfigurations;
  totalSearchedGamesList: GamePregmatic[] = [];
  filteredGamesList: GamePregmatic[] = [];
  gamesList: GamePregmatic[] = [];

  // Enums
  windowType: "device" | "mobile" = "device";

  // Timeout
  timeoutInstance: NodeJS.Timer;

  // Subscriptions
  getAllGamesSubscription: Subscription;
  searchSubscription: Subscription;
  gamesSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private gamesFilterPipe: GamesFilterPipe,
    private mixPanelService: MixPanelService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private store: Store<AppState>
  ) { }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.loadMoreSearchGames = this.searchResultsDefaultSize;

    this.getWindowType();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.subscriptions = [
      this.store
        .select(selectAllGamesIsLoaded)
        .subscribe((gamesLoaded: GamesState) => {
          this.isGamesLoading = gamesLoaded.isLoading;

          this.isGamesLoaded = gamesLoaded.isLoaded;
        }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;

          this.onGetLobbyGroupsAndGames(true);
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = !isLoggedOut;

            this.onGetLobbyGroupsAndGames(true);
          }
        }),
      this.commonService.isLiveCasinoPage$.subscribe(
        (isLiveCasinoPage: boolean) => {
          this.isLiveCasinoPage = isLiveCasinoPage;
        }
      ),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !==
      changes["callingFrom"].currentValue
    ) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
  }

  ngAfterViewInit(): void {
    if (this.windowType === "mobile") {
      this.searchInput.nativeElement.focus();
    }
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onResize(): void {
    this.getWindowType();
  }

  @HostListener("window:orientationchange") onRotate(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Get Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth <= 1023) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }

    /*
      Default search result will changes based on device
      resolution so here we update the search result default
      size to render..
    */
    if (clientWidth >= 768 && clientWidth <= 1023) {
      this.searchResultsDefaultSize = 25;

      this.loadMoreSearchGames = this.searchResultsDefaultSize;

      if (this.searchText && this.searchText.length > 0) {
        this.onSearchGames(this.searchText);
      }
    } else {
      this.searchResultsDefaultSize = 18;

      this.loadMoreSearchGames = this.searchResultsDefaultSize;

      if (this.searchText && this.searchText.length > 0) {
        this.onSearchGames(this.searchText);
      }
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetLobbyGroupsAndGames(isForce?: boolean): void {
    this.isLoading = true;

    this.gamesSubscription = this.store
      .select(selectAllGames)
      .subscribe((gamesList: GamePregmatic[]) => {
        if (gamesList && gamesList.length > 0) {
          this.gamesList = JSON.parse(JSON.stringify(gamesList));
        } else {
          this.filteredGamesList = [];
        }

        this.isLoading = false;
      });
  }

  onSearchGames(searchKey: string): void {
    this.isLoading = true;

    this.searchSubscription = this.store
      .select(selectAllGames)
      .subscribe((gamesList: GamePregmatic[]) => {
        if (gamesList && gamesList.length > 0) {
          this.gamesList = JSON.parse(JSON.stringify(gamesList));

          let filterData: FilteredGamesBy = {};

          if (this.callingFrom === "liveCasinoLobby") {
            filterData = { typeOfGames: "live-game" };
          }

          this.gamesList =
            Object.keys(filterData).length > 0
              ? this.gamesFilterPipe.transform(this.gamesList, filterData)
              : this.gamesList;
        } else {
          this.filteredGamesList = [];
        }

        this.isLoading = false;

        this.searchText = searchKey;

        if (searchKey && searchKey.length > 0) {
          this.loadMoreSearchGames = this.searchResultsDefaultSize;

          this.totalSearchedGamesList = undefined;

          /*
            User can seach game not only based on name, he can search based on game
            tag name & vendor display name (means provider name)
          */
          this.totalSearchedGamesList = _.filter(
            this.gamesList,
            (game: GamePregmatic) => {
              return (
                game.name.toLowerCase().indexOf(searchKey.toLowerCase()) !=
                -1 ||
                game.vendorDisplayName
                  .toLowerCase()
                  .indexOf(searchKey.toLowerCase()) != -1 ||
                (game.tagList &&
                  game.tagList
                    .join("")
                    .toLowerCase()
                    .indexOf(searchKey.toLowerCase()) != -1)
              );
            }
          );

          if (this.excludedMarketList.includes(this.languageCode)) {
            this.totalSearchedGamesList = this.totalSearchedGamesList.filter(
              (game: GamePregmatic) => game.gameCode !== "livespinsBetBehind"
            );
          }

          this.filteredGamesList = this.totalSearchedGamesList.slice(
            0,
            this.searchResultsDefaultSize
          );
        } else {
          this.filteredGamesList = [];
        }
      });

    this.onSendMixPanelEvent(searchKey);
  }

  /*
    Search loading related functionality
    case 1:
    - Basically we load only 18 games on page load
      when user scroll we will keep on adding 18(searchResultsDefaultSize) more games on each scroll..
    case 2:
    - But only in ipad the case is different we load 25 games on page load
      & we are handling this DefaultSize for different resolutions in getWindowType()...
  */
  onLoadMoreSearchResult(event): void {
    if (
      event.srcElement.classList &&
      event.srcElement.classList.contains("searchresults-card")
    ) {
      let searchWrapper: HTMLElement = document.getElementById(
        "searchresults-card"
      );

      if (
        searchWrapper.scrollTop + searchWrapper.offsetHeight >=
        searchWrapper.scrollHeight - 50
      ) {
        this.loadMoreSearchGames += this.searchResultsDefaultSize;

        this.filteredGamesList = this.totalSearchedGamesList.slice(
          0,
          this.loadMoreSearchGames
        );
      }
    }
  }

  onCloseSearchResultsHandler(): void {
    this.searchInput.nativeElement.value = "";

    this.onSearchGames("");

    if (this.isEnableSearching) {
      this.onStopSearching();
    }
  }

  onFocusInputText(): void {
    setTimeout(() => {
      if (document.getElementById("searchtext")) {
        document.getElementById("searchtext").focus();
      }
    });
  }

  onStartSearching(): void {
    this.isEnableSearching = true;
  }

  onStopSearching(): void {
    this.isEnableSearching = false;
  }

  onSendMixPanelEvent(searchValue: string): void {
    if (this.timeoutInstance) {
      clearTimeout(this.timeoutInstance);
    }

    this.timeoutInstance = setTimeout(() => {
      if (searchValue) {
        let evenProperties: TrackGameEvent = {
          query: searchValue,
          vertical: "Header",
        };

        if (this.callingFrom === "casinoLobby") {
          evenProperties.vertical = "Casino";
        } else if (this.callingFrom === "liveCasinoLobby") {
          evenProperties.vertical = "Live Casino";
        }

        this.mixPanelService.onTrackMixPanelEvents(
          mixPanelEventsConfigurations.initiated_Search,
          evenProperties
        );
      }
    }, 2000);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.searchSubscription) this.searchSubscription.unsubscribe();

    if (this.gamesSubscription) this.gamesSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
