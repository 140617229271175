import { FormControl, ValidationErrors, AbstractControl } from "@angular/forms";
import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";

// Configurations
import { userMinimumRGLimitCheckConfigurations } from "src/app/configurations/main.configurations";

// Models
import { ValidationResponse } from "src/app/modules/registration/models/validation/validation-response.model";
import { ValidationRequest } from "src/app/modules/registration/models/validation/validation-request.model";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

// Utilities
import { getCreditCardValidation } from "src/app/modules/shared/utilities/creditcard-validation.uitliities";

@Injectable()
export class CustomValidatorService {
  static CHAZ =
    "\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29";
  static JAAZ =
    "\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B";
  static ACCENTEDLETTERS = "A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff";
  static SPECIALCHAR =
    "ÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ";
  static numbers = "0-9";
  static letters =
    CustomValidatorService.CHAZ +
    CustomValidatorService.JAAZ +
    CustomValidatorService.ACCENTEDLETTERS +
    CustomValidatorService.SPECIALCHAR;
  static nameRegex = new RegExp(
    "^([" + CustomValidatorService.letters + "\\s-])*$"
  );
  static address =
    CustomValidatorService.letters + CustomValidatorService.numbers;
  static alphaNum = new RegExp(
    "^([" + CustomValidatorService.address + "\\s-])*$"
  );
  static addressRegex = new RegExp(
    "^([" + CustomValidatorService.address + "#,'\\s-])*$"
  );
  static alphaNumericRegex = new RegExp(
    "^([" + CustomValidatorService.address + "#,'\\s-])*$"
  );
  static dobRegex = new RegExp(
    "^([" + CustomValidatorService.numbers + "\\/])*$"
  );
  static translationService: any;
  currencySymbolSub: Subscription;
  currencySymbol: string;

  constructor(
    public translationServiceParam: TranslationService,
    public userDetailsService: UserDetailsService,
    public currencyFormatPipe: CurrencyFormatPipe
  ) {
    CustomValidatorService.translationService = translationServiceParam;
    this.currencySymbolSub = this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
      (currencySymbol) => {
        this.currencySymbol = currencySymbol;
      }
    );
  }
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    let message;

    if (!c.get("password").value) {
      c.get("password").setErrors({
        message: CustomValidatorService.translationService.get("errors.error1"),
      });
      message = {
        message: CustomValidatorService.translationService.get("errors.error1"),
      };
    }
    if (!c.get("confirmPassword").value) {
      c.get("confirmPassword").setErrors({
        message: CustomValidatorService.translationService.get("errors.error1"),
      });
      message = {
        message: CustomValidatorService.translationService.get("errors.error1"),
      };
    } else if (
      c.get("confirmPassword").value &&
      c.get("password").value &&
      c.get("password").value !== c.get("confirmPassword").value
    ) {
      c.get("confirmPassword").setErrors({
        message: CustomValidatorService.translationService.get("errors.error2"),
      });
      message = {
        message: CustomValidatorService.translationService.get("errors.error2"),
      };
    } else {
      c.get("confirmPassword").setErrors(null);
      message = {};
    }
    return message;
  }

  validName(minChar, maxChar) {
    return function (control: FormControl) {
      const name = control.value;
      let hasCat = /_/;
      let message;
      if (!name) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (name && name.length < minChar) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error3") +
            minChar +
            CustomValidatorService.translationService.get("errors.error4"),
        };
      } else if (name && name.length > maxChar) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error8") +
            maxChar +
            CustomValidatorService.translationService.get("errors.error4"),
        };
      } else if (
        !CustomValidatorService.nameRegex.test(name) ||
        hasCat.test(name)
      ) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error5"
          ),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  maxMinCharacters(minChar, maxChar) {
    return function (control: FormControl) {
      const name = control.value;
      let message;
      if (!name) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (name && name.length < minChar) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error3") +
            minChar +
            CustomValidatorService.translationService.get("errors.error4"),
        };
      } else if (name && name.length > maxChar) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error8") +
            maxChar +
            CustomValidatorService.translationService.get("errors.error4"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  validAlphaNumeric(minChar, maxChar) {
    return function (control: FormControl) {
      const name = control.value;
      let message;
      const regEx = /^[a-z0-9]+$/i;

      if (!name) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (!regEx.test(name)) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error6"
          ),
        };
      } else if (name && name.length < minChar) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error3") +
            minChar +
            CustomValidatorService.translationService.get("errors.error4"),
        };
      } else if (name && maxChar && name.length > maxChar) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error7") +
            maxChar +
            CustomValidatorService.translationService.get("errors.error4"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }
  // getater dan this month and yr
  reqMinMaxNum(minNum, maxChar) {
    return function (control: FormControl) {
      const value = control.value;
      let message;
      const regEx = /^[0-9]*$/;
      if (!value) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (!regEx.test(value)) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error9"
          ),
        };
      } else if (value.length < minNum) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error3") +
            minNum +
            CustomValidatorService.translationService.get("errors.error4"),
        };
      } else if (value.length > maxChar) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error8") +
            maxChar +
            CustomValidatorService.translationService.get("errors.error4"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  validNumbers(minChar) {
    return function (control: FormControl) {
      const name = control.value;
      let message;
      const regEx = /^[0-9]*$/;

      if (!name) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (name && !regEx.test(name)) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error9"
          ),
        };
      } else if (name && name.length < minChar) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error3") +
            minChar +
            CustomValidatorService.translationService.get("errors.error4"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  minValueNumber(minValue, maxValue, fieldName) {
    return function (control: FormControl) {
      let name = control.value;
      let message;
      const regEx = /^[0-9]*(\.\d{0,2})?/;
      minValue = Number(minValue);
      maxValue = Number(maxValue);
      if (!name) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (name && minValue && name < minValue) {
        message = {
          message:
            fieldName +
            CustomValidatorService.translationService.get("errors.error10") +
            minValue,
        };
      } else if (name && maxValue && name > maxValue) {
        message = {
          message:
            fieldName +
            CustomValidatorService.translationService.get("errors.error11") +
            maxValue,
        };
      } else if (name) {
        name = Number(name);
        if (!regEx.test(name)) {
          message = {
            message: CustomValidatorService.translationService.get(
              "errors.error12"
            ),
          };
        } else if (!name) {
          message = {
            message: CustomValidatorService.translationService.get(
              "errors.error12"
            ),
          };
        }
      } else {
        message = null;
      }
      return message;
    };
  }

  maxLength(value) {
    return function (control: FormControl) {
      const name = control.value;
      let message;
      value = Number(value);
      if (!name) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (name && value && name.length > value) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error13") +
            value +
            CustomValidatorService.translationService.get("errors.error14"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  addressValidator(isOptional) {
    return function (c: FormControl) {
      const address =
        typeof c.value === "string" && c.value ? c.value.trim() : c.value;
      const mindigit = 2;
      const maxdigit = 100;
      let message;
      if (!address && !isOptional) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (address && address.length < mindigit) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error15"
          ),
        };
      } else if (address && address.length > maxdigit) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error16"
          ),
        };
      } else if (!CustomValidatorService.addressRegex.test(address)) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error17") +
            "  /'#,-'",
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  phoneNumberValidator(c: FormControl): ValidationErrors {
    const phone = c.value;
    const regEx = /^[0-9]*$/;
    const mindigit = 5;
    const maxdigit = 10;
    let message;
    if (!phone) {
      message = {
        message: CustomValidatorService.translationService.get("errors.error1"),
      };
    } else if (phone && !regEx.test(phone)) {
      message = {
        message: CustomValidatorService.translationService.get(
          "errors.error18"
        ),
      };
    } else if (phone && phone.length < mindigit) {
      message = {
        message:
          CustomValidatorService.translationService.get("errors.error19") +
          mindigit +
          CustomValidatorService.translationService.get("errors.error21"),
      };
    } else if (phone && phone.length > maxdigit) {
      message = {
        message:
          CustomValidatorService.translationService.get("errors.error20") +
          maxdigit +
          CustomValidatorService.translationService.get("errors.error21"),
      };
    } else {
      message = null;
    }

    return message;
  }

  validateUniqueness(
    fieldToValidate: string,
    commonService: CommonService,
    isValidateUnique: boolean
  ): (
    control: FormControl
  ) => {
    message?: string;
  } {
    return function (control: FormControl) {
      const name: string = control.value;

      const regEx: RegExp = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;

      let message: {
        message?: string;
      } = {};

      if (!name) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (name && fieldToValidate == "txtEmail" && !regEx.test(name)) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error22"
          ),
        };
      } else if (
        name &&
        fieldToValidate == "txtEmail" &&
        (name.length < 5 || name.length > 50)
      ) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error61"
          ),
        };
      } else if (
        name &&
        fieldToValidate == "txtNickname" &&
        name.length < 5 &&
        !regEx.test(name)
      ) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error23"
          ),
        };
      } else if (isValidateUnique) {
        const validateValues: ValidationRequest = {
          [fieldToValidate]: name,
        };

        commonService
          .onGetValidateUniqueness(validateValues)
          .subscribe((validationResponse: ValidationResponse) => {
            if (validationResponse && validationResponse.response == 1) {
              message = null;

              control.setErrors(null);
            } else if (
              validationResponse &&
              validationResponse.response == -1
            ) {
              message = {
                message:
                  CustomValidatorService.translationService.get(
                    "errors.error26"
                  ) +
                  (fieldToValidate === "txtEmail"
                    ? CustomValidatorService.translationService.get(
                        "errors.error28"
                      )
                    : CustomValidatorService.translationService.get(
                        "errors.error25"
                      )),
              };

              control.setErrors({
                message:
                  CustomValidatorService.translationService.get(
                    "errors.error26"
                  ) +
                  (fieldToValidate === "txtEmail"
                    ? CustomValidatorService.translationService.get(
                        "errors.error28"
                      )
                    : CustomValidatorService.translationService.get(
                        "errors.error25"
                      )),
              });
            } else {
              message = {
                message:
                  fieldToValidate == "txtEmail"
                    ? CustomValidatorService.translationService.get(
                        "errors.error29"
                      )
                    : CustomValidatorService.translationService.get(
                        "errors.error30"
                      ),
              };

              control.setErrors(message);
            }
          });
      }

      return message;
    };
  }

  validatePassword(
    minChar: number,
    maxChar: number
  ): (
    control: FormControl
  ) => {
    message?: string;
  } {
    return function (control: FormControl) {
      const password = control.value;

      let message: {
        message?: string;
      } = {};

      if (!password) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (password && password.length < minChar) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error24"
          ),
        };
      } else if (password && password.length > maxChar) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error57") +
            maxChar +
            CustomValidatorService.translationService.get("errors.error58"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  validEmail(c: FormControl): ValidationErrors {
    const email = c.value;

    const regEx = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let message;
    if (!email) {
      message = {
        message: CustomValidatorService.translationService.get("errors.error1"),
      };
    } else if (email && !regEx.test(email)) {
      message = {
        message: CustomValidatorService.translationService.get(
          "errors.error22"
        ),
      };
    } else {
      message = null;
    }

    return message;
  }

  required(c: FormControl): ValidationErrors {
    const value = c.value;
    let message;
    if (!value || value.length <= 0) {
      message = {
        message: CustomValidatorService.translationService.get("errors.error1"),
      };
    } else {
      message = null;
    }

    return message;
  }

  reqMin(minNum) {
    return function (control: FormControl) {
      const value = control.value;
      let message;
      if (!value) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (value.length < minNum) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error3") +
            minNum +
            CustomValidatorService.translationService.get("errors.error4"),
        };
        control.setErrors(message);
      } else {
        message = null;
      }

      return message;
    };
  }

  creditCardValidator(utils: UtilityService) {
    return function (control: FormControl) {
      const ccNumber = control.get("cardNumber").value
        ? control.get("cardNumber").value.replace(/-/g, "")
        : null;

      // typeof control.get('cardNumber').value == 'string' &&

      const newCard = 0; // control.get("cardId").value;
      const regEx = /^[0-9]*$/;
      const mindigit = 2;
      let message;

      if (newCard === 0) {
        if (!ccNumber) {
          control.get("cardNumber").setErrors({
            message: CustomValidatorService.translationService.get(
              "errors.error1"
            ),
          });
          message = {
            message: CustomValidatorService.translationService.get(
              "errors.error1"
            ),
          };
        } else if (ccNumber && !regEx.test(ccNumber)) {
          control.get("cardNumber").setErrors({
            message: CustomValidatorService.translationService.get(
              "errors.error9"
            ),
          });
          message = {
            message: CustomValidatorService.translationService.get(
              "errors.error9"
            ),
          };
        } else if (ccNumber && !getCreditCardValidation(ccNumber)) {
          control.get("cardNumber").setErrors({
            message: CustomValidatorService.translationService.get(
              "errors.error31"
            ),
          });
          message = {
            message: CustomValidatorService.translationService.get(
              "errors.error31"
            ),
          };
        } else {
          message = null;
        }
      } else {
        message = null;
      }

      return message;
    };
  }

  expCardData() {
    return function (control: FormControl) {
      const value = control.value;
      let message;
      const regEx = /^(((0)[0-9])|((1)[0-2]))(\/)\d{2}$/i;
      const date = new Date();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      if (!value) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (!regEx.test(value)) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error64"
          ),
        };
      } else {
        const month = date.getMonth() + 1;
        // const year = parseInt(new Date().getFullYear().toString().substring(0,2));
        const year = date.getFullYear();
        const expiryMonth = parseInt(value.split("/")[0]);
        const expiryYear = parseInt("20" + value.split("/")[1]);
        if (expiryYear < year) {
          message = {
            message: CustomValidatorService.translationService.get(
              "errors.error64"
            ),
          };
        } else if (expiryYear === year && expiryMonth < month) {
          message = {
            message: CustomValidatorService.translationService.get(
              "errors.error64"
            ),
          };
        }
      }
      return message;
    };
  }

  validateRealityCheckValue() {
    return function (control: FormControl) {
      const value = control.value;
      let message;
      const regEx = /^[0-9]+$/;
      if (!value) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (!regEx.test(value)) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error9"
          ),
        };
      } else if (value > 60) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error37"
          ),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  validateNumericValue() {
    return function (control: FormControl) {
      const value = control.value;
      let message;
      const regEx = /^[0-9]+$/;
      if (!value) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (!regEx.test(value)) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error9"
          ),
        };
      } else {
        message = null;
      }

      return message;
    };
  }
  validateRgLimits(currentLimits) {
    let userCurrency = this.userDetailsService.getCurrencySymbol();
    let userMinimumLimit = userMinimumRGLimitCheckConfigurations[userCurrency];
    var self = this;

    return function (control: FormControl) {
      const period = control.get("period").value;
      const value = control.get("limit").value;
      const regEx = /^[0-9]*$/;
      let message;
      if (!value) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
        control.get("limit").setErrors(message);
        return message;
      } else if (!regEx.test(value)) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error9"
          ),
        };
        control.get("limit").setErrors(message);
        return message;
      } else if (value < userMinimumLimit) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error38") +
            self.currencyFormatPipe.transform(
              userMinimumLimit,
              this.currencySymbol
            ),
        };
        control.get("limit").setErrors(message);
        return message;
      }
      if (currentLimits) {
        switch (period) {
          case "dailyLimit": {
            // tslint:disable-next-line:max-line-length
            if (
              (currentLimits["weeklyLimit"] &&
                value > currentLimits["weeklyLimit"] / 100) ||
              (currentLimits["monthlyLimit"] &&
                value > currentLimits["monthlyLimit"] / 100)
            ) {
              message = {
                message: CustomValidatorService.translationService.get(
                  "errors.error39"
                ),
              };
              control.get("limit").setErrors(message);
            } else {
              message = null;
            }
            return message;
          }
          case "weeklyLimit": {
            // if (currentLimits['dailyLimit'] && (value < currentLimits['dailyLimit']/100)) {
            //   message = { 'message': CustomValidatorService.translationService.get('errors.error40') };
            //   control.get('limit').setErrors(message);
            // }
            if (
              currentLimits["monthlyLimit"] &&
              value > currentLimits["monthlyLimit"] / 100
            ) {
              message = {
                message: CustomValidatorService.translationService.get(
                  "errors.error41"
                ),
              };
              control.get("limit").setErrors(message);
            } else if (
              currentLimits["dailyLimit"] &&
              currentLimits["pendingDailyLimit"] / 100 &&
              value < currentLimits["pendingDailyLimit"] / 100
            ) {
              message = {
                message: CustomValidatorService.translationService.get(
                  "errors.error59"
                ),
              };
              control.get("limit").setErrors(message);
            } else {
              message = null;
            }
            return message;
          }
          case "monthlyLimit": {
            if (
              currentLimits["weeklyLimit"] &&
              value < currentLimits["weeklyLimit"] / 100
            ) {
              message = {
                message: CustomValidatorService.translationService.get(
                  "errors.error42"
                ),
              };
              control.get("limit").setErrors(message);
            }
            if (
              (currentLimits["dailyLimit"] &&
                currentLimits["pendingDailyLimit"] &&
                value < currentLimits["pendingDailyLimit"] / 100) ||
              (currentLimits["weeklyLimit"] &&
                currentLimits["pendingWeeklyLimit"] &&
                value < currentLimits["pendingWeeklyLimit"] / 100)
            ) {
              message = {
                message: CustomValidatorService.translationService.get(
                  "errors.error60"
                ),
              };
              control.get("limit").setErrors(message);
            } else {
              message = null;
            }
            return message;
          }
        }
      }
    };
  }

  startEndDateCheck(c: AbstractControl): { invalid: boolean } {
    let message;
    const startDate = c.get("startDate").value;
    const endDate = c.get("endDate").value;
    if (!startDate) {
      c.get("startDate").setErrors({ invalid: true });
      message = {
        message: CustomValidatorService.translationService.get(
          "errors.error10"
        ),
      };
    }
    if (!endDate) {
      c.get("endDate").setErrors({ invalid: true });
      message = {
        message: CustomValidatorService.translationService.get(
          "errors.error10"
        ),
      };
    }

    if (startDate && endDate && Date.parse(endDate) < Date.parse(startDate)) {
      c.get("endDate").setErrors({
        message: CustomValidatorService.translationService.get(
          "errors.error25"
        ),
      });
      message = {
        message: CustomValidatorService.translationService.get(
          "errors.error25"
        ),
      };
    }
    return message;
  }

  validAlphaNumericName(minChar, maxChar) {
    return function (control: FormControl) {
      const name = control.value;
      let hasCat = /_/;
      let message;
      if (!name) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (name && name.length < minChar) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error3") +
            minChar +
            CustomValidatorService.translationService.get("errors.error4"),
        };
      } else if (name && name.length > maxChar) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error8") +
            maxChar +
            CustomValidatorService.translationService.get("errors.error4"),
        };
      } else if (
        !CustomValidatorService.alphaNumericRegex.test(name) ||
        hasCat.test(name)
      ) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error5"
          ),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  // exactNumberMatch(value) {
  //   let self = this;
  //   return function (control: FormControl) {
  //     const name = control.value;
  //     let message;
  //     var regEx = /^[0-9]*$/;
  //     value = Number(value);
  //     if (!name) {
  //       message = { 'message': CustomValidatorService.translationService.get('errors.error10') };
  //     } else if (name && !regEx.test(name)) {
  //       message = { 'message': CustomValidatorService.translationService.get('errors.error18') };
  //     } else if (name && value && name.length != value) {
  //       message = { 'message': CustomValidatorService.translationService.get('errors.error31') + value + CustomValidatorService.translationService.get('errors.error56') };
  //     } else {
  //       message = null;
  //     }

  //     return message;
  //   };
  // };

  exactNumberMatch(value, removeWhiteSpaces?) {
    return function (control: FormControl) {
      let name =
        typeof control.value == "string" && control.value
          ? control.value.trim()
          : control.value;
      if (name && removeWhiteSpaces) {
        name = name.replace(/ /g, "");
      }
      let message;
      const length = name && name.toString().length;
      var regEx = /^[0-9]*$/;
      value = Number(value);
      if (!name) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error1"
          ),
        };
      } else if (name && !regEx.test(name)) {
        message = {
          message: CustomValidatorService.translationService.get(
            "errors.error9"
          ),
        };
      } else if (name && value && length !== value) {
        message = {
          message:
            CustomValidatorService.translationService.get("errors.error65") +
            value +
            CustomValidatorService.translationService.get("errors.error21"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }
}
