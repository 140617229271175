// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { UserPaymentMethod } from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-response.model";
import { DynamicFormProcessPaymentRequest } from "src/app/modules/account/models/dynamic-form-process-payment-request.model";
import { ProcessPaymentRequest } from "src/app/modules/account/models/process-payments/process-payment-request.model";
import { CashierAttributes } from "src/app/modules/account/models/cashier/cashier-attributes.model";
import { DepositMethodGroup } from "src/app/modules/account/models/deposit-method-group-form.model";
import { CashierDetails } from "src/app/modules/account/models/cashier/cashier-details.model";
import { PiqDetails } from "src/app/modules/account/models/piq-details.model";

export const getProcessPaymentRequest = (
  selectedPaymentMethods: UserPaymentMethod,
  cashierType: string,
  piqDetails: PiqDetails,
  cashierDetails: CashierDetails,
  cashierAttributes: CashierAttributes,
  depositMethodGroup: DepositMethodGroup,
  encryptData: any
): ProcessPaymentRequest => {
  let dynamicFormValues: DynamicFormProcessPaymentRequest = {};

  const selectPaymentMethods: string = selectedPaymentMethods.providerType;

  const requestObject: ProcessPaymentRequest = {
    paymentMethod: cashierType,
    methodType: "process",
    merchantId: piqDetails.merchantId
      ? piqDetails.merchantId
      : environment.paymentIqMID,
    sessionId: piqDetails.sessionId,
    userId: piqDetails.userId,
    amount: cashierDetails.amount,
    attributes: cashierAttributes,
    provider: selectPaymentMethods.toLocaleLowerCase(),
  };

  if (selectedPaymentMethods.service) {
    requestObject.service = selectedPaymentMethods.service;
  }

  dynamicFormValues = depositMethodGroup;

  if (selectPaymentMethods === "CREDITCARD") {
    if (dynamicFormValues.cvv) {
      dynamicFormValues.encCvv = encryptData(dynamicFormValues.cvv);

      delete dynamicFormValues.cvv;
    }

    if (dynamicFormValues.creditcardNumber) {
      dynamicFormValues.encCreditcardNumber = encryptData(
        dynamicFormValues.creditcardNumber.replace(" ", "")
      );
      delete dynamicFormValues.creditcardNumber;
    }

    if (dynamicFormValues.expiryMonthYear) {
      const expriyDate: string = dynamicFormValues.expiryMonthYear;

      dynamicFormValues.expiryMonth = expriyDate.split("/")[0];

      dynamicFormValues.expiryYear = `20${expriyDate.split("/")[1]}`;

      delete dynamicFormValues.expiryMonthYear;
    }
  }

  if (dynamicFormValues.phoneNumber && selectPaymentMethods === "MUCHBETTER") {
    dynamicFormValues.phoneNumber = dynamicFormValues.phoneNumber
      .trim()
      .replace("+", "")
      .replace(/^0+/, "");
  }

  _.each(dynamicFormValues, (value: string, key: string) => {
    if (value === "") {
      delete dynamicFormValues[key];
    }
  });

  return { ...requestObject, ...dynamicFormValues };
};
