import { Injectable } from "@angular/core";

// Models
import { GtmRegistrationPayload } from "src/app/modules/shared/models/gtm/gtm-registration-payload.model";
import { GtmRegistrationEvent } from "src/app/modules/shared/models/gtm/gtm-registration-event.model";
import { GTMCashierJourney } from "src/app/modules/shared/models/gtm/gtm-cashier-journey.model";
import { GtmRouterPayload } from "src/app/modules/shared/models/gtm/gtm-router-payload.model";
import { GtmEventPayload } from "src/app/modules/shared/models/gtm/gtm-event-payload.model";
import { GtmRouterEvent } from "src/app/modules/shared/models/gtm/gtm-router-event.model";
import { GTMLayer } from "src/app/modules/shared/models/gtm/gtm-layer-event.model";

@Injectable({
  providedIn: "root",
})
export class GtmService {
  constructor() {}

  gtmDataLayerEvent(event: GTMLayer): void {
    let dataLayer = (window["dataLayer"] = window["dataLayer"] || []);

    dataLayer.push(event);
  }

  sendRegilyAnalyticsEvent(
    hitType: string,
    action: string,
    label: string
  ): void {
    const gaEvent: GtmRegistrationEvent = {
      event: "user_interaction",
      event_type: "register",
      event_context: `${action} ${label}`,
    };

    this.gtmDataLayerEvent(gaEvent);
  }

  registrationGTMEvent(
    eventType: string,
    payload: GtmRegistrationPayload
  ): void {
    let eventpayload: GtmRegistrationEvent;

    switch (eventType) {
      case "register":
        {
          eventpayload = {
            event: "user_interaction",
            event_type: "register",
            event_context: payload.event_context,
          };

          if (payload.event_context === "finish") {
            eventpayload.userid = payload.ecrId;
          }
        }
        break;

      case "registration-flow": {
        eventpayload = {
          event: "user_interaction",
          event_type: "register",
          event_context: payload.action,
          eventLabel: payload.label,
        };
        break;
      }
    }

    this.gtmDataLayerEvent(eventpayload);
  }

  routeChangeGtmEvent(event: GtmRouterPayload): void {
    let pathname: string = event.url;
    let router_name: string = "";

    let gtmRouterEvent: GtmRouterEvent;

    if (pathname && pathname.length > 7 && pathname.includes("en-row")) {
      router_name = pathname.substring(7);
    } else if (
      pathname &&
      pathname.length > 6 &&
      !pathname.includes("en-row")
    ) {
      router_name = pathname.substring(6);
    } else {
      router_name = "/landing-page";
    }

    gtmRouterEvent = {
      event: "user_interaction",
      event_type: "page",
      route_name: router_name,
    };

    if (router_name) {
      this.gtmDataLayerEvent(gtmRouterEvent);
    }
  }

  userCashierJourneyEvents(
    callingFrom: string,
    cashierPayload?: GTMCashierJourney
  ): void {
    let eventPayLoad: GtmEventPayload = {
      event: callingFrom === "navigate" ? "user_interaction" : "custom_event",
      event_category: "paymentiq",
    };

    switch (callingFrom) {
      case "cashierInitLoad":
        {
          eventPayLoad.event_labelId = "casinofriday";
          eventPayLoad.event_action = "initload";
          eventPayLoad.event_label = "click";
          eventPayLoad.partnerID = "CASINO";

          if (cashierPayload.bonusCode) {
            eventPayLoad.bounusCode = cashierPayload.bonusCode;
          }
        }
        break;

      case "isLoading":
        {
          eventPayLoad.event_action = "is_loading";
          eventPayLoad.event_label = "click";
        }
        break;

      case "doneLoading":
        {
          eventPayLoad.event_action = "done_loading";
          eventPayLoad.event_label = "click";
        }
        break;

      case "failure":
        {
          eventPayLoad.payment_method = cashierPayload.method_name;
          eventPayLoad.event_label = cashierPayload.statusCode;
          eventPayLoad.txRefId = cashierPayload.txRefId;
          eventPayLoad.amount = cashierPayload.amount;
          eventPayLoad.userId = cashierPayload.userId;
          eventPayLoad.event_action = "error";
        }
        break;

      case "paymentMethodSelect":
        {
          eventPayLoad.payment_provider = cashierPayload.providerType;
          eventPayLoad.event_action = "payment_method";
          eventPayLoad.userId = cashierPayload.userId;

          if (cashierPayload.txType) {
            //Ecopayz, Credit Card, Btc, etc..
            eventPayLoad.event_label = cashierPayload.txType;
          }
        }
        break;

      case "onLoadError":
        {
          eventPayLoad.event_label = cashierPayload.status;
          eventPayLoad.userId = cashierPayload.userId;
          eventPayLoad.event_action = "on_LoadError";
        }
        break;

      case "navigate":
        {
          eventPayLoad.payment_method = cashierPayload.method_name;
          eventPayLoad.route_name = cashierPayload.path;
          eventPayLoad.event_type = "page";
        }
        break;

      case "transactionInit":
        {
          eventPayLoad.payment_method = cashierPayload.method_name;
          eventPayLoad.event_action = "transaction initiated";
          eventPayLoad.txRefId = cashierPayload.txRefId;
          eventPayLoad.userId = cashierPayload.userId;
          eventPayLoad.amount = cashierPayload.amount;
        }
        break;
    }

    this.gtmDataLayerEvent(eventPayLoad);
  }
}
